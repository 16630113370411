import { GetCallTypeImage } from "../../GetImage";
import { secondsToHHMMSS } from "../../../functions/dataHandler";
import Tooltip from '@mui/material/Tooltip';
import { calculateAverageDuration } from "../../../Pages/insights/Insights";
import "./CallTile.css";

export const CallTile = ({ className, title, data, allCallData, setSelectedData }) => {
  return (
    <div
      onClick={() => {
        //if (data===null) return;
        setSelectedData({
          title: title,
          data: (title === "All Calls") ? allCallData : data
        });
      }}

      className={`callTile overflow-visible bg_${className} width-full border-radius_site todays_calls_tile`}
    >

      <GetCallTypeImage imgString={title} className="alignSelfCenter" />
      <div className="tileDetailsContainer">
        <div className="tileTop">
          <div>{title}</div>
          <div>{data.length}</div>
        </div>
        <hr width="100%" />
        <div className="tileBottom">
          <Tooltip title="Average response time (mm:ss)" placement="bottom-end">
            <div className="durationField">{secondsToHHMMSS(calculateAverageDuration(data))}</div>
          </Tooltip>
        </div>
      </div>

    </div>


  );
};
