import { useState } from 'react';
import './Taggins.css';
import { CloseRounded } from '@mui/icons-material';

const Tag=({option, onRemoveTag})=>{ 
    
    const [removing, setRemoving] = useState(false)

    function remove(){
        try { if (onRemoveTag) onRemoveTag(option.unitCode); }
        catch(e){console.log(e)}
        setRemoving(true);
    }
    
    return (
        <div className={`tagging_tag ${((removing===true)?"tag_removing":"")}`} onClick={()=>remove()} >
            <div>{option.value}</div>
            <CloseRounded style={{color:"white", cursor:(removing===false)?"hand":"pointer"}} />
        </div>
    ); 
}

export default Tag;