import { columns_template_devices } from "../../../components/AQDataTable/columns_templates";
import { PageLayout } from "../../../components/page-layout";
import TableTemplateFirebase from "./TableTemplate_Firebase";
import TableTemplateAPI from "./TableTemplateAPI";

export const RunTemplate=()=>{

    return(
        <PageLayout>

            <TableTemplateAPI
                title={"Residents"}
                apiPath={"/api/messages/residents/listall"}
                apiParams={{"siteID":"1"}}
                tableTemplate={columns_template_devices}
                tableSettingsType={""}
            />

           {/* 
            <TableTemplateFirebase
                title={"Testing"}
                db={""}
                dbRef={"arquellacareconfig"}
                tableTemplate={columns_template_devices}
                tableSettingsType={"devices"}
            />
            <TableTemplateAPI
                title={"Testing"}
                apiPath={"/api/messages/residents/listall"}
                apiParams={{"siteID":"1"}}
                tableTemplate={columns_template_devices}
                tableSettingsType={""}
            />
    */}
        </PageLayout>
    );
}