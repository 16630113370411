import { Checkbox, Grid } from "@mui/material";
import { useEffect, useState } from "react";
import { apiPost } from "../../data/apidata";
import { v4 as uuid } from 'uuid';
import axios from "axios";
import { LoadingBanner } from "../charts/Notifications";
import { timeout } from "d3";
import { Switch } from '@mui/material';

import { styled } from '@mui/material/styles';
import { enqueueSnackbar } from "notistack";


export const VayyarConfig = ({ currStep, config, setConfig, page_error, setPage_error, setCurrStep, onClose }) => {
    const [sensors, setSensors] = useState(config.sensors)
    const [rooms, setRooms] = useState(config.devices)
    const [saveBtn, setSaveBtn] = useState('')
    const [hasChanged, setHasChanged] = useState(false);

    useEffect(() => {
        if (config.tryAgain)
            setSaveBtn('Try again')
        else
            setSaveBtn('next')
    }, [config.tryAgain])

    function addDevice() {
        setSensors((data) => ([
            ...data,
            {
                id: uuid(),
                sensor_id: '',
                sensor_room: '',
                isSelected: false,
                error: false,
                msg: ''
            }
        ]))
        setHasChanged(true)
    }

    function removeDevice(e) {
        console.log('sensors', sensors)
        let tmp = sensors.filter(item => item.isSelected === false);

        console.log(tmp)
        setSensors(tmp)
        setHasChanged(true)
    }
    function goBack() {
        setCurrStep(1)
    }
    const findNonUniqueNameAndId = () => {
        const idSet = new Set();
        const nonUniqueIds = [];
        let arr = sensors
        console.log(arr)
        for (let item of arr) {
            const id = item.sensor_id;
            if (idSet.has(id)) {
                console.log(id)
                nonUniqueIds.push(item);
                item['error'] = true
            } else {
                idSet.add(id);
            }
        }
        console.log(idSet);

        setSensors(arr)
        setHasChanged(true)
        if (nonUniqueIds.length > 0)
            return false;
        else return true
    };
    function saveData(e) {
        if (sensors.length > 0) {
            if (!sensors.some(item => item.error === true))
                if (findNonUniqueNameAndId()) {
                    axios.post('https://home.vayyarhomeapisdev.com/token/idtoken/',
                        {
                            email: config.username,
                            password: config.password
                        })
                        .then(d => {
                            // console.log(d);
                            if (d.status === 200) {
                                apiPost('api/messages/Sense/updateVayyarLoginDetails',
                                    {
                                        site_id: config.site_id,
                                        username: config.username,
                                        password: config.password
                                    }, d => {
                                        console.log('Login details saved')
                                    }, e => {
                                        console.log('Login details failed')
                                    }
                                )

                                setConfig((data) => (
                                    {
                                        ...data,
                                        sensors: sensors,
                                        idToken: d.data.idToken,
                                    }
                                ))
                                setCurrStep(3)
                                setHasChanged(true)
                                setPage_error('')
                            }
                        })
                        .catch(e => {

                            setPage_error('Error when getting user token')

                        })
                }
                else
                    setPage_error('Some sensors have the same ids')
            else
                setPage_error('Some sensors have errors')
        }
        else {
            setPage_error('Error on number of sensors')
        }
    }
    return (
        <div className='aqForm'>
            <div className='aq_fields'>
                <h4 style={{ marginTop: 0 }}>Step 2/3: Device config</h4>
                {
                    sensors.map((item) => {
                        console.log(item)
                        return (
                            <SensorUnit hasChanged={hasChanged} setHasChanged={setHasChanged}
                                sensorList={sensors} sensor={item} setSensors={setSensors} rooms={rooms}
                                isSelected={item.isSelected} error={item.error} id={item.id} idToken={config.idToken} />

                        )
                    })
                }

                <button className='validate_button' onClick={addDevice}>Add Device</button>
            </div>
            <div className=' bottom_field' style={{ width: '100%', }} >

                <div>{sensors.filter(item => item['isSelected'] === true).length} items selected</div>

                <div style={{ display: 'flex', justifyContent: 'space-between', alignContent: 'center', width: '100%', }}>
                    {sensors.filter(item => item['isSelected'] === true).length > 0 ?
                        <div style={{ alignSelf: 'flex-start' }}>

                            <button className="delete_button btn_size" onClick={removeDevice}> Delete</button>
                        </div>
                        : <div></div>
                    }
                    <div className='validate_close_div' style={{ alignSelf: 'flex-end' }}>
                        <button className='validate_button btn_size' onClick={(e) => {
                            setCurrStep(1);
                            setPage_error('')
                        }}>Previous</button>
                        <button className='validate_button btn_size' style={{ width: '73px', marginRight: '2px' }} onClick={saveData}>{saveBtn}</button>

                        <button className='close_buton btn_size' onClick={onClose}>Close</button>
                    </div>
                </div>
            </div>

        </div>
    )
}
export const VayyarConfigLoading = ({ currStep, config, setConfig, page_error, setPage_error, setCurrStep, onClose }) => {
    const [sensors, setSensors] = useState(config.sensors)
    const [checkConfig, setCheckConfig] = useState(false)
    const [loaded, setLoaded] = useState(false)

    useEffect(() => {
        if (!checkConfig) {

            let l_sensors = sensors;

            function createHooks(rr) {
                return rr.map(item => {
                    if (item.hasConfig === true)
                        return axios.post(`https://home.vayyarhomeapisdev.com/mqtthooks/${item.sensor_id}/`,
                            {
                                host: 'mqtt://mqtt.arquel.la',
                                port: '8883',
                                username: config.username,
                                password: config.password,
                            },
                            {
                                headers: {
                                    'Authorization': `Bearer ${config.idToken}`,
                                }
                            })
                            .then(async (d) => {
                                console.log('dd', d)
                                return d;
                            })
                            .catch(error => {
                                console.log('Hook creation error!', error);
                                return null;
                            });
                    else
                        return 0
                });
            }

            function readHooks(rr) {
                return rr.map(item => {
                    if (item.hasConfig)
                        return fetch(`https://home.vayyarhomeapisdev.com/mqtthooks/${item.sensor_id}/`, {
                            method: 'GET',
                            headers: {
                                'Authorization': `Bearer ${config.idToken}`,
                                'Content-Type': 'application/json'
                            }
                        })
                            .then(async (response) => {
                                return response.json()
                            })
                            .catch(error => {
                                console.log(`Error fetching data for:`, error);
                                return null;
                            });
                    else
                        return null;
                });
            }

            const getConfig = l_sensors.map(item => {

                return fetch(`https://home.vayyarhomeapisdev.com/deviceAdmin/${item.sensor_id}/config/`, {
                    method: 'GET',
                    headers: {
                        'Authorization': `Bearer ${config.idToken}`,
                        'Content-Type': 'application/json'
                    }
                })
                    .then(async (response) => {

                        return response.json()
                    })
                    .catch(error => {
                        console.error(`Error fetching data for:`, error);
                        return null;
                    });
            });

            Promise.all(getConfig)
                .then(d => {
                    let res = d.map((item, i) => {
                        if (item !== null)
                            return ({
                                ...sensors[i],
                                hasConfig: true,
                                msg: '',
                            })
                        else
                            return ({
                                ...sensors[i],
                                hasConfig: false,
                                msg: 'Invalid device id'
                            })
                    })
                    console.log('getConfig', res)

                    l_sensors = res;

                    setSensors(l_sensors)


                    Promise.all(createHooks(res))
                        .then(c_d => {
                            let c_res = c_d.map((c_item, c_i) => {
                                if (c_item === null)
                                    return ({
                                        ...l_sensors[c_i],
                                        hasHook: false,
                                        msg: 'Mqtt hook creation failed',
                                    })
                                else if (c_item === 0)
                                    return ({
                                        ...l_sensors[c_i],
                                        hasHook: false,
                                    })
                                else
                                    return ({
                                        ...l_sensors[c_i],
                                        hasHook: true,
                                        msg: '',
                                    })
                            })

                            console.log('Create hooks', c_res)

                            l_sensors = c_res;

                            setSensors(l_sensors)

                            Promise.all(readHooks(c_res))
                                .then(r_d => {
                                    let r_res = r_d.map((r_item, r_i) => {
                                        if (r_item !== null)
                                            return ({
                                                ...l_sensors[r_i],
                                                hasHook: true,
                                                msg: ''
                                            })
                                        else
                                            return ({
                                                ...l_sensors[r_i],
                                                hasHook: false,
                                                msg: l_sensors[r_i].msg === '' ? 'Cant read' : l_sensors[r_i].msg
                                            })
                                    })
                                    console.log('read hooks', r_res)

                                    l_sensors = r_res;

                                    setSensors(l_sensors)
                                    setLoaded(true)

                                })
                                .catch(e => {
                                    console.log(e)
                                    setLoaded(true)
                                })
                        })
                }).catch(e => {
                    console.log(e)
                    setLoaded(true)
                })

            setCheckConfig(true)
        }
    }, [])

    useEffect(() => {
        if (checkConfig === true && loaded === true) {
            setConfig((data) => (
                {
                    ...data,
                    sensors: sensors,
                    tryAgain: true,
                }
            ))
            // console.log('ggg', sensors.filter(item => item.msg === ''))
            if (sensors.filter(item => item.msg !== '').length > 0)
                setCurrStep(2)
            else
                setCurrStep(4)
        }
    }, [sensors, checkConfig, loaded])

    return (
        <div className='aqForm'>
            <div className='aq_fields'>

                <div style={{ height: '300px', width: '100%', display: 'flex', alignItems: 'center' }}>
                    <div style={{ width: '100%' }}>
                        <LoadingBanner />
                        <p style={{ textAlign: 'center', marginTop: '20px', fontSize: '15px' }}>Configuring devices, please wait</p>
                    </div>

                </div>
            </div>
            <div className=' bottom_field' style={{ width: '100%', }} >
            </div>
        </div>
    )
}
const SensorUnit = ({ hasChanged, setHasChanged, sensorList, sensor, setSensors, rooms, isSelected, error, id, idToken }) => {
    const [sensorID, setSensorID] = useState(sensor.sensor_id)
    const [paired_unit, setPaired_unit] = useState(sensor.paired_unit)
    const [sensorRoom, setRoom] = useState(sensor.sensor_room)
    const [checked, setChecked] = useState(isSelected);
    const [hasError, setHasError] = useState(error);
    const [divBg, setDivBg] = useState('div_default ');
    useEffect(() => {
        if (hasChanged) {
            setSensorID(sensor.sensor_id);
            setRoom(sensor.sensor_room);
            setPaired_unit(sensor.device);
            setHasError(sensor.error)

            setHasChanged(false)
        }

    }, [hasChanged])
    useEffect(() => {
        if (error !== hasError)
            setHasError(error)
    }, [error])

    useEffect(() => {
        setChecked(isSelected)
        let tmp = 'div_default '
        if (hasError) tmp += 'div_error '
        if (isSelected) tmp += 'div_notSelected '
        setDivBg(tmp)
    }, [isSelected, hasError])

    useEffect(() => {
        let val = true
        if (sensorID !== null && sensorID !== undefined && sensorID !== '')
            if (paired_unit !== null && paired_unit !== undefined)
                if (sensor.msg === '')
                    val = false

        const tmp = sensorList.map((item) => {
            if (item.id === id) {
                item['sensor_id'] = sensorID;
                item['sensor_room'] = sensorRoom;
                item['error'] = val;
                item['device'] = paired_unit;
            }
            return item;
        })
        setSensors(tmp);
        setHasError(val);

    }, [sensorID, paired_unit, sensor.msg])

    function handleSelection(e) {
        // setChecked(e.target.checked);
        const tmp = sensorList.map((item) => {
            if (item.id === id) {
                // console.log(e.target.name);
                item['isSelected'] = e.target.checked;
                // console.log(item)
            }
            return item;
        })
        setSensors(tmp);
    }


    function getConfig() {
        return new Promise((resolve, reject) => {
            return fetch(`https://home.vayyarhomeapisdev.com/deviceAdmin/${sensorID}/config/`, {
                method: 'GET',
                headers: {
                    'Authorization': `Bearer ${idToken}`,
                    'Content-Type': 'application/json'
                }
            })
                .then(async (response) => {
                    // console.log('Received response:', response); 

                    if (!response.ok) {
                        console.log(`HTTP error! Status: ${response.status} - ${response.statusText}`);
                        return reject(null);
                    }

                    try {
                        const data = await response.json();
                        console.log('Parsed data:', data);
                        resolve(data);
                    } catch (error) {
                        reject(null);
                    }
                })
                .catch(error => {
                    reject(null);
                });
        })
            .catch(finalError => {
                return null
            });

    }
    function checkDeviceConfig(e) {
        if (sensorID !== null && sensorID !== undefined && sensorID !== '')
            getConfig()
                .then(d => {
                    let tmp;
                    let herror = false
                    console.log('validation', d)
                    if (d !== null) {
                        tmp = sensorList.map((item) => {
                            if (item.id === id) {
                                item['msg'] = '';

                            }
                            return item;
                        })

                    }
                    else {
                        tmp = sensorList.map((item) => {
                            if (item.id === id) {
                                item['msg'] = 'Invalid device id';
                                herror = true;
                            }
                            return item;
                        })

                    }
                    setSensors(tmp);
                    // setHasError(herror)
                })
    }

    return (
        <div className={divBg}>
            {
                sensor.msg !== null &&
                <div style={{ marginLeft: '40px', color: 'red' }}>
                    {sensor.msg}
                </div>
            }
            <div style={{ display: 'flex', alignItems: 'center' }}>
                <Checkbox checked={checked} onChange={handleSelection} />

                <input
                    className="config_input shadow appearance-none border rounded  py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                    type="text" style={{ marginLeft: '5px', width: '200px' }} name="sensorID"
                    placeholder='device id' value={sensorID} onChange={(e) => { setSensorID(e.target.value) }}
                    onBlur={checkDeviceConfig} />

                <input
                    className="tag_input"
                    type="text"
                    id="autocomplete-input"
                    list="suggestions"
                    style={{ marginLeft: '5px', width: '200px' }}
                    placeholder="Select paired unit..."
                    value={sensorRoom}
                    onChange={(e) => {
                        let selectedOption
                        if (rooms !== null)
                            selectedOption = rooms.find(item =>
                                `${item.device_zone} ${item.device_name}` === e.target.value);

                        if (selectedOption !== undefined)
                            setPaired_unit(selectedOption)
                        else
                            setPaired_unit(null)

                        setRoom(e.target.value);

                    }}
                />
                <datalist id="suggestions">
                    {rooms !== null && rooms.map(o =>
                        <option key={o.id} value={`${o.device_zone} ${o.device_name}`}>{o.device_zone} {o.device_name}</option>)}
                </datalist>
            </div>


        </div>
    )
}