import { PropagateLoader  } from 'react-spinners';

const SpinnerLoader=({isLoading})=> {
    return (
        <div className="w-full max-h-100 spinner-container text-center">
            <PropagateLoader color="#36D7B7" loading={isLoading} />
        </div>
    );
}

export default SpinnerLoader;   