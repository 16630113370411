import React, { useEffect, useState } from 'react';
import { apiPost } from '../../../data/apidata';
import './NewIntegration.css';
import { useSnackbar } from 'notistack';

const NewIntegration=({onClose, data, siteID})=>{
    var integrationType = ''
    var existingNourish = false
    
    if(data.length !== 0){
        siteID = data[0].siteID
        integrationType = data[0].integrationType
        if (integrationType === 'Nourish'){existingNourish = true}
    }


    const { enqueueSnackbar } = useSnackbar();
    const [formData, setFormData] = useState({
        siteID: siteID,
        integration: integrationType
    })

    const [error, setError] = useState("");


    const handleChange=(e)=>{
        // console.log('check', e.target.value)
        const {name, value} = e.target;
        setFormData((prevData)=>({
            ...prevData,    
            [name]: value,
        }))
    }

    const handleSubmit=(e)=>{
        e.preventDefault();
        
        if(formData.integration===''){
            setError("You have not selected an Integration.");
        }
        else{
            apiPost(
                "api/messages/integrations/generate_site_key", 
                {
                    "siteID": formData.siteID,
                    "integrationType": "Nourish",
                    "integrationID": -1
                },
                (d)=>{
                    enqueueSnackbar("Integration created", {variant:"success"});
                    onClose()
                },
                (e)=>{console.log('generate_err',e)}
            )
            
        }
    }

    return (
        <div className='inner_layer' style={{width: '500px'}}>
            <h1>Create Integration</h1>
            <form className='aqForm' style={{height: '250px'}} onSubmit={handleSubmit}>
                <div className='aq_fields' style={{fontSize:'16px'}}>
                {(error!=="")&&<><small className='formFieldError'>{error}</small><br /></>}
                    <label>
                        Integrations :
                        <IntegrationInput
                            type='text'
                            name='integration'
                            value={'formData.integration'}
                            onChange={handleChange}/>
                    </label>
                    <br/>
                    <div style={{marginTop: '50px'}}>
                            An API key will be generated once this integration is created.
                            You will need to enter the API key in the digital care record app
                            to enable the integration.  
                    </div>
                    <div className='submit_fields'>
                        <div className='validate_close_div'>
                            <button className='validate_button' type='submit' style={{width: '70px'}}>Create</button>
                            <button className='close_buton' onClick={onClose} style={{width: '70px'}}>Close</button>
                        </div>
                    </div>
                </div>
            </form>
        </div>
    );
};


export default NewIntegration;

const IntegrationInput=({name, value, onChange })=>{
    return(
        <select className='aqselect' name={name} onChange={onChange}>
            <option selected={(value==='')} value={''}> </option>
            <option selected={(value==='Nourish')}  value={'Nourish'}>Nourish</option>
        </select>
    )
}