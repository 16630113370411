import { useAuth0 } from "@auth0/auth0-react";
import React from "react";
import { MobileNavBarTab } from "./mobile-nav-bar-tab";

export const MobileNavBarTabs = ({ handleClick }) => {
  const { isAuthenticated } = useAuth0();

  return (
    <div className="mobile-nav-bar__tabs">
      <MobileNavBarTab
        path="/live"
        label="Live"
        handleClick={handleClick}
      />
      <MobileNavBarTab
        path="/reports"
        label="Reports"
        handleClick={handleClick}
      />

<MobileNavBarTab
        path="/insights"
        label="Insights"
        handleClick={handleClick}
      />

<MobileNavBarTab
        path="/residents"
        label="Residents"
        handleClick={handleClick}
      />

<MobileNavBarTab
        path="/teams"
        label="Teams"
        handleClick={handleClick}
      />
      

      
      {/*isAuthenticated && (
        <>
          <MobileNavBarTab
            path="/admin"
            label="Admin"
            handleClick={handleClick}
          />
        </>
      )
    */}
    </div>
  );
};
