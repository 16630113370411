import React from "react";
import { NavLink } from "react-router-dom";
import IMG_LOGO from '../../../images/logo_white_background.jpg';

export const NavBarBrand = () => {
  return (
    <div className="nav-bar__brand">
      <NavLink to="/">
        <img
          className="nav-bar__logo"
          src={IMG_LOGO}
          alt="Arquella Connect"
          width="150"
          //height="26"
        />
      </NavLink>
    </div>
  );
};
