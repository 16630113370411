import { useEffect, useState } from "react";
import { FormInput } from "../Input";
import "./ResetPasswordForm.css";
import { apiPost } from "../../data/apidata";
import { useSnackbar } from "notistack";

const ResetPasswordForm=({id, onClose})=>{
    
    const { enqueueSnackbar } = useSnackbar();

    const [pass1, setPass1] = useState("");
    const [pass2, setPass2] = useState("");
    const [error, setError] = useState("");

    useEffect(()=>checkPassWord(), [pass2]);

    function checkPassWord(){
        console.log(`${pass1} - ${pass2}`);
        if (pass1!==pass2){
            setError("Passwords do not match.");
        }
        else {
            setError("");
        }
    }
      // Handle form submission
    const handleSubmit = (e) => {
        e.preventDefault();
        
        apiPost(
            "api/messages/user/password/reset",
            {
                "username" : id,
                "password" : pass1
            },
            (d)=>{ 
                enqueueSnackbar("Password has been reset.", {variant: "success"});
                if (onClose) onClose(); 
            },
            (e)=>{ setError(e) },
        )

    };

    const handleChange = (e) => {
        const { name, value } = e.target;
        if (name==="password1") setPass1(value);
        if (name==="password2") {
            setPass2(value);
        }
     };
        
    function isDisabled(){
        if (pass1==="") return true;
        if (pass2==="") return true;
        return (error!="");
    }

    return(
        <div className="reset_password_form aqForm">
            <h1>Reset Password</h1>
            <p>Enter a new temporary password.</p>
            <br />
            <form onSubmit={handleSubmit}>

                <div className="aq_fields">
                    <label>
                        New Password
                        <FormInput
                            type="password"
                            name="password1"
                            onChange={handleChange}
                            value={pass1}
                        />
                    </label>
                    <br /><br />
                    <label>
                        Confirm Password
                        <FormInput
                            type="password"
                            name="password2"
                            onChange={handleChange}
                            value={pass2}
                        />
                        <small className="formFieldError">{error}</small>
                    </label>

                </div>
                
                    <div className='submit_fields'>
                        <div className='validate_close_div'>
                            <button className='validate_button' disabled={isDisabled()} type="submit">Reset password</button>
                            <button className='close_buton' onClick={onClose}>Close</button>
                        </div>
                    </div>
                {/* <button
                    className="button_1"
                    disabled={isDisabled()}
                >Reset Password</button> */}
            </form>

            
        </div>
    );
}

export default ResetPasswordForm;