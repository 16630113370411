import { apiPost } from "../../data/apidata";

//function to post to api to delete zone profile
function DeleteZoneProfileHandler(profileID){
    return new Promise((resolve, reject)=>{
        apiPost(
            "api/messages/zoneprofiles/delete",
            {
                "profileID": profileID
            },
            (d) => {
                try {
                    console.log("response", d);
                    resolve(d.message)
                } catch (e) {
                    console.log("errorsss", e);
                    reject(e);
                }
            },
            (e) => { reject(e) }
        )
    
    });
}



export default DeleteZoneProfileHandler;