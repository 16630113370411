import { useEffect, useState } from 'react';
import { PieChart } from '@mui/x-charts/PieChart';
import { PieItemIdentifier, DefaultizedPieValueType } from '@mui/x-charts/models';
import { Typography, Stack } from '@mui/material';
import { groupBy } from '../../functions/objects_arrays';
import { DataModal, InsightDataModal } from '../charts/DataModal';
import { useModal } from '../ModalContext/ModalContext';
import AQDataTable from '../AQDataTable/AQDataTable';
import { columns_template_all_livecalls } from '../AQDataTable/columns_templates';

const formatObject = (obj) => {
  if (obj === null) {
    return '  undefined';
  }
  return JSON.stringify(obj, null, 2)
    .split('\n')
    .map((l) => `  ${l}`)
    .join('\n');
};
const PieChartCustom = ({ data, rawData, columns}) => {

  const [identifier, setIdentifier] = useState(null);
  const [id, setId] = useState(undefined);
  const [title, setTitle] = useState(null);
  const [selectedData, setSelectedData] = useState(null)
  const { isModalOpen, openModal, closeModal } = useModal();

  const handleClick = (
    event,
    itemIdentifier,
    item,
  ) => {
    setId(item.id);
    setIdentifier(itemIdentifier);
  };

  const handleItemClick = (data, item) => {
    closeModal()
    setTitle(data[item.dataIndex].id)
    setSelectedData(rawData[data[item.dataIndex].label])
  }

  useEffect(()=>{
    if (selectedData===null) {
      closeModal();
    }
    else {
      openModal();      
    }
  }, [selectedData]);

  return (
    <div>
      {(isModalOpen&&(selectedData!==null)) && (
        <InsightDataModal
          title={(title)?title:''}
          selectedData={(selectedData)?selectedData:null}
          columns={columns}
          closeModal={closeModal}
          setSelectedData={setSelectedData}>
        </InsightDataModal>
      )}
      {
        ((data) && (data !== null))
        &&
        <PieChart
          series={[
            {
              data: data,
              highlightScope: { faded: 'global', highlighted: 'item' },
              faded: { innerRadius: 30, additionalRadius: -30, color: 'gray' },
            },
          ]}
          //onClick={handleClick}
          width={400}
          height={200}
          margin={{ right: 200 }}
          onClick={(event, item) => handleItemClick(data, item)}
        />
      }
    </div>
  );

}


export default PieChartCustom;  