import { useEffect } from "react";
import { PageLayout } from "../../../components/page-layout";

const SuportPage=()=>{

    
        return(
                <div className="m-10">
                    <h1>Contact Support</h1>
                    <ScriptPage siteID={"notneeded"} />
                </div>
        );
}


const ScriptPage=({siteID})=> {
        
    let loadCount = 0;

    useEffect(()=>{  
        if (loadCount!==0) return;
        const script = document.createElement("script");
        
          script.src = "https://js.hsforms.net/forms/embed/v2.js";
          script.async = true;
        
          const cell = document.getElementById("hubspotForm")
          cell.innerHTML="";
          cell.appendChild(script);
    
          script.addEventListener('load', () => {
            if(window.hbspt) {
              window.hbspt.forms.create({
                region: "na1",
                portalId: "8709725",
                formId: "985d7801-9dd8-4bbd-8596-da74a6150217"
              })
            }
          });
        loadCount++;
    },[siteID]);
      
    return(<div id="hubspotForm"></div>);
      
}

export default SuportPage;