
import { useEffect, useState } from "react";
import { Grid, Col, Metric, Divider, Text, TextInput,  Tab,  TabList,  TabGroup,  TabPanel,  TabPanels, Card, Flex } from "@tremor/react";
import Nourish from "../integrations/Nourish";
import { getOnceValue } from "../../data/firebase";
import { groupBy } from "../../functions/objects_arrays";
import RoomUnitConfig from "../RoomUnitConfig/RoomUnitConfig";
import { IMAP } from "../Move/imap";
import { OnboardingSites } from "./OnboardingSites";
import { SiteOwners } from "./SiteOwners";
import { AQModal } from "../../components/Modal/Modal";
import SpinnerLoader from "../../components/SpinnerLoader";
import { addNewSite, getSitesList } from "../../functions/apiActions";
import { FormInput } from "../../components/Input";
import { SiteDetails } from "./SiteDetails";
import { FormTextInput } from "../../components/FormInputs/FormTextInput/FormTextInput";
import SelectAutoComplete from "../../components/FormInputs/SelectAutoComplete/SelectAutoComplete";
import { set } from "firebase/database";
import './onboarding.css';
import { PageLayout } from "../../components/page-layout";


export default function Onboarding({siteCode}) {

  return(
    <PageLayout>
      <ShowOnboarding />
    </PageLayout>
  );
}

const ShowOnboarding=()=>{

  const [formData, setFormData] = useState({email:"", password:""});

  function updateEvent(e){
    const { name, value } = e.target;
    // Update the form data with the changed field
    setFormData({
      ...formData,
      [name]: value,
    });
  }

  function createUser(){

  }

  return(
    <div>
      <FormTextInput 
        id={"email"}
        name="email" 
        value={formData.email}
        onChange={updateEvent}
        placeholder={"...email address"}
        errorText={""}
      />
      <FormTextInput 
        id={"password"}
        name="password" 
        value={formData.password}
        onChange={updateEvent}
        placeholder={"...password"}
        errorText={""}
      />
      <button
        onClick={()=>createUser()}
      >Create user</button>
    </div>
  );
}


function Onboardings({siteCode}) {

  /*
  const [siteConfig, setSiteConfig] = useState(null);
  
  useEffect(()=>{
    if (siteCode==="") return;
    getOnceValue(
      siteCode + "config",
      (d)=>setSiteConfig(d),
      (e)=>{console.log(e);}
    )
  },[siteCode]);
*/

return (
  <main 
  //className="px-12 py-12"
  >

    <Text>Site</Text>
    <Sites />
    

  </main>
);
  }


const Sites=()=>{

  const [viewSiteForm, setViewSiteForm] = useState(null);
  const [sitesList, setSitesList] = useState([]);

  function closeForm(){ setViewSiteForm(null); }

  useEffect(()=>{ 
    setSitesList([
      {
        s_ID : "-1",
        s_Name: "Tranby Park",
        s_db_code:"tranby"
      },
      {
        s_ID : "-2",
        s_Name: "Stadium Court",
        s_db_code:"stadcourt"
      }
    ]);
    //  getSitesList({}, (d)=>setSitesList(d)); 
  },[]);

  const mappedSites = sitesList.map((site)=>{
    return {
      name: site.s_Name,
      value: site.s_ID
    }
  });
 
  function setSite(siteID){
console.log("setSite");
    const site = sitesList.filter((site)=>site.s_ID===siteID)[0];
    if (site===undefined) return;
    setViewSiteForm(new Object({
                  id: site.s_ID,
                  siteName:site.s_Name,
                  siteCode:site.s_db_code
                }));
  }

  return(
    <div>
      <div className="flex-row">
        <SelectAutoComplete
          options={mappedSites}
          value={(viewSiteForm&&viewSiteForm.id) ? viewSiteForm.id : ""}
          name="site"
          placeholder="Choose your site"
          onChange={setSite}
        />
        {/*}
        <button
        onClick={()=>{
          setViewSiteForm({
            id: -1,
            siteName: "",
            siteCode: ""
          });
        }}
        ><NewSiteSVG /></button>
      */}
        </div>

      <div class="flex">
     
        <div class="w-3/4 bg-white p-4">
        {viewSiteForm!==null && <SiteForm siteDetails={viewSiteForm} />}
        </div>
      </div>
    </div>
  )

}

const SiteForm=({siteDetails})=>{

  const [site, setSite] = useState(siteDetails);
  
  function addSite(){
    if (site.id!==-1) return;
    addNewSite(site, (d)=>{
      if (parseInt(d).toString()==="NaN") { 
        return;
      }
      else {
        setSite((prevState) => ({ ...prevState, id: d }));
      }
    }
    );
  }
  
  const handleInputChange = (event) => {
    const { name, value } = event.target;
    setSite((prevState) => ({ ...prevState, [name]: value }));
  };

  const handleSubmit = (event) => {
    event.preventDefault();
    addSite();
  };
  
  useEffect(()=>{ setSite(siteDetails); },[siteDetails]);

  if ((site===null)||(site===undefined)) return <SpinnerLoader isLoading={true} />;
 
  return (
    <div className="mt-6">
 {//}     <Card>
}
  <div className="flex-row">
      <form className="site_form_width" onSubmit={handleSubmit}>
            <Flex flexDirection="row" alignItems="end">
              <FormTextInput label="Site Name" id="siteName" name="siteName" value={site.siteName} onChange={handleInputChange} placeholder={"Site Name"} />
            </Flex>

            <Flex flexDirection="row" alignItems="end">
              <FormTextInput label="Site Code" id="siteCode" name="siteCode" value={site.siteCode} onChange={handleInputChange} placeholder={"Site Code"} errorText={"invalid date"} />
            </Flex>
            <button type="submit">{(site.id===-1)?"Create Site":"Update"}</button>
      </form>
      {(site.id!==-1)&&<SiteDetails siteCode={site.siteCode} siteID={siteDetails} siteName={site.siteName} site={site} />}
  </div>
 {//</div>}     </Card>
}
    </div>

  );
}


const NewSiteSVG=()=>{

  return(
    <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="w-6 h-6">
      <path stroke-linecap="round" stroke-linejoin="round" d="M12 9v6m3-3H9m12 0a9 9 0 11-18 0 9 9 0 0118 0z" />
    </svg>
  );
}


