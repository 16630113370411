export const StyleGuide=()=>{

    return(
        <div>
            <h1>Header 1</h1>
            <h2>Header 2</h2>
            <h3>Header 3</h3>
            <h4>Header 4</h4>
            <h5>Header 5</h5>
            <p>Paragraph.</p>
            <p>Paragraph with <b>bold</b> and <i>italics</i></p>
            <div>Regular content not in a paragraph</div>
            
        </div>
    );
}