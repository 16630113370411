import React, { useEffect, useState } from 'react';
import { FormInput } from '../Input';
import './SitePage.css';
import { apiPost } from '../../data/apidata';
import { enqueueSnackbar, useSnackbar } from 'notistack';
import { SystemUpdateAltSharp as Download, Backup, Add } from '@mui/icons-material';
import { Checkbox } from '@mui/material';
import { v4 as uuidv4 } from 'uuid';
import PropTypes from 'prop-types';
import { SiteRoomConfig } from './SiteRoomConfig';
import * as XLSX from 'xlsx';
import { json } from 'd3';
const EditSiteDetails = ({ siteID, siteList, onClose }) => {

    const { enqueueSnackbar } = useSnackbar();
    const [currStep, setCurrStep] = useState(1);
    const [zones, setZones] = useState([]);
    const [devices, setDevices] = useState([]);
    const [services, setServices] = useState([])
    const [integrations, setIntegrations] = useState([])
    const [panel_num, setPanel_num] = useState(0)
    const [finalConfig, setFinalConfig] = useState();
    const [formData, setFormData] = useState(
        {
            site_name: '',
            site_id: '',
            site_code: '',
            site_address: '',
            site_postcode: '',

            mn_name: '',
            mn_email: '',
            mn_phone: '',

            mt_name: '',
            mt_email: '',
            mt_phone: '',

            dr_name: '',
            dr_email: '',
            dr_phone: '',
        }
    )
    const [formValidation, setValidation] = useState(
        {
            site_name: false,
            site_address: false,
            site_postcode: false,

            mn_name: false,
            mn_email: false,
            mn_phone: false,

            mt_name: false,
            mt_email: false,
            mt_phone: false,

            dr_name: false,
            dr_email: false,
            dr_phone: false,
        }
    )
    useEffect(() => {
        apiPost(
            'api/messages/site/management/GetSiteConfig',
            { site_id: siteID },
            d => {
                console.log(d['message']['data'][0][0])
                const data = d['message']['data'][0][0]
                setFormData(data)
                setValidation({
                    site_name: true,
                    site_address: true,
                    site_postcode: true,

                    mn_name: true,
                    mn_email: true,
                    mn_phone: true,

                    mt_name: true,
                    mt_email: true,
                    mt_phone: true,

                    dr_name: true,
                    dr_email: true,
                    dr_phone: true,
                })
            }

        )
    }, [siteID])

    return (
        <>
            {currStep === 1 && (
                <Step1 formData={formData} setFormData={setFormData} formValidation={formValidation} setValidation={setValidation} setCurrStep={setCurrStep} siteList={siteList} onClose={onClose} />
            )}
            {currStep === 2 && (
                <Step2 formData={formData} setFormData={setFormData} formValidation={formValidation} setValidation={setValidation} setCurrStep={setCurrStep} siteList={siteList} onClose={onClose} />
            )}
        </>
    );
};


export default EditSiteDetails;

const Step1 = ({ formData, setFormData, formValidation, setValidation, setCurrStep, siteList, onClose }) => {
    const [site_name_error, setSite_name_error] = useState('');
    const [page_error, setPage_error] = useState('');
    function handleChange(e) {
        setFormData((data) => ({
            ...data,
            [e.target.name]: e.target.value,
        })
        )
    }
    function checkSiteName(e) {
        handleChange(e)

        if (e.target.value !== '') {
            // const code = e.target.value.toLowerCase().replaceAll(/ /g, '')
            // setFormData((data) => ({
            //     ...data,
            //     site_code: code,
            // }))

            if (!siteList.some(item => formData.siteID !== item.site_id && item.site_name === e.target.value)
            ) {
                setSite_name_error('');

            }
            else {
                setSite_name_error('Site name or ref already exists');
            }
        }
        else {
            setSite_name_error('This field is required');
        }
    }

    function saveData(e) {
        if (site_name_error === '' && formValidation.site_address && formValidation.site_postcode) {
            if (formData.site_name !== '' && formData.site_address !== '' && formData.site_postcode !== '') {
                return setCurrStep(2);

            }
        }
        return setPage_error('Make sure all required fields are filled')

    }


    return (
        <div className='new_user_page_container'>
            <h1 style={{ marginBottom: '0px' }}>Edit site details</h1>
            <span className='formFieldError'>{page_error}</span>
            <br />
            <div className='aqForm'>
                <div className='aq_fields'>
                    <h4 style={{ marginTop: 0 }}>Step 1/2: Fill in site information</h4>

                    <label>
                        Site name <span className='red_star'>*</span>:
                        <FormInput
                            type="text"
                            name="site_name"
                            value={formData.site_name}
                            onChange={checkSiteName}
                        />
                        <span className='formFieldError'>{site_name_error}</span>
                    </label>
                    <br />
                    <label>
                        Site Ref <span className='red_star'>*</span>:
                        <input
                            className=" readonly_input shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                            type="text"
                            name="site_code"
                            readOnly={true}
                            value={formData.site_code}
                        />
                    </label>
                    <br />
                    <RequiredField Title='Address' name='site_address' value={formData.site_address} setFormData={setFormData} setValidation={setValidation} />
                    <RequiredField Title='Postcode' name='site_postcode' value={formData.site_postcode} setFormData={setFormData} setValidation={setValidation} />



                </div>
                <div className='submit_fields'>
                    <div className='validate_close_div'>
                        <button className='validate_button action_button' onClick={saveData}>Next</button>
                        <button className='close_buton action_button' onClick={onClose}>Close</button>
                    </div>
                </div>

            </div>
        </div>
    )
}


const Step2 = ({ formData, setFormData, formValidation, setValidation, setCurrStep, siteList, onClose }) => {
    const [page_error, setPage_error] = useState('');
    const [services, setServices] = useState([])
    const [integrations, setIntegrations] = useState([])
    const [serviceList, setServiceList] = useState(['Sense', 'Move', 'Call'])
    const [integrationList, setIntegrationList] = useState(['PCS', 'Nourish', 'Care vision'])
    const serviceSelector = React.useRef();
    const integrationSelector = React.useRef();
    useEffect(() => {
        console.log(formValidation)
        if (formData.services !== null && formData.services.length > 0)
            setServices(formData.services.map(item => { return item.name }))
        if (formData.integrations !== null && formData.integrations.length > 0)
            setIntegrations(formData.integrations.map(item => { return item.name }))
    }, [])
    function handleArquellaServices() {
        console.log(serviceSelector.current)
        if (serviceSelector.current.value !== '')
            if (!services.some(item => item === serviceSelector.current.value)) {
                setServices((data) => [
                    ...data,
                    serviceSelector.current.value,
                ])

            }

    }
    function handleIntegrations() {
        console.log(integrationSelector.current)
        if (integrationSelector.current.value !== '')
            if (!integrations.some(item => item === integrationSelector.current.value)) {
                setIntegrations((data) => [
                    ...data,
                    integrationSelector.current.value,
                ])

            }

    }

    function saveData(e) {
        if (formValidation.mn_email && formValidation.mn_email && formValidation.mn_phone)
            if (formData.mn_email !== '' && formData.mn_email !== '' && formData.mn_phone !== '') {
                console.log('validated')
                let conf = formData;
                conf['integrations'] = JSON.stringify(integrations)
                conf['services'] = JSON.stringify(services)
                console.log(conf)
                apiPost(
                    'api/messages/site/management/UpdateSiteDetails',
                    conf,
                    (d) => {
                        console.log('site id', d['message']['data'][0][0]);
                        console.log('new id', d['message']['data'][0][0].site_id)
                        enqueueSnackbar('Site details updated', { variant: 'success' })
                        return onClose();
                    },
                    (e) => {
                        console.log(e);
                        enqueueSnackbar('Site update failes', { variant: 'error' })
                    }
                )
            }
            else setPage_error('Make sure all required fields are filled')
        else setPage_error('Make sure all required fields are filled')

    }
    function goBack(e) {
        setCurrStep(1);
    }
    return (
        <div className='new_user_page_container'>
            <h1 style={{ marginBottom: '0px' }}>Edit site details</h1>
            <span className='formFieldError'>{page_error}</span>
            <br />
            <div className='aqForm'>
                <div className='aq_fields'>
                    <h4 style={{ marginTop: 0 }}>Step 2/2: Fill in contact information</h4>
                    {/* Manager info */}
                    <h5 style={{ marginTop: 0 }}>Manager contact information</h5>
                    <RequiredField Title='Name' name='mn_name' value={formData.mn_name} setFormData={setFormData} setValidation={setValidation} />
                    <RequiredField Title='Email' name='mn_email' value={formData.mn_email} setFormData={setFormData} setValidation={setValidation} />
                    <RequiredField Title='Phone Number' name='mn_phone' value={formData.mn_phone} setFormData={setFormData} setValidation={setValidation} />

                    {/* Maintenance info */}
                    <br />
                    <h5 style={{ marginTop: 0 }}>Maintenance contact information</h5>
                    <RequiredField Title='Name' name='mt_name' value={formData.mt_name} setFormData={setFormData} setValidation={setValidation} />
                    <RequiredField Title='Email' name='mt_email' value={formData.mt_email} setFormData={setFormData} setValidation={setValidation} />
                    <RequiredField Title='Phone Number' name='mt_phone' value={formData.mt_phone} setFormData={setFormData} setValidation={setValidation} />

                    {/* Director information */}
                    <br />
                    <h5 style={{ marginTop: 0 }}>Director contact information</h5>
                    <label>
                        Name:
                        <FormInput type="text" name="dr_name" value={formData.dr_name}
                            onChange={(e) => setFormData((data) => ({ ...data, dr_name: e.target.value, }))} />
                    </label>
                    <br />
                    <label>
                        Email:
                        <FormInput type="text" name="dr_email" value={formData.dr_email}
                            onChange={(e) => setFormData((data) => ({ ...data, dr_email: e.target.value, }))} />
                    </label>
                    <br />
                    <label>
                        Phone Number:
                        <FormInput type="text" name="dr_phone" value={formData.dr_phone}
                            onChange={(e) => setFormData((data) => ({ ...data, dr_phone: e.target.value, }))} />
                    </label>
                    <br />
                    <div>
                        Arquella services:
                        <select ref={serviceSelector} style={{ marginLeft: '35px' }} className="shadow  border rounded  py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                        >
                            <option value="" selected disabled hidden>Select service</option>
                            {serviceList != null && serviceList.map(item => {
                                return (
                                    <option value={item}>{item}</option>
                                )
                            })}
                        </select>
                        <button className='add_button' onClick={handleArquellaServices}>add <Add /></button>
                        <div style={{ marginTop: '5px' }}>
                            <Tags selection={services} setSelection={setServices} />
                        </div>
                    </div>

                    <div >
                        Third party integration:
                        <select ref={integrationSelector} style={{ marginLeft: '10px' }} className="shadow  border rounded  py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                        >
                            <option value="" selected disabled hidden>Select integration</option>
                            {integrationList != null && integrationList.map(item => {
                                return (
                                    <option value={item}>{item}</option>
                                )
                            })}
                        </select>
                        <button className='add_button' onClick={handleIntegrations}>add <Add /></button>
                        <div style={{ marginTop: '5px' }}>
                            <Tags selection={integrations} setSelection={(setIntegrations)} />
                        </div>
                    </div>
                </div>
                <div className='submit_fields'>
                    <div className='validate_close_div'>
                        <button className='validate_button action_button' onClick={goBack}>Previous</button>

                        <button className='validate_button action_button' onClick={saveData}>Save</button>
                        <button className='close_buton action_button' onClick={onClose}>Close</button>
                    </div>
                </div>

            </div>
        </div>
    )
}


const RequiredField = ({ Title, name, value, setFormData, setValidation }) => {
    const [error, setError] = useState('')

    useEffect(() => {
        if (error === '')
            setValidation((data) => ({
                ...data,
                [name]: true,
            })
            )
        else
            setValidation((data) => ({
                ...data,
                [name]: false,
            })
            )
    }, [error])

    function handleChange(e) {
        setFormData((data) => ({
            ...data,
            [e.target.name]: e.target.value,
        })
        )
    }
    function checkField(e, setError) {
        handleChange(e)
        if (e.target.value !== '') {
            setError('');
        }
        else setError('This field is required')
    }
    function handleFocus(e) {
        if (e.target.value !== '')
            if (e.target.name.includes('email') && !validateEmail(e.target.value))
                setError('Email is not valid')
        if (e.target.name.includes('phone') && !validatePhoneNumber(e.target.value))
            setError('Phone number is not valid')
    }
    const validateEmail = (email) => {
        const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
        return emailRegex.test(email);
    };
    const validatePhoneNumber = (phoneNumber) => {
        const ph1 = /^\d{11}$/;
        const ph2 = /^\d{10}$/;
        return (ph1.test(phoneNumber) || ph2.test(phoneNumber));
    };
    return (
        <>
            <label>
                {Title} <span className='red_star'>*</span>:
                <input
                    className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                    type="text"
                    name={name}
                    value={value}
                    onChange={(e) => checkField(e, setError)}
                    onBlur={handleFocus}

                />
                <span className='formFieldError'>{error}</span>
            </label>
            <br />
        </>
    )
}
const Tags = ({ selection, setSelection }) => {
    function removeValue(e) {
        const tbl = selection.filter((item) => item !== e)
        console.log(selection, tbl)
        setSelection(tbl)
    }
    function clearAll() {
        setSelection([])

    }
    return (
        <div style={{ display: "flex", flexDirection: 'column' }}>
            <div style={{ marginBottom: "0px" }}>
                {selection != null && selection.map((item) => (
                    <div className="tag-item">
                        <span className="text" >{item}</span>
                        <span onClick={() => removeValue(item)} className="close">&#10006;</span>
                    </div>
                ))
                }
                {selection.length > 1 &&
                    <button className='clear_button' onClick={clearAll}>Clear all</button>
                }

            </div>
        </div>
    )
}
const DeviceTags = ({ selection, setSelection }) => {
    function removeValue(e) {
        const tbl = selection.filter((item) => item !== e)
        setSelection(tbl)
    }
    function clearAll() {
        setSelection([])
    }
    return (
        <div style={{ display: "flex", flexDirection: 'column' }}>
            <div style={{ marginBottom: "0px" }}>
                {selection != null && selection.map((item) => (
                    <div className="tag-item">
                        <span className="text">{item.name}</span>
                        <span onClick={() => removeValue(item)} className="close">&#10006;</span>
                    </div>

                ))
                }
                {selection != null && selection.length > 1 &&
                    <button className='clear_button' onClick={clearAll}>Clear all</button>
                }
            </div>
        </div>
    )
}
