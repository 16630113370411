import { useState, useEffect } from "react";
import { columns_template_reporting_calls } from "../components/AQDataTable/columns_templates";
import TableTemplateAPI from "./Settings/UserManagement/TableTemplateAPI";
import { useUserSettings } from "../components/UserSettingsProvider/UserSettingsProvider";
import AQDateRangePicker from "../components/Chart/DateRangePicker/DateRangePicker";

export const ReportData=()=>{
    
    const sevenDaysAgo = new Date();
    sevenDaysAgo.setDate(sevenDaysAgo.getDate() - 7);
    sevenDaysAgo.setHours(0, 0, 0, 0); // Set to the start of the day
  
    const today = new Date();
    today.setHours(23, 59, 59, 999); 

    const [value, setValue] = useState({
        from: sevenDaysAgo.toISOString(),
        to: today.toISOString(),
    });
    
    const { userSettings, updateSettings } = useUserSettings();
    const [postData, setPostData] = useState({
        "siteID" : `${userSettings.site.id}`,
        "start": `${value.from}`,
        "end": `${value.to}`
    });
    

    function updateLocalValues(){
        localStorage.setItem("siteName", userSettings.site.name);
        try {
            localStorage.setItem("startDate", value.from);
            localStorage.setItem("endDate", value.to);
        }
        catch(e){
            console.log("UpdateocalValuesERROR", e);
        }
    }


    function updateDates(start, end){
        setValue({
            from: start,
            to: end
        });
    }
    
    useEffect(()=>{
        setPostData(new Object({
            "siteID" : `${userSettings.site.id}`,
            "start": `${value.from}`,
            "end": `${value.to}`
        }));
    
    },[value.from, value.to])

    updateLocalValues();


    return(
            <>
                <TableTemplateAPI
                    title={"Reports"}
                    apiPath={"/api/messages/reports/list"}
                    apiParams={postData}
                    tableTemplate={columns_template_reporting_calls}
                    tableSettingsType={"reporting_data"}
                    grouping={"journey"}
                >
                    <AQDateRangePicker start={value.from} end={value.to} onChange={updateDates} />
                </TableTemplateAPI>
                
            </>
    );
}

export default ReportData;