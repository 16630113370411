import { columns_template_zone_profiles } from "../../components/AQDataTable/columns_templates";
import { PageLayout } from "../../components/page-layout";
import TableTemplateAPI from "./UserManagement/TableTemplateAPI";
import { useUserSettings } from "../../components/UserSettingsProvider/UserSettingsProvider";
import { useState } from "react";

const ZoneProfiles=()=>{
    const { userSettings, updateSettings } = useUserSettings();

    console.log("usersss", userSettings.site.code);

    return(

            <TableTemplateAPI
                title={"Zone Profile Management"}
                apiPath={"/api/messages/zoneprofiles/list"}
                apiParams={{"username": userSettings.user.email, "userToken": userSettings.user.email}}
                tableTemplate={columns_template_zone_profiles}
                tableSettingsType={"zone_profile"}
                onUpdateRefreshPath={"zone_profiles"}
                siteID={userSettings.site.code}
            />

    );
}

export default ZoneProfiles;
