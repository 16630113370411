import { Checkbox, Grid } from "@mui/material";
import { useEffect, useState } from "react";
import { apiPost } from "../../data/apidata";
import { v4 as uuid } from 'uuid';
import axios from "axios";
import { LoadingBanner } from "../charts/Notifications";
import { timeout } from "d3";
import { Switch } from '@mui/material';

import { styled } from '@mui/material/styles';
import { enqueueSnackbar } from "notistack";
import { VayyarConfig, VayyarConfigLoading } from "./vayyar";
import { CogvisConfig } from "./cogvis";
import { GlobalConfig } from "./GlobalConfig";


export const SenseConfig = ({ siteID, onClose }) => {
    const [currStep, setCurrStep] = useState(1)
    const [page_error, setPage_error] = useState('')
    const [config, setConfig] = useState({})
    const [vendor, setVendor] = useState('')

    useEffect(() => {
        console.log("currStep", currStep)
    }, [currStep])

    useEffect(() => {
        console.log(`siteid ${siteID}`)
        apiPost(
            'api/messages/Sense/GetSiteSenseConfig',
            { site_id: siteID },
            d => {
                let data = d['message']['data'][0][0]
                console.log(data)

                setConfig(data)
            },
            e => {
                console.log(e)
            }
        )
    }, [siteID])

    return (
        <div className='new_user_page_container'><h1 style={{ marginBottom: '0px' }}>Sense configuration</h1>
            <span className='formFieldError'>{page_error}</span>
            <br />

            {/* <renderStep currStep={currStep} setCurrStep={setCurrStep}
                config={config} setConfig={setConfig} onClose={onClose}
                page_error={page_error} setPage_error={setPage_error} vendor={vendor} /> */}
            {renderStep({
                currStep: currStep, config: config, setConfig: setConfig, page_error: page_error, setPage_error: setPage_error, setCurrStep: setCurrStep, onClose: onClose, vendor: vendor, setVendor: setVendor
            })}
        </div>
    );

}
const renderStep = (props) => {
    switch (props.currStep) {
        case 1:
            return <Step1 {...props} />;
        case 2:
            return props.vendor === 'Vayyar' ? <VayyarConfig {...props} /> : <CogvisConfig {...props} />;
        case 3:
            return props.vendor === 'Vayyar' && <VayyarConfigLoading {...props} />;
        case 4:
            return props.vendor && <GlobalConfig {...props} />;
        default:
            return null;
    }
};
const Step1 = ({ currStep, config, setConfig, page_error, setPage_error, setCurrStep, onClose, vendor, setVendor }) => {

    const [username, setUsername] = useState('')
    const [password, setPassword] = useState('')
    const [num_sensor, setNum_sensor] = useState(0)
    function saveData() {
        if (num_sensor > 0) {
            if (vendor === 'Vayyar') {
                axios.post('https://home.vayyarhomeapisdev.com/token/idtoken/',
                    {
                        email: username,
                        password: password
                    })
                    .then(d => {
                        console.log('UserData', d.data);
                        if (d.status === 200) {
                            let sensors = []
                            for (var i = 0; i < num_sensor; i++)
                                sensors.push(
                                    {
                                        id: uuid(),
                                        sensor_id: '',
                                        sensor_room: '',
                                        isSelected: false,
                                        error: false,
                                        msg: ''
                                    })

                            setConfig((data) => (
                                {
                                    ...data,
                                    'sensors': sensors,
                                    idToken: d.data.idToken,
                                    username: username,
                                    password: password,
                                    vendor: vendor,
                                    tryAgain: false,
                                }
                            ))
                            setCurrStep(2);
                            setPage_error('')

                            console.log(d.data)
                            // console.log(config);
                        }
                    })
                    .catch(e => {
                        console.error('Error!', e);
                        setPage_error('Wrong username or passrword')
                    });
            }
            else if (vendor === 'Cogvis') {
                let sensors = []
                for (var i = 0; i < num_sensor; i++)
                    sensors.push(
                        {
                            id: uuid(),
                            sensor_id: '',
                            sensor_room: '',
                            isSelected: false,
                            error: false,
                            msg: ''
                        })

                setConfig((data) => (
                    {
                        ...data,
                        'sensors': sensors,
                        vendor: vendor,
                        tryAgain: false,
                    }
                ))
                setCurrStep(2);
                setPage_error('')

                console.log(config)
            }
        }
        else {
            setPage_error('Error on the number of sensors')
        }
    }

    return (
        <div className='aqForm'>
            <div className='aq_fields'>
                <h4 style={{ marginTop: 0 }}>Step 1/3: Vendor selection</h4>
                <label>
                    <span style={{ marginRight: '10px' }}>Vendor </span>
                    <select className="config_input shadow  border rounded  py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                        type="select" style={{ marginLeft: '64px' }} name="vendor" placeholder='zone name' value={vendor} onChange={(e) => setVendor(e.target.value)}>
                        <option hidden selected={vendor === ''}>Select vendor</option>
                        <option>Vayyar</option>
                        <option>Cogvis</option>
                    </select>
                </label>
                <br />
                <br />
                <label>
                    <span style={{ marginRight: '10px' }}>Number of sensors: </span>
                    <input
                        className="config_input shadow appearance-none border rounded  py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                        type="number" style={{ marginLeft: '0px' }} name="number" placeholder='number' value={num_sensor} onChange={(e) => setNum_sensor(e.target.value)} />
                </label>
                <br />
                <br />

                {vendor === 'Vayyar' &&
                    <div>
                        <label>
                            <span style={{ marginRight: '10px' }}>Username: </span>
                            <input
                                className="config_input shadow appearance-none border rounded  py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                                type="text" style={{ marginLeft: '45px' }} name="username" placeholder='username' value={username} onChange={(e) => setUsername(e.target.value)} />
                        </label>
                        <br />
                        <br />
                        <label>
                            <span style={{ marginRight: '10px' }}>Password: </span>
                            <input
                                className="config_input shadow appearance-none border rounded  py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                                type="password" style={{ marginLeft: '48px' }} name="zone" placeholder='password' value={password} onChange={(e) => setPassword(e.target.value)} />
                        </label>
                    </div>
                }

            </div>
            <div className='submit_fields'>
                <div className='validate_close_div'>
                    <button className='validate_button action_button' onClick={saveData}>Next</button>
                    <button className='close_buton action_button' onClick={onClose}>Close</button>
                </div>
            </div>

        </div>
    )
}