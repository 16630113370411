import './CreateAPI.css'
import { apiPost } from '../../../data/apidata';
import { enqueueSnackbar } from 'notistack';

const CreateAPI = ({onClose, data}) => {

    const siteID = data.siteID;
    const integrationID = data.integrationID

    const handleSubmit=(e)=>{
        e.preventDefault();

        apiPost(
            "api/messages/integrations/generate_site_key", 
            {
                "siteID": siteID,
                "integrationType": "Nourish",
                "integrationID": integrationID
            },
            (d)=>{
                enqueueSnackbar("API Key generated", {variant:"success"});
                onClose();
            },
            (e)=>{
                console.log('generate_err',e)
            }
        )
    }

    return(
        <div div className='inner_layer' style={{width: '500px'}}>
            <form className='aqForm' style={{height: '200px'}}>
            <h3>Generate new API key</h3>
                <div>
                    <span style={{color: '#FF8433'}}>Warning</span> : A new API key will be generated and
                    your integration will become inactive. 
                    You will need to enter the new API key in the digital care record app 
                    to enable the integration again.
                </div>
                <div className='submit_fields'>
                        <div className='validate_close_div'>
                            <button className='validate_button' onClick={handleSubmit} style={{width: '70px'}}>Generate</button>
                            <button className='close_buton' onClick={onClose} style={{width: '70px'}}>Close</button>
                        </div>
                </div>
            </form>
        </div>
    );
}

export default CreateAPI;