import { useEffect, useState } from "react";
import './UserManagement.css';
import { AQModal } from "../../../components/Modal/Modal";
import { FormTextInput } from "../../../components/FormInputs/FormTextInput/FormTextInput";
import { apiPost } from "../../../data/apidata";
import "./Devices.css";
import { columns_template_devices } from "../../../components/AQDataTable/columns_templates";
import TableTemplateFirebase from "./TableTemplate_Firebase";
import { useUserSettings } from "../../../components/UserSettingsProvider/UserSettingsProvider";
import TableTemplateAPI from "./TableTemplateAPI";

const Devices = ()=> {

    const { userSettings, updateSettings } = useUserSettings();
    
    return(

        <TableTemplateFirebase
            title={"Devices"}
            db={""}
            dbRef={`${userSettings.site.code}config`}
            tableTemplate={columns_template_devices}
            tableSettingsType={"devices"}
        />
        
    );

}

const ViewDetailsLink=(props)=>{
    
    function clickEvent(){
        props.onClick && props.onClick();
    }

    return <div 
    className="view_details_link"
    onClick={()=>clickEvent()}
>[{props.children}]</div>
}


const UserForm=({userForm, onClose})=>{

    const defaultForm = {
            id:-1,
            title: "",
            titleError: "",
            fName: "",
            fNameError: "",
            surname: "",
            surnameError: "",
            username: "",
            usernameError: "",
            email: "",
            emailError: "",
            password: "",
            passwordError: "",
            role: "",
            roleError: "",
    }

    const [form, setForm] = useState(userForm?userForm:defaultForm);
    const [disableSubmit, setDisableSubmit] = useState(false);
    const [submitError, setSubmitError] = useState("");

    useEffect(()=>validateForm(),[form]);
    //useEffect(()=>validateForm(),[]);

    function postSuccess(d){
        onClose();
    }

    function postError(e){
        setSubmitError(e);
    }

    function createUser(){
        apiPost("/api/messages/site/users/add", form, 
            (d)=>postSuccess(d), 
            (e)=>postError(e)
        );
    }

    function validateForm(){
        return;
        let formValue = false;
        if ((form.fName==="")&&(!disableSubmit)) formValue = true;
        if ((form.surname==="")&&(!disableSubmit)) formValue = true;
        if ((form.email==="")&&(!disableSubmit)) formValue = true;
        if ((form.password==="")&&(!disableSubmit)) formValue = true;
        if ((form.role==="")&&(!disableSubmit)) formValue = true;
        setDisableSubmit(formValue);
    }

    function updateForm(e){
        const { name, value } = e.target;
        // Update the form state with the new value
        setForm({
          ...form,
          [name]: value,
        });
        validateForm();
    }
    
    function handleIsValidCallBack(isValid){
        //setDisableSubmit(!isValid);
    }

    function modalCloseEvent(){
        console.log("closing");
    }

    return(
        <AQModal show={true} onClose={modalCloseEvent} hideFooter={true}>
            <div style={{minWidth:"300px"}}>
            <h3>Create New user</h3>
            {(submitError!=="")&&<div className="form_error">{submitError}</div>}
            <table>
                <tr>
                    <td>Title</td>
                    <td>
                        <FormTextInput
                            label={""}
                            id={"title"}
                            name={"title"}
                            value={form.title}
                            onChange={updateForm}
                            placeholder={""}
                            errorText={""}

                        />
                    </td>
                </tr>
                <tr>
                    <td>First name</td>
                    <td>
                        <FormTextInput
                            label={""}
                            id={"fName"}
                            name={"fName"}
                            value={form.fName}
                            onChange={updateForm}
                            placeholder={""}
                            errorText={""}
    
                        />
                    </td>
                </tr>
                <tr>
                    <td>Last name</td>
                    <td>
                        <FormTextInput
                            label={""}
                            id={"surname"}
                            name={"surname"}
                            value={form.surname}
                            onChange={updateForm}
                            placeholder={""}
                            errorText={""}

                        />
                    </td>
                </tr>
                <tr>
                    <td>Email</td>
                    <td>
                        <FormTextInput
                            label={""}
                            id={"email"}
                            name={"email"}
                            value={form.email}
                            onChange={updateForm}
                            placeholder={""}
                            errorText={""}
                        />
                    </td>
                </tr>
                <tr>
                    <td>Username</td>
                    <td>
                        <FormTextInput
                            label={""}
                            id={"username"}
                            name={"username"}
                            value={form.username}
                            onChange={updateForm}
                            placeholder={""}
                            errorText={""}
                        />
                    </td>
                </tr>
                <tr>
                    <td>Password</td>
                    <td>
                        <FormTextInput
                            label={""}
                            id={"password"}
                            name={"password"}
                            value={form.password}
                            onChange={updateForm}
                            placeholder={""}
                            errorText={""}
                        />
                    </td>
                </tr>
                <tr>
                    <td>Role</td>
                    <td>
                        <FormTextInput
                            label={""}
                            id={"role"}
                            name={"role"}
                            value={form.role}
                            onChange={updateForm}
                            placeholder={""}
                            errorText={""}
                        />
                    </td>
                </tr>
            </table>
            <div>
                <button 
                    onClick={()=>{
                    if (onClose!==undefined) onClose();}}
                    className="button_1">Cancel</button>
                <button className="button_1" disabled={disableSubmit} onClick={()=>{createUser()}} >Create</button>
            </div>
            </div>
        </AQModal>
    );

}

const BoldDiv=({children})=>{
    return <div><b>{children}</b></div>
}

const ExportIcon=()=>{

    return (
        <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="w-10 h-10">
            <path stroke-linecap="round" stroke-linejoin="round" d="M9 8.25H7.5a2.25 2.25 0 0 0-2.25 2.25v9a2.25 2.25 0 0 0 2.25 2.25h9a2.25 2.25 0 0 0 2.25-2.25v-9a2.25 2.25 0 0 0-2.25-2.25H15m0-3-3-3m0 0-3 3m3-3V15" />
        </svg>
    );

}

const SortDownIcon=()=>{
    return(
        <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="w-6 h-6">
            <path stroke-linecap="round" stroke-linejoin="round" d="M3 4.5h14.25M3 9h9.75M3 13.5h9.75m4.5-4.5v12m0 0-3.75-3.75M17.25 21 21 17.25" />
        </svg>
    );
}

const VerticalDotsIcon=()=><svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="w-6 h-6">
<path stroke-linecap="round" stroke-linejoin="round" d="M12 6.75a.75.75 0 1 1 0-1.5.75.75 0 0 1 0 1.5ZM12 12.75a.75.75 0 1 1 0-1.5.75.75 0 0 1 0 1.5ZM12 18.75a.75.75 0 1 1 0-1.5.75.75 0 0 1 0 1.5Z" />
</svg>


export default Devices;