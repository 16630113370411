import { useState } from "react";
import { useEffect } from "react";
import './monitor.css';
import { PageLayout } from "../../../components/page-layout";
import { apiPost } from "../../../data/apidata";
import Alert from '@mui/material/Alert';
import { IconButton } from "@mui/material";
//import { SnackMessage } from "./SnackMessage";
import { SnackbarProvider, VariantType, useSnackbar } from 'notistack';



const SystemCheckEvents=({site})=>{

    const { enqueueSnackbar } = useSnackbar();
    const [data, setData] =  useState(null);

    function getDataOnce(){

        apiPost(
            "/api/messages/systemcheckevent/list",
            {},
            (d)=>setData(d), 
            ()=>{console.log("error")}
        );

    }

    useEffect(()=>getDataOnce(), [site]);


    const ShowReports=({data})=>{
        console.log("dddddd", data);
        
        const [selection, setSelection] = useState("");
        const [show, setShow] = useState(true);
    
        function close(){
            console.log("CLOSING");
            setShow(false);
        }
    
        const handleClick = () => {
            enqueueSnackbar('I love snacks.');
          };
        
          const handleClickVariant = (variant) => () => {
            // variant could be success, error, warning, info, or default
            enqueueSnackbar('This is a success message!', { variant });
          };
    
        return(
            <div>
                <ShowSelection data={data} />
                <button onClick={handleClick}>Show snackbar</button>
                <button onClick={handleClickVariant('success')}>Show success snackbar</button>
            </div>
        );
    }
    
    
    const ShowSelection=({data})=>{
    
        return(
            <table>
                <tr>
                    <th>Date</th>
                    <th>Panel</th>
                    <th>Version</th>
                    <th>Battery</th>
                    <th>Date</th>
                    <th>Panel</th>
                    <th>Version</th>
                    <th>Battery</th>
    
                </tr>
                {
                Object.keys(data).map(d=>{
                    // sce_id
                    return(
                        <tr>
                            <td>{data[d]["sce_datetime"]}</td>
                            <td>{data[d]["sce_user"]}</td>
                            <td>{data[d]["sce_site"]}</td>
                            <td>{data[d]["sce_title"]}</td>
                            <td>{data[d]["sce_description"]}</td>
                        </tr> 
                    )
                })
                }
            </table>
        );
    
    }

    console.log("EVVVVV", data);
    //if (data===null) return (<div>Loading...</div>);
    return(
        <PageLayout>
            <div>
                <div>
                    {data!=null&&<ShowReports data={data} />}
                </div>
            </div>
        </PageLayout>
    );



   

}



export default SystemCheckEvents;