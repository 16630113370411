import { useState, useEffect } from "react";
import { Accordion, AccordionHeader, List, ListItem, Card, Title } from "@tremor/react";
import { GetSenseImage } from "../GetImage";
import { apiPost } from "../../data/apidata";
import "./Notifications.css";
import AQToolTip from "../ToolTip/ToolTip";
import { displayDateFromUTC, displayDateTimeFromUTC } from "../../functions/dataHandler";
import {  getOnValue,getOnceValue } from "../../data/firebase";
import {Grid} from '@mui/material';
import { useUserSettings } from "../UserSettingsProvider/UserSettingsProvider";
import IMG_EMERGENCY from  '../../images/call_types/emergency.png'; 
import IMG_NONOTIFICATION from  '../../images/settings/noNotification.png'; 
import Badge from '@mui/material/Badge';
import { useNavigate } from "react-router-dom";
import CircularProgress from '@mui/material/CircularProgress';


export default function Notifications({sitesList, siteID}){
  
  const [notifications, setNotifications] = useState([]);
    const [unitCall, setUnitCall] = useState([]);
    const { userSettings, updateSettings } = useUserSettings();
    const [isLoading, setIsLoading] = useState(true);
    

    function getUnitcalls() {
      const siteID = userSettings.siteID;
        if (siteID === "") return;

        const {code} = userSettings.site;

        getOnValue(code+'heartBeat',(d)=>{
            // if(d===null || d===undefined)
            //   setUnitCall([])
            // else
            //   setUnitCall(d)
            setUnitCall(d)
            // console.log(d)
          // console.log("heartbeat data", d)

          let transformedData = []

        if(d!==null){

        Object.entries(d).map(([key, value])=>{
            var newTime = null;
            var newRoom = null;
            var newZone = null;
            const newStatus = 'Unit inactive';
            if (value.room && typeof value.room === 'string') {
                newRoom = value.room;
            } else {
                newRoom = 'Not Available'
            }
            if (value.zone && typeof value.zone === 'string') {
                newZone = value.zone;
            } else {
                newZone = 'Not Available'
            }

            if (value.time && typeof value.time === 'string') {
                const [date, timeWithMicroseconds] = value.time.split('T');
                const [time] = timeWithMicroseconds.split('.');
                const [year, month, day] = date.split('-');

                // Combine into desired format DD/MM/YYYY HH:MM:SS
                newTime = `${day}/${month}/${year} ${time}`;

                // Convert to Date object
                const dateTime = new Date(`${date}T${time}`);
                const now = new Date();

                // Calculate the time difference in hours
                const timeDifferenceInHours = (now - dateTime) / (1000 * 60 * 60);

                // Get only inactive
               if(timeDifferenceInHours > 24 ) 
                transformedData.push({ unitStatus: newStatus, unitId: key, unitName: newRoom, unitZone: newZone, lastTime: newTime});
            } else {
                newTime = 'Not Available';
            }
            });

            setUnitCall(transformedData);
            // if(transformedData.length>0)
            // console.log("inactive",transformedData[0])
          }
            setIsLoading(false);
            
          //setIsLoading(false);
        },
        ()=>{
          //setIsLoading(false);
        })
    }


    


   function getNotifications(){
    apiPost("/api/messages/notifications/list", {"siteID": siteID},(d)=>{
      setNotifications(d.message.lowbattery);
      setIsLoading(false);
    },
    (e)=>{console.log(e)
      setIsLoading(false);
    });
  }
  
  function setNotificationsTimer(){
    
        setTimeout(()=>{
          setIsLoading(true);
          getUnitcalls();
          getNotifications();
          //setIsLoading(false);
        }, 1*60000) ;

  }
  //adc disabled notifications
  useEffect(()=>{
    getNotifications();
    getUnitcalls();
    setNotificationsTimer();
  },[sitesList]); 

  return(
  <Card 
    className="height-full"
  >
    <Title className="dashboard_section_title upper_case">Notifications</Title>
    <div className="dashboard_frame" style={{overflowY:"hidden"}}>
      
      {(isLoading)
      ?<LoadingBanner/>:
              
      ((notifications!==null)&&(notifications.length>0)) || ((unitCall!==null)&&(unitCall.length>0)) 
        ?   
            <ShowNotifications data={notifications} unitData={unitCall}/>
            
        : <div className="notification_nodata">
          <div>
            <img src={IMG_NONOTIFICATION} color="#000000" width={"50px"}/>
            {/* <NoNotification/> */}
            </div>
            <div>
            <div className="caughtUP">You're all caught up!</div>
            <div className="nonotif">No new notifications.</div>
            </div>
          </div>
      }

    </div>

  </Card>
)};


const ShowNotifications=({data, unitData})=>{
  const [hasData, setHasData] = useState(true);
  if(data.length===0 && unitData.length===0)
    setHasData(false)
  data = data.filter(f=>(f.level===0));
  data = data.filter(f=>(f.charging===0));

return(
  <div >
    {hasData===true?
      <Grid container spacing={1} className="notifications_container">

{((unitData!==null) && (unitData.length))>0?
        <Grid item lg={6} md={6} xs={12}>
<NotificationBanner title={"Unit Inactive"} type={"InactiveUnit"} number={unitData.length} goToPath={"/heartbeat monitoring"}/>
        </Grid>:<></>}

        {data.length>0?
        <Grid item lg={6} md={6} xs={12}>
<NotificationBanner title={"Low Battery"} type={"low_battery"} number={data.length} goToPath={"/battery levels"}/>
        </Grid>:<></>}


        </Grid>
:<></>
}
        </div>
)
  
}

const LoadingBanner=()=>{
  return(
  <div className="notification_nodata">
        <CircularProgress 
        color="primary"/>
      </div>
  )
}
export {LoadingBanner};

const NotificationBanner=({title, type, number, goToPath})=>{
 // console.log(`notifications_row notifications_${type}`)
 const navigate = useNavigate();
 function goTo(){
  navigate(goToPath);
 }
  return(
    // <button className={`notifications_row notifications_${type}`} onClick={goTo}>
       <div className={`notifications_row notifications_${type}`} style={{ width:"100%"}} onClick={goTo}>
  
            <div style={{display:"inline-block"}} >
              <Badge badgeContent={number} max={9} style={{color:"white",}} color="error" sx={{ "& .MuiBadge-badge": { fontSize: 14} }}>
              {type==="low_battery"?
              <GetIcon iconType={"Low Battery"}  width="40px" />
              :null}
              {type==="InactiveUnit"?
              <img src={IMG_EMERGENCY}  width="40px" alt="Unit Inactve"/>
              :null}
              </Badge>
              </div>
            <span className="notification_text">{title}</span>
            </div>
          // </button>
  )
}

const GetIcon=({iconType})=>{
  if (iconType==="Low Battery") return <BatteryIconLow />; 
  if (iconType==="High Battery") return <BatteryIconHigh />;
  if (iconType==="Charging") return <ChargingIcon />;
  return <></>;
}

const NoNotification=()=>{
  return(
  <svg width={"50px"} xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="grey" class="size-6">
  <path fill-rule="evenodd" d="M5.25 9a6.75 6.75 0 0 1 13.5 0v.75c0 2.123.8 4.057 2.118 5.52a.75.75 0 0 1-.297 1.206c-1.544.57-3.16.99-4.831 1.243a3.75 3.75 0 1 1-7.48 0 24.585 24.585 0 0 1-4.831-1.244.75.75 0 0 1-.298-1.205A8.217 8.217 0 0 0 5.25 9.75V9Zm4.502 8.9a2.25 2.25 0 1 0 4.496 0 25.057 25.057 0 0 1-4.496 0Z" clip-rule="evenodd" />
</svg>


  )

}
const ChargingIcon=()=><svg fill="#ffffff" width="30px" height="35px" viewBox="0 0 32 32" version="1.1" xmlns="http://www.w3.org/2000/svg">
<path d="M14.389 1c-0.89 0-3.223 0.747-3.223 1.67v1.67h-3.193c-1.78 0-3.223 1.493-3.223 3.34v19.981c0 1.847 1.442 3.34 3.223 3.34h16.055c1.78 0 3.223-1.493 3.223-3.34v-19.98c0-1.847-1.442-3.34-3.223-3.34h-3.193v-1.67c0-0.923-2.333-1.67-3.223-1.67l-3.224-0.001zM16 7.68v8.32h6.416l-6.416 11.66v-8.32h-6.416l6.416-11.66z"></path>
</svg>

const BatteryIconLow = ()=><svg fill="#ffffff" width="30px" height="35px" viewBox="0 0 32 32" version="1.1" xmlns="http://www.w3.org/2000/svg">
<path d="M12.563 1c-0.937 0-1.25 0.211-1.25 1.25v0.937h-2.813c-1.847 0-2.187 0.341-2.187 2.187v23.438c0 1.847 0.341 2.187 2.187 2.187h15c1.847 0 2.187-0.341 2.187-2.187v-23.438c0-1.847-0.341-2.187-2.187-2.187h-2.813v-0.937c0-1.038-0.312-1.25-1.25-1.25h-6.875zM8.5 7.563c0-2.5-0.312-2.187 2.187-2.187 1.25 0 9.688 0 10.625 0 2.5 0 2.187-0.312 2.187 2.187 0 1.25 0 17.813 0 19.063 0 2.5 0.312 2.187-2.187 2.187-1.25 0-9.375 0-10.625 0-2.5 0-2.187 0.312-2.187-2.187 0-1.251 0-17.813 0-19.063zM10.062 23.188h11.875c0.345 0 0.625 0.28 0.625 0.625v3.438c0 0.345-0.28 0.625-0.625 0.625h-11.875c-0.345 0-0.625-0.28-0.625-0.625v-3.438c0-0.345 0.28-0.625 0.625-0.625z"></path>
</svg>



const BatteryIconHigh = ()=><svg xmlns="http://www.w3.org/2000/svg" fill="green" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="w-12 h-12">
<path stroke-linecap="round" stroke-linejoin="round" d="M21 10.5h.375c.621 0 1.125.504 1.125 1.125v2.25c0 .621-.504 1.125-1.125 1.125H21M3.75 18h15A2.25 2.25 0 0 0 21 15.75v-6a2.25 2.25 0 0 0-2.25-2.25h-15A2.25 2.25 0 0 0 1.5 9.75v6A2.25 2.25 0 0 0 3.75 18Z" />
</svg>


const PhoneIcon = ()=><svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="w-6 h-6">
<path stroke-linecap="round" stroke-linejoin="round" d="M10.5 1.5H8.25A2.25 2.25 0 0 0 6 3.75v16.5a2.25 2.25 0 0 0 2.25 2.25h7.5A2.25 2.25 0 0 0 18 20.25V3.75a2.25 2.25 0 0 0-2.25-2.25H13.5m-3 0V3h3V1.5m-3 0h3m-3 18.75h3" />
</svg>


const CheckLocation=({location, calls})=>{
  const filteredCalls = calls.filter(c=>(c.location===location));
  
  if (filteredCalls.length===0) return <></>;
  //console.log({filteredCalls})
  
  return(
    <>
      <CallCard data={filteredCalls} location={location} />
    </>
  );
}

const CallCard=({data, location})=>{
  
  return (
    <div>

      <div className={"bg_sense_" + location + " colour-white flex-row"}>      
        <CallHeader data={location} />
        <div>
          <List>
            {data.map((d) => (
              <ListItem className="colour-white" key={d.room}>
                <span>{d.room}</span>
              </ListItem>
            ))
            }
          </List>
        </div>
      </div>
    </div>
  );

}

function getLocationDisplay(location){
  if (location==="bed") return "In bed";
  if (location==="fall") return "Fall";
  if (location==="manOnGround") return "On the ground";
  if (location==="bathroom") return "In bathroom";
  if (location==="chair") return "In Chair";
  if (location==="room") return "In Room";

  return location;
}

const CallHeader=({data})=>{
  return (
    <div className="sense_call_header_container">
        <GetSenseImage imgString={data.toLowerCase()} />
        <div className="align-left left-padding-10">{getLocationDisplay(data)}</div>
    </div>
  );
}


const CallBody=({data})=>{

  return (
    <div>
      Call Details
    </div>
  );
}