import { columns_template_site_management } from "../../components/AQDataTable/columns_templates";
import TableTemplateAPI from "../Settings/UserManagement/TableTemplateAPI";

const SiteManagement = () => {


    return (


        <TableTemplateAPI
            title={"Site Management"}
            apiPath={"/api/messages/site/management/list"}
            apiParams={{ "userID": "1" }}
            tableTemplate={columns_template_site_management}
            tableSettingsType={"site_management"}
        />

    );
}

export default SiteManagement;