import {
  Card,
  Text,
  BadgeDelta,
  Flex,
  Metric,
  ProgressBar,
  DeltaBar
} from "@tremor/react";
import { secondsToHHMMSS } from "../functions/dataHandler";
import { useState } from "react";
import { DataBreakDown } from "./DataBreakDown";
import "./Card.css"


export default function DashboardCard({item, postFilters}) {
  
  const [callDataVal, setCallDataVal] = useState(false);

  const GetItem=()=>{
    return <AttendanceCard 
    //onClick={updateBreakdown} 
    item={item} />;
  }
  
  function updateBreakdown(){ setCallDataVal(!callDataVal); }

  
  return (
    <div 
      //onClick={()=>updateBreakdown(item.title)}
      className="card_click_listener"
      >
      {(callDataVal===true) && <DataBreakDown 
      preData={(item.rawData)?item.rawData:[]}
      onClose={updateBreakdown} />}
      <GetItem />
    </div>
  );
  
}



function calculatePercentage(top, bottom){
  return (top/bottom)*100;
}

function increaseIsPositive(callType){
  if (callType=="Attendance") return true;
  return false;
}

export const AttendanceCard=({item, onClick})=>{
  return(

    <Card
    onClick={onClick}
    key={item.title}>
      <div className="dashboard_card_layout">

        <div className="dashboard_card_title">{item.title}</div>
        <div className="dashboard_card_metric">{item.metric}</div>

      </div>

    {((item.progress!==undefined)&&(item.target!==undefined))&&
      <>
              <div className="mt-4 space-x-2 dashboard_card_high_low">
                <div className="truncate">{`${secondToMin(item.progress)}`}</div>
                <div>{secondToMin(item.target)}</div>
              </div>

              <ProgressBar 
                value={calculatePercentage(item.progress, item.target)} 
                target={item.target} 
                color="teal" 
                className="mt-3" 
              />
      </>
      }
    
    </Card>

  );
}


export const StandardCard=({item, onClick})=>{
  return(

    <Card
    onClick={onClick}
    key={item.title}>
      <div className="dashboard_card_layout">

        <div className="dashboard_card_title">{item.title}</div>
        <div className="dashboard_card_metric">{item.metric}</div>

      </div>

          {((item.progress!==undefined)&&(item.target!==undefined))&&
          <>
              <div className="mt-4 space-x-2 dashboard_card_high_low">
                <div className="truncate">{`${secondToMin(item.progress)}`}</div>
                <div>{secondToMin(item.target)}</div>
              </div>

              
              <ProgressBar 
                value={calculatePercentage(item.progress, item.target)} 
                target={item.target} 
                color="teal" 
                className="mt-3" 
              />
              </>
              }
          </Card>

  );
}

function secondToMin(secs){
  var date = new Date(1970,0,1);
  date.setSeconds(secs);
  return date.toTimeString().replace(/.*(\d{2}:\d{2}).*/, "$1");
}


