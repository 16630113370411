import React from "react";
import { NavBar } from "./navigation/desktop/nav-bar";
import { MobileNavBar } from "./navigation/mobile/mobile-nav-bar";
import { PageFooter } from "./page-footer";
import { MainContentLayout } from "../Pages/MainContentLayout";

export const PageLayout = ({ user, children }) => {
  return (
    <div className="page-layout">
      <NavBar user={user} />
      <MobileNavBar />
      <div className="page-layout__content">
        <MainContentLayout>{children}</MainContentLayout>
      </div>
      {//<PageFooter />
}
    </div>
  );
};
