import { useEffect, useState } from "react";
import { apiPost } from "../../../data/apidata";
import { GetSite } from "./site/site";

export const AdminSitesList=()=>{

    const [selectedSiteID, setSelectedSiteID] = useState(null);

    return(
        <div>
            Admin sites list
            
            <GetSitesList onChange={setSelectedSiteID} />

            {(selectedSiteID!==null)&&<GetSite siteID={selectedSiteID} />}

        </div>
    );
}

const GetSitesList=({onChange})=>{

    const [firstTimeLoad, setFirsTimeLoad] = useState(true);
    const [sites, setSites] = useState([]);

    function getAPIData(){
        apiPost("api/messages/user/site/listAll", {},
        (d)=>{ try{setSites(d.message.data);} catch(e){console.log(e)} },
        (e)=>{ console.log(e); })
    }

    useEffect(()=>{
        getAPIData();
        setFirsTimeLoad(false);
    }, [setFirsTimeLoad===true])

    return(
        <div>
            <select onChange={(e)=>{
                if (onChange) onChange(e.target.value);
            }}>
                <option value=""></option>
                {sites.map(s=><option value={s.id}>{s.name}</option>)}
            </select>
        </div>
    );
}

