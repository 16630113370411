import React, { useEffect, useState, useRef } from 'react';
import '../Nourish/NourishMonitor';
import { apiPost } from '../../data/apidata';
import { enqueueSnackbar, useSnackbar } from 'notistack';
import DELETE_ICON from "../../images/settings/delete.png"
import { groupBy } from "../../functions/objects_arrays";
import { utcDateToYYYYMMDD } from "../../components/AQDataTable/columns_templates.js";
import AQToolTip from '../../components/ToolTip/ToolTip';

export const ArquellaResidentNew = ({ siteID, dataRefresh, options, onClose }) => {
    //console.log(userData);



    const [genderList, setGenderList] = useState([])
    const [currStep, setCurrStep] = useState(1)
    console.log('site id', siteID)
    const [userData, setUserData] = useState(
        {
            title: '',
            firstName: '',
            surname: '',
            gender: '',
            dob: Date.UTC(),
            site_id: siteID,
            res_id: 0,
        }
    )


    useEffect(() => {
        setGenderList(["Female", "Male", "Intermediate", 'Not specified']);
    }, [])



    return (
        <>
            {currStep === 1 &&
                <Step1 userData={userData} setUserData={setUserData} genderList={genderList} onClose={onClose} setCurrStep={setCurrStep} />
            }
            {currStep === 2 &&
                <div>
                    <RoomAssigment user={userData} siteID={siteID} options={options} onClose={onClose} />
                </div>
            }
        </>
    );
};

export default ArquellaResidentNew;
const Step1 = ({ userData, setUserData, genderList, onClose, setCurrStep }) => {

    const [error, setError] = useState(false);
    // Handle input changes
    const handleChange = (e) => {
        const { name, value } = e.target;
        console.log(name)
        setUserData((prevData) => ({
            ...prevData,
            [name]: value,
        }));
    };


    // Handle form submission
    const handleSubmit = (e) => {
        if (userData.title != null && userData.title !== '' &&
            userData.firstName != null && userData.firstName !== '' &&
            userData.surname != null && userData.surname !== '' &&
            userData.gender != null && userData.gender !== '' &&
            userData.dob != null && isNaN(userData.dob) === true
        ) {
            apiPost('api/messages/ArquellaResident/add',
                userData,
                d => {
                    console.log(d['message']['data'][0][0])
                    setUserData((data) => ({
                        ...data,
                        res_id: d['message']['data'][0][0].id,
                    }))
                    setError(false)
                    setCurrStep(2)
                },
                e => {
                    console.log(e)
                }
            )
        }
        else {
            setError(true)
        }
    };

    return (
        <div className='new_user_page_container'>
            <h1 style={{ marginBottom: '0px' }}>Add new resident</h1>
            <div className='aqForm'>
                <h4>Step 1/2: Resident details</h4>
                <div className='aq_fields' >
                    <label>
                        Title:
                        <NourishSelect
                            type="text"
                            name="title"
                            value={userData.title}
                            onChange={handleChange}
                            list={["Mr", 'Mrs', 'Ms', 'Dr', 'Prof']}
                        />
                    </label>
                    <br />

                    <label>
                        Name:
                        <NourishInput
                            type="text"
                            name="firstName"
                            value={userData.firstName}
                            onChange={handleChange}
                        />
                    </label>
                    <br />
                    <label>
                        Surname:
                        <NourishInput
                            type="text"
                            name="surname"
                            value={userData.surname}
                            onChange={handleChange}
                        />
                    </label>
                    <br />

                    <label>
                        Date of birth:
                        <br />
                        <input
                            className=" shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                            type="date"
                            name="dob"
                            value={userData.dob}
                            onChange={handleChange}
                            style={{ width: '150px' }}
                        />

                    </label>
                    <br />

                    <label>
                        Gender:
                        <NourishSelect
                            type="text"
                            name="gender"
                            value={userData.gender}
                            onChange={handleChange}
                            list={genderList}
                        />
                    </label>
                    <br />
                    {error &&

                        <div className='errorMessage'>The above issues must be fixed before assigning rooms</div>
                    }

                </div>


                <div className='submit_fields'>
                    <div className='validate_close_div'>
                        <button className='validate_button uniForm_btn' onClick={handleSubmit}>Next</button>
                        <button className='close_buton uniForm_btn' onClick={onClose}>Close</button>
                    </div>
                </div>

            </div>
        </div>
    )
}

const RoomAssigment = ({ user, siteID, options, onClose, }) => {
    // user.dob = utcDateToYYYYMMDD(user.dob);
    const [zoneList, setZoneList] = useState([]);
    const [load, setLoad] = useState(null);
    const [selectedUnits, setSelectedUnits] = useState([]);
    const [selectedZone, setSelectedZone] = useState(null);

    //Get zones
    useEffect(() => {
        if (options === undefined) return;
        if (options.length === 0) return;
        const tmp = Object.keys(groupBy(options, "zone"));
        setZoneList(tmp)
        console.log('zone list', tmp)
        setLoad(true)
    }, [options])


    useEffect(() => {
        console.log("aaaa")
        getRooms();
        setLoad(false)



    }, [load])

    function getRooms() {
        console.log('getting rooms')
        if (load === true) {
            apiPost("api/messages/ArquellaResident/getResidentRoomUnits",
                { ...user, ...{ "siteID": siteID } }
                ,
                (d) => {
                    console.log(d)
                    let rr = d.message.data[0][0].map(unitRecord => {
                        console.log(unitRecord.unitCode, options)
                        // console.log(options.filter(optionRecord => (optionRecord.unitCode === unitRecord.unitCode))[0].value)
                        return ({
                            "unitCode": unitRecord.unitCode,
                            "value": options.filter(optionRecord => (optionRecord.unitCode === unitRecord.unitCode))[0].value,
                            "zone": options.filter(optionRecord => (optionRecord.unitCode === unitRecord.unitCode))[0].zone,
                        })
                    })
                    console.log("Assigned rooms", rr)
                    if (rr.length > 0)
                        setSelectedZone(rr[0].zone)

                    setSelectedUnits(rr)
                    setLoad(false)
                },
                (e) => {
                    console.log("Room units error", e)
                })
        }
    }
    function handleZoneSelection(zone) {
        const selectedOption = options.find(opt => opt.zone === zone);
        if (selectedOption) {
            console.log("zoneee", selectedOption)
            if (selectedOption.zone !== selectedZone) {
                console.log("zone changed", selectedOption.zone)
                setSelectedZone(selectedOption.zone)
                clearAll()
                setLoad(true)
            }
        }
    }
    function clearAll() {
        if (selectedUnits !== null) {
            selectedUnits.map(item => {
                removeRoom(item.unitCode)
            })
            setLoad(true)
        }
    }

    function addRoom(id) {
        console.log('tag data', { ...user, ...{ "unitCode": id }, ...{ "siteID": siteID } })
        apiPost("api/messages/ArquellaResident/addunit",
            { ...user, ...{ "unitCode": id }, ...{ "siteID": siteID } },
            (d) => {
                console.log("added unit: ", id)
                setLoad(true)
            }, (e) => {
                console.log(e)
                setLoad(true)
            }
        )
    }
    function removeRoom(id) {
        console.log('tag data', { ...user, ...{ "unitCode": id }, ...{ "siteID": siteID } })

        apiPost("api/messages/ArquellaResident/removeunit",
            { ...user, ...{ "siteID": siteID, "unitCode": id } },
            (d) => {
                console.log("Removed unit: ", id)
                setLoad(true)
            }
            , (e) => {
                console.log(e)
                setLoad(true)
            })
    }
    function handleSubmit() {
        enqueueSnackbar('Resident created successfully', { variant: 'success' })
        onClose();
    }
    if (options === undefined) return ("No room units config")

    return (
        <div className='new_user_page_container'>
            <h1 style={{ marginBottom: '0px' }}>Add new resident</h1>
            <div className='aqForm' >
                <h4>Step 1/2: Resident details</h4>
                <div className='aq_fields'>
                    <h5>Assign Rooms</h5>
                    <div>
                        <div >
                            <label>
                                Select zone:
                                <ZoneSelection selectedZone={selectedZone} zoneList={zoneList} handleZoneSelection={handleZoneSelection} />
                            </label>
                            <br />

                            Assigned bedrooms
                            <div className="flex-row">
                                <div style={{ display: "flex", flexDirection: 'column' }}>
                                    <div style={{ marginBottom: "5px" }}>
                                        {selectedUnits != null ? selectedUnits.map((d) => (
                                            d.unitCode != null ?
                                                <div className="tag-item">
                                                    <span className="text" >{d.value}</span>
                                                    {/* <img src={DELETE_ICON} onClick={()=>removeSite(d.siteID)} className="close"/>  */}
                                                    <span onClick={() => removeRoom(d.unitCode)} className="close">&#10006;</span>
                                                </div>
                                                : null
                                        )) : null}
                                    </div>
                                    {
                                        selectedUnits != null ?
                                            selectedUnits.length > 1 ?

                                                <div style={{ alignItems: 'center', display: 'flex', justifyContent: 'center' }}>
                                                    <button className='clear_button' onClick={clearAll}>
                                                        {/* Clear <span>&#10006;</span> */}
                                                        Clear <img src={DELETE_ICON} />
                                                    </button>

                                                </div>
                                                : null
                                            : null
                                    }
                                    <SelectableUnits options={options} selectedZone={selectedZone} selectedUnits={selectedUnits} addRoom={addRoom} />
                                </div>

                            </div>



                        </div>
                    </div>

                </div>


                <div className='submit_fields'>
                    <div className='validate_close_div'>
                        <button className='validate_button uniForm_btn' onClick={handleSubmit}>Save</button>
                        <button className='close_buton uniForm_btn' onClick={onClose}>Close</button>
                    </div>
                </div>

            </div>
        </div>


    );
}
const ZoneSelection = ({ selectedZone, zoneList, handleZoneSelection }) => {
    return (
        <div>
            <select
                className='selected_site aqselectform'
                value={selectedZone}
                onChange={
                    (e) => {
                        handleZoneSelection(e.target.value);
                    }
                }
            >
                <option selected={selectedZone === null}></option>
                {zoneList.map(opt =>
                    <option value={opt} selected={selectedZone === opt}>{opt}</option>

                )}
            </select>
        </div>
    )
}
const SelectableUnits = ({ options, selectedZone, addRoom, selectedUnits }) => {

    let selectedUnitcode = null
    function setSelectedUnitCode(e) {
        // () => { selectedUnitcode = o.unitCode }
        console.log("jioejijojoi", e)
    }

    if (selectedZone == null) return
    if (options == null) return
    console.log("all options", options, selectedZone)
    let res = [];
    options.find(item => {
        if (item.zone === selectedZone) {
            var ff = selectedUnits.find(un => un.unitCode === item.unitCode)
            if (!ff)
                res.push(item)
        }
    }
    )
    if (res.length > 0) {

    }
    console.log("available units", res)
    return (
        <div>
            <input
                className="tag_input"
                type="text"
                id="autocomplete-input"
                list="suggestions"
                placeholder="Select a bedroom to add..."
                onChange={(e, key) => {
                    // if (selectedUnitcode != null) {
                    console.log(e.target.value)
                    const selectedOption = res.find(opt => opt.value === e.target.value);

                    if (selectedOption) {
                        //if (onAdd) onAdd(selectedOption);
                        addRoom(selectedOption.unitCode);
                        e.target.value = '';
                        // selectedUnitcode = null
                    }
                    // }
                }}
            />
            <datalist id="suggestions">
                {res.map(o => <option key={o.unitCode} value={o.value}>{o.value}</option>)}
            </datalist>
        </div>
    )

}

const NourishInput = ({ id, name, type, value, placeholder, onChange, }) => {
    return (
        <input
            className=" shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
            id={id}
            type={type}
            name={name}
            value={value}
            placeholder={placeholder}
            onChange={onChange}
        />
    );
}
const NourishSelect = ({ id, name, type, value, placeholder, onChange, list }) => {
    return (
        <>
            <br />
            <select
                className="shadow border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                id={id}
                type={type}
                name={name}
                value={value}
                placeholder={placeholder}
                onChange={onChange}
                style={{ width: '150px' }}
            >
                <option hidden={true} selected>Select</option>
                {list !== null && list !== undefined && list.map(item => {
                    return (
                        <option>{item}</option>
                    )
                })}

            </select>
        </>
    );
}


