import IMG_COMMING_SOON from '../../images/coming_soon.png';
import './Subscription.css';

export const NotSubscribedSense=({})=>{
    return (
        <SubscribedMainTemplate>
        
        </SubscribedMainTemplate>
    );
}

export const CommingSoonSense=({})=>{
    return (
            <CommingSoonTemplate>
                <SenseSoon />
            </CommingSoonTemplate>    
    );
}

export const CommingSoonPeople=({})=>{
    return (
        <CommingSoonTemplate>
            <PeopleSoon />
        </CommingSoonTemplate>    
    );
}

export const CommingSooMonitoring=({})=>{
    return (
        <CommingSoonTemplate>
            <Monitoring />
        </CommingSoonTemplate>    
    );
}


const SubscribedMainTemplate=(props)=>{
    return (
        <div className='subscription_container'>
            <div>{props.children}</div>
        </div>
    );
}


const NotSubscribedTemplate=(props)=>{
    return(
        <>
        <div>You Are Not Subscribed to This Feature</div>
        {props.children}
        <div>Ready to Transform Your Care Delivery? Contact our Sales team through (Email) or (Phone number)</div>
        </>
    );
}

const CommingSoonTemplate=(props)=>{
    return(
        <div className='subscription_container'>
        <img src={IMG_COMMING_SOON} />
        {props.children}
        <p>We will notify you once this page is available. Stay Tuned!</p>      
        </div>
    );
}

const MoveData=()=>{
    return(
        <NotSubscribedTemplate>
        <p>Purchase Arquella Move technologies to access its full capabilities within the Arquella Connect platform.</p>
        <p>Arquella Move redefines the efficiency and safety of care delivery through precise, real-time location tracking of people and assets, providing complete visibility, enhanced efficiency, and improved care delivery. Learn More About Arquella Move </p>
        </NotSubscribedTemplate>
    );
}

const SenseSoon=()=>{
    return(
        <>
        <p>This page is coming soon to Arquella Connect for insights and configurations for fall prevention and fall detection.</p>
        </>
    );
}

const PeopleSoon=()=>{
    return(
        <>
        <p>This page is coming soon to Arquella Connect for Residents information.</p>
        </>
    );
}


const Monitoring=()=>{
    return(
        <div>This page is coming soon to Arquella Connect for battery and heartbeat monitoring for room units and panels.</div>
    );
}
