
import { useEffect, useState } from "react";
import { Text, TextInput,  Tab,  TabList,  TabGroup,  TabPanel,  TabPanels,  Card } from "@tremor/react";
import Nourish from "./integrations/Nourish";
import { getOnceValue } from "../data/firebase";
import { groupBy } from "../functions/objects_arrays";
import RoomUnitConfig from "./RoomUnitConfig/RoomUnitConfig";
import { PanelSettings } from "./PanelSettings";
import NourishSite from "./integrations/nourish/NourishSite";
import { MoveSiteSettings } from "./MoveSiteSettings";
import { SiteEvents } from "./SiteEvents";

export default function Settings({siteCode, site}) {
  const [siteConfig, setSiteConfig] = useState(null);
  
  useEffect(()=>{
    if (siteCode==="") return;
    getOnceValue(
      siteCode + "config",
      (d)=>setSiteConfig(d),
      (e)=>{console.log(e);}
    )
  },[siteCode]);

  return (
    <div className="ml-12">
      <TabGroup className="">
        <TabList>
          <Tab>Panels</Tab>
          <Tab>Integrations</Tab>
          <Tab>Move</Tab>
          <Tab>Room Units</Tab>
          <Tab>Sense</Tab>
          <Tab>Events</Tab>
        </TabList>
        <TabPanels>
          <TabPanel><PanelSettings siteCode={site.siteCode} /></TabPanel>
          <TabPanel>
            <NourishSite siteID={site.id} siteCode={site.siteCode} site={site} />
          </TabPanel>
          <TabPanel>
            <MoveSiteSettings siteID={site.id} siteCode={site.siteCode} />
          </TabPanel>
          <TabPanel>
            <RoomUnitConfig siteID={site.id} siteCode={site.siteCode} />
          </TabPanel>
          <TabPanel>
            <MoveSiteSettings siteID={site.id} siteCode={site.siteCode} />
          </TabPanel>
          <TabPanel>
            <SiteEvents siteCode={site.siteCode} />
          </TabPanel>
        </TabPanels>
      </TabGroup>
    </div>
  );
}

const RoomUnitConfisg=({siteID})=>{
  const [units, setConfig] = useState(null);

  useEffect(()=>{
    if (siteID==="") return;
    getOnceValue(siteID + "config", (d)=>setConfig(d),(e)=>{console.log(e);});
  },[siteID]);

  return(
    <div>
      {(units===null) && <div>loading</div>}
      {
        (units!==null) && 
        Object.values(units).map((u,i)=><RoomUnit data={u} key={i} />)
      }
    </div>
  );
}

const RoomUnit=({data})=>{

    return(
      <Card>
        <TextInput placeholder="ID" value={data.unitId} />
        <TextInput placeholder="Name" value={data.unitName} />
        <TextInput placeholder="Zone" value={data.unitZone} />
        <TextInput placeholder="Linked Panel Code" value={data.linkID} />
        <TextInput placeholder="Move - Room Config" value={data.iiwari_room_id} />
        <button>Update</button>
        {//Object.keys(data).map(k=><div>{k}</div>)
        }
      </Card>
    
    );
}


const SiteSettings=({config})=>{

  const [zones, setZones] = useState([]);

  useState(()=>{
    if ((config===undefined)||(config===null)){
      setZones([]);
      return;
    }
    const z = groupBy(config, "unitZone");
    console.log("zed", z);
    setZones(Object.keys(z));

  }, [config]);

  const RoomUnit=({data})=>{
    return(
      <Card>
        <TextInput placeholder="ID" value={data.unitId} />
        <TextInput placeholder="Name" value={data.unitName} />
        <TextInput placeholder="Zone" value={data.unitZone} />
        <TextInput placeholder="Linked Panel Code" value={data.linkID} />
        <TextInput placeholder="Move - Room Config" value={data.iiwari_room_id} />
        <button>Update</button>
        {//Object.keys(data).map(k=><div>{k}</div>)
        }
      </Card>
    )
  }
  
  return(
    <div>
      <br />
      <Text>Zones</Text>
      {zones.map(z=><div>{z}</div>)}
    </div>
  )
}

const PhoneSettings=()=>{
  return(<div>SiteSettings</div>)
}

const RoomUnitSettings=()=>{
  return <RoomUnitConfig />;
  return(<div>SiteSettings</div>)
}


const UserAccountSettings=()=>{
  return(<div>SiteSettings</div>)
}

const SenseSettings=()=>{
  return(<div>SiteSettings</div>)
}

const MoveSettings=()=>{
  return(<div>SiteSettings</div>)
}

