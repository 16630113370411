import React, { useEffect, useState } from 'react';
import { FormInput } from '../Input';
import './SitePage.css';
import { apiPost } from '../../data/apidata';
import { enqueueSnackbar, useSnackbar } from 'notistack';
import { Checkbox, Grid } from '@mui/material';
import DELETE_ICON from '../../images/settings/delete.png';
import { SystemUpdateAltSharp as Download, Backup, Add } from '@mui/icons-material';


const EditSiteConfig = ({ siteID, onClose }) => {
    const [selection, setSelection] = useState([]);
    const [currStep, setCurrStep] = useState(1)
    const [page_error, setPage_error] = useState('');
    const [config, setConfig] = useState()
    useEffect(() => {
        apiPost(
            'api/messages/site/management/GetSiteConfig',
            { site_id: siteID },
            d => {
                let data = d['message']['data'][0][0]
                if (data.devices === null) data['devices'] = [{
                    device_name: '',
                    unit_code: '',
                    unit_zone: '',
                    device_type: '',
                    isSelected: false,
                    error: ''
                }]
                else
                    data['devices'] = data.devices.map(item => {
                        return ({
                            device_name: item.device_name,
                            unit_code: item.device_code,
                            unit_zone: item.device_zone,
                            device_type: item.device_type,
                            isSelected: false,
                            error: ''

                        })
                    })


                //Get everything room to Room Unit
                let dt =
                    data.device_types.filter(item =>
                        item.name.toLowerCase() !== 'communal unit' &&
                        item.name.toLowerCase() !== 'ensuite unit' &&
                        item.name.toLowerCase() !== 'bedroom unit'
                    )
                data['device_types'] = dt;
                data['old_zones'] = data.zones

                console.log('got', d['message']['data'][0][0], data)
                setConfig(data);
            }
        )
    }, [siteID])

    // const [currStep, setCurrStep] = useState(2);
    return (
        <div className='new_user_page_container'><h1 style={{ marginBottom: '0px' }}>Edit site config</h1>
            <span className='formFieldError'>{page_error}</span>
            <br />{config != null ?
                (
                    currStep === 1 ?
                        <Step1 currStep={currStep} setCurrStep={setCurrStep}
                            config={config} setConfig={setConfig} onClose={onClose}
                            page_error={page_error} setPage_error={setPage_error} />

                        :
                        <Step2 currStep={currStep} setCurrStep={setCurrStep}
                            config={config} onClose={onClose}
                            page_error={page_error} setPage_error={setPage_error} />

                )
                :
                <div className='aqForm'>
                    <div className='aq_fields' >
                        <div>
                            Loading
                        </div>
                        <div className=' bottom_field'  >

                            <div className='validate_close_div' >
                                <button className='close_buton btn_size' onClick={onClose}>Close</button>
                            </div>
                        </div>
                    </div>
                </div>
            }
        </div>
    );
};
export default EditSiteConfig;
const Step1 = ({ currStep, setCurrStep, page_error, setPage_error, config, setConfig, onClose }) => {
    const [zones, setZones] = useState([])
    const [panels, setPanels] = useState([])
    const [zone_name, setZone_name] = useState([])


    useEffect(() => {
        try {
            if (config.panels != null)
                setPanels(config.panels.map(item => {
                    return (
                        {
                            ...item, isNew: false
                        }
                    )
                }))
            if (config.zones != null)
                setZones(config.zones)
        }
        catch (e) {
            console.log(e)
        }

    }, [config])


    function addZone() {
        if (zone_name !== '') {
            let check = zones.some(item => item.name === zone_name);
            if (!check) {
                setZones((data) => [...data,
                { name: zone_name }
                ]);
                setZone_name('')
            }
        }
    }
    function addPanel() {
        if (panels.length < 1) return
        let panel_name = ''
        if (panels.length === 0)
            panel_name = 'Main Panel'
        else panel_name = `Panel ${panels.length + 1}`

        setPanels((data) => [...data,
        {
            panel_name: panel_name,
            isNew: true,
            panel_ref: `${config.site_code}${panel_name.replace(/ /g, '')}Config`,
            email: `${config.site_code}${panel_name.replace(/ /g, '')}@arquella.co.uk`,
        }
        ])

    }
    function saveData() {
        if (zones.length > 0 && panels.length > 0) {
            setConfig((data) => ({
                ...data,
                panels: panels,
                zones: zones
            }
            ))
            setCurrStep(2)
        }
    }
    return (
        <div className='aqForm'>
            <div className='aq_fields'>
                <h4 style={{ marginTop: 0 }}>Step 1/2: Zones and Panels</h4>
                <label>
                    <span style={{ marginRight: '10px' }}>Add Zone: </span>
                    <input
                        className="config_input shadow appearance-none border rounded  py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                        type="text" style={{ marginLeft: '20px' }} name="zone" placeholder='zone name' value={zone_name} onChange={(e) => setZone_name(e.target.value)} />

                    <button className='add_button' onClick={addZone}>add <Add /></button>
                </label>
                <Tags selection={zones} setSelection={setZones} />
                <br />

                <label>
                    <span style={{ marginRight: '10px' }}>Panels: </span>
                    <button className='add_button' onClick={addPanel}>add Panel <Add /></button>

                    <br />
                    <span className='formFieldError'>Warning: added panels cannot be removed from this page</span>
                    <br />
                </label>
                <PanelTags selection={panels} setSelection={setPanels} />
                <br />

            </div>
            <div className='submit_fields'>
                <div className='validate_close_div'>
                    <button className='validate_button action_button' onClick={saveData}>Next</button>
                    <button className='close_buton action_button' onClick={onClose}>Close</button>
                </div>
            </div>

        </div>
    )
}
const Step2 = ({ currStep, setCurrStep, config, onClose, page_error, setPage_error }) => {

    const [unitList, setUnitList] = useState(config['devices']);
    const [multiZone, setMultiZone] = useState('');
    console.log("step2", config)
    useEffect(() => {
        const old_zones = config.old_zones
        const new_zones = config.zones

        const diff = old_zones.filter(item => {
            if (!new_zones.some(item2 => item2.name === item.name))
                return item
        })
        console.log('Diff', diff)
        console.log(config)
    })

    function addDevice() {
        const tmp = [...unitList, {
            device_name: '', device_type: '', unit_code: '', unit_zone: '',
        }]
        setUnitList(tmp)
    }
    function removeDevice() {

        const tmp = unitList.filter(item => item.isSelected === false)
        console.log('tmp', tmp)
        setUnitList(tmp)
    }
    function handleApply() {
        const tmp = unitList.map(item => {
            if (item['isSelected'] === true) {
                item['unit_zone'] = multiZone;
                item['isSelected'] = false;
            }
            return item;
        })
        setUnitList(tmp)
    }
    const findNonUniqueNameAndId = () => {
        const idSet = new Set();
        const nameSet = new Set();

        const nonUniqueIds = [];
        let arr = config['devices']
        console.log(arr)
        for (let item of arr) {
            const name = item.device_name;
            console.log(name)
            const id = item.unit_code;
            if (nameSet.has(name) || idSet.has(id)) {
                console.log(name, id)
                nonUniqueIds.push(item);
                item['error'] = true
            } else {
                idSet.add(id);
                nameSet.add(name);
            }
        }
        console.log(idSet, nameSet);
        config['devices'] = arr
        if (nonUniqueIds.length > 0)
            return false;
        else return true
    };
    function handleSave(e) {
        // console.log(panels.some(item => item.error == null || item.error === true), panels)
        config['devices'] = unitList
        console.log('config', config)
        if (unitList.some(item => item.error == null || item.error === true) === true)
            setPage_error('Make sure all devices are configured')
        else {
            const res = !findNonUniqueNameAndId()
            console.log(res)
            if (res) {
                setPage_error('Some devices have non unique Device name or unitID')

            }
            else {
                setPage_error('')
                let dd = config.devices;
                dd.map(item => {
                    if (item.device_type.toLowerCase() === 'communal unit' ||
                        item.device_type.toLowerCase() === 'ensuite unit' ||
                        item.device_type.toLowerCase() === 'bedroom unit')
                        item['device_type'] = 'Room Unit'
                    return item;
                })
                config['devices'] = dd;
                console.log(dd, config)
                apiPost('api/messages/site/management/UpdateSiteConfig',
                    config,
                    (d) => {
                        console.log(d)
                        enqueueSnackbar("Site config updated", { variant: "success" });
                        onClose();
                    },
                    (e) => {
                        console.log(e)
                        enqueueSnackbar("Site config update failed", { variant: "error" });

                    }
                )

            }
        }
    }
    return (
        <div className='aqForm'>
            <div className='aq_fields' >
                {/* <h4 style={{ marginTop: 0 }}>Setp 4/4 Device config</h4> */}
                <div style={{ display: 'flex', alignItems: 'center', marginLeft: '40px', marginBottom: '0px' }} >
                    <p className='unit_header_field' >Device name</p>
                    <p className='unit_header_field' style={{ width: '50px' }}>UnitID</p>
                    <p className='unit_header_field'>Zone</p>
                    <p className='unit_header_field'>Device type</p>
                </div>
                {
                    unitList.map((item, i) => {
                        return (
                            <UnitEdit unitID={i} unitCode={item.unit_code}
                                unitName={item.device_name} unitZone={item.unit_zone}
                                unitDeviceType={item.device_type}
                                unitList={unitList} setUnitList={setUnitList}
                                zones={config['zones']}
                                deviceTypes={config['device_types']}
                                isSelected={item.isSelected}
                                error={item['error']}
                                setPage_error={setPage_error} />
                        )
                    })
                }
                {unitList.length > 0 &&
                    <button className="validate_button" onClick={addDevice}>Add Device</button>
                }

            </div>
            <div className=' bottom_field' style={{ width: '100%', }} >

                <div>{unitList.filter(item => item['isSelected'] === true).length} items selected</div>

                <div style={{ display: 'flex', justifyContent: 'space-between', alignContent: 'center', width: '100%', }}>
                    {unitList.filter(item => item['isSelected'] === true).length > 0 ?
                        <div style={{ alignSelf: 'flex-start' }}>
                            <select name='device_type' onChange={(e) => setMultiZone(e.target.value)} className='unit_edit_field' >
                                <option hidden selected>Select zone</option >
                                {config['zones'] != null && config['zones'].map(item => {
                                    return (<option >{item.name}</option>)
                                })}
                            </select>
                            <button className="validate_button btn_size" onClick={handleApply}> Apply</button>or
                            <button className="delete_button btn_size" onClick={removeDevice}> Delete</button>
                        </div>
                        : <div></div>
                    }
                    <div className='validate_close_div' style={{ alignSelf: 'flex-end' }}>
                        <button className='validate_button btn_size' onClick={(e) => { setCurrStep(1) }}>Previous</button>
                        <button className='validate_button btn_size' style={{ marginRight: '2px' }} onClick={handleSave}>Save</button>

                        <button className='close_buton btn_size' onClick={onClose}>Close</button>
                    </div>
                </div>
            </div>
        </div>
    )
}
const UnitEdit = ({ unitID, unitCode, unitName, unitZone, unitDeviceType,
    unitList, setUnitList, zones, deviceTypes, isSelected, error, setPage_error }) => {
    const [checked, setChecked] = useState(isSelected);
    const [hasError, setHasError] = useState(error);
    const [divBg, setDivBg] = useState('div_default ');
    useEffect(() => {
        if (error !== hasError)
            setHasError(error)
    }, [error])

    useEffect(() => {
        setChecked(isSelected)
        let tmp = 'div_default '
        if (hasError) tmp += 'div_error '
        if (isSelected) tmp += 'div_notSelected '
        setDivBg(tmp)
    }, [isSelected, hasError])
    useEffect(() => {
        let res = false;
        if (unitName != null && unitZone != null && unitDeviceType != null && unitCode != null) {
            if (unitName.trim() !== '' && unitZone.trim() !== '' &&
                unitDeviceType.trim() !== '' && unitCode.trim() !== ''
                && unitCode.length === 3)
                res = false;
            else
                res = true
        }
        else
            res = true

        if (zones.filter(item => item.name.toLowerCase() === unitZone.toLowerCase()).length < 1)
            res = true

        const tmp = unitList.map((item, i) => {
            if (i === unitID) {
                item['error'] = res;

            }
            return item;
        })
        setUnitList(tmp)
        // setHasError(res)
    }, [unitName, unitZone, unitDeviceType, unitCode])

    function handleUnitChange(e) {
        const tmp = unitList.map((item, i) => {
            if (i === unitID) {
                console.log(e.target.name)
                item[e.target.name] = e.target.value;
                item['isSelected'] = isSelected;
            }
            return item;
        })
        setUnitList(tmp);
    }
    function handleSelection(e) {
        // setChecked(e.target.checked);
        const tmp = unitList.map((item, i) => {
            if (i === unitID) {
                console.log(e.target.name);
                item['isSelected'] = e.target.checked;
            }
            return item;
        })
        setUnitList(tmp);
    }
    return (
        <div className={divBg} style={{ display: 'flex', alignItems: 'center' }}>
            <input type="checkbox" className="checkBox" checked={checked} onChange={handleSelection} />
            <input name='device_name' className='unit_edit_field' type="text" value={unitName} onChange={handleUnitChange} />

            <input name='unit_code' className='unit_edit_field' maxLength={3} style={{ width: '45px' }} type="text" value={unitCode} onChange={handleUnitChange} />

            <select name='unit_zone' placeholder='Select zone' className='unit_edit_field' onChange={handleUnitChange} >
                <option hidden selected>Select zone</option>
                {zones != null && zones.map(item => {
                    return (<option selected={item.name.toLowerCase().trim() === unitZone.toLowerCase().trim()}>{item.name}</option>)
                })}

            </select>

            <select name='device_type' className='unit_edit_field' onChange={handleUnitChange} >
                <option hidden selected>Select device</option >
                {deviceTypes != null && deviceTypes.map(item => {
                    return (<option selected={item.name === unitDeviceType}>{item.name}</option>)
                })}

            </select>

            {/* <button className="delete_button"><img src={DELETE_ICON} /></button> */}

        </div>
    )
}
const Tags = ({ selection, setSelection }) => {
    function removeValue(e) {
        const tbl = selection.filter((item) => item !== e)
        setSelection(tbl)
    }
    function clearAll() {
        setSelection([])

    }
    return (
        <div style={{ display: "flex", flexDirection: 'column' }}>
            <div style={{ marginBottom: "0px", width: 'fit-content' }}>
                {selection != null && selection.map((item) => (
                    <div className="tag-item">
                        <span className="text" >{item.name}</span>
                        <span onClick={() => removeValue(item)} className="close">&#10006;</span>
                    </div>
                ))
                }
                <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>

                    {selection.length > 1 &&
                        <button className='clear_button' onClick={clearAll}>Clear all</button>
                    }
                </div>
            </div>
        </div>
    )
}
const PanelTags = ({ selection, setSelection }) => {
    function removeValue(e) {
        const tbl = selection.filter((item) => item !== e)
        setSelection(tbl)
    }
    function clearAll() {
        setSelection(selection.filter(item => !item.isNew))
    }
    return (
        <div style={{ display: "flex", flexDirection: 'column' }}>
            <div style={{ marginBottom: "0px", width: 'fit-content' }}>
                {selection != null && selection.map((item) => (
                    <div className={item.isNew ? "tag-item" : "tag-item-gray"}>
                        <span className="text">{item.panel_name}</span>
                        {item.isNew &&
                            <span onClick={() => removeValue(item)} className="close">&#10006;</span>
                        }
                    </div>
                ))
                }
                <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'center', width: '100%' }}>

                    {selection != null && selection.filter(item => item.isNew).length > 1 &&
                        <button className='clear_button' onClick={clearAll}>Clear all</button>
                    }
                </div>
            </div>
        </div>
    )
}
export { Step1, Step2 };