import React, { useEffect, useState } from "react";
import BarChart from "./BarChart";
import Insight_BarChartCustom from "../../Pages/insights/Insight_BarCharCustom";
import { columns_template_livecalls, columns_template_insightcalls } from "../AQDataTable/columns_templates";

const BarChartKPI = ({ data }) => {

  const [dataBreakdown, setDataBreakdown] = useState([])
  const [masterData, setMasterData] = useState(null);

  useEffect(() => {
    setDataBreakdown(data)
  }, [data])

  function showBreakdown(title, bData) {
    setDataBreakdown(bData);
  }

  function formatSecsAsMins(v) {
    const date = new Date(0); // Start at the Unix epoch (1970-01-01T00:00:00Z)
    date.setSeconds(v);
    const minutes = String(Math.floor(v / 60)).padStart(2, '0');
    const seconds = String(v % 60).padStart(2, '0');
    let secString = seconds.split(".")[0];
    return `${minutes}:${secString}`;
  }

  const handleClick=(d)=>{
    if(d !== null && d.length !== 0) {
      setMasterData(dataBreakdown[d[0].index].data)
    }
  }

  const options = {
    responsive: true,
    maintainAspectRatio: false,
    plugins: {
      legend: { display: false },
      title: { display: false },
      tooltip: {
        backgroundColor: '#4cc1bd',
        callbacks: {
          label: function(tooltipItem) {
            //return tooltipItem.value;
            return formatSecsAsMins(tooltipItem.raw.toString()); // Adjust formatting as needed
          }
        }
      },
      autocolors: false,
      annotation: {
        annotations: [
          {
            type: 'line',
            mode: 'horizontal',
            scaleID: 'y-axis-0',
            value: 0,
            borderColor: 'rgb(75, 192, 192)',
            borderWidth: 2,
            label: {
              enabled: false,
              content: 'Test label'
            }
          }
        ]
      }
    },
    scales: {
      x: {
        barPercentage: 0.5,
        scaleLabel: { 
          display: true, 
          labelString: "Date" 
        },
        grid: { 
          display: true, 
          drawOnChartArea: false 
        }
      },
      y: {
        ticks: {
          callback: function(value, index, values) {
            return formatSecsAsMins(value);
          },
          stepSize: 30, // add a tick every 30 units
        },
        scaleLabel: { 
          display: true, 
          labelString: "Average Time (mins)" 
        },
        grid: { 
          display: true, 
          drawOnChartArea: true 
        }
      }
    },
    onClick: (event, d) => {handleClick(d)}
  };

  return (
    <Insight_BarChartCustom
      barData={dataBreakdown}
      barOptions={options}
      selectedData={masterData}
      setSelectedData={setMasterData}
      //columns={columns_template_livecalls}
      columns={columns_template_insightcalls}
      barType = {'BarChart'}
    />
    // <BarChart datasets={dataBreakdown} options={options}/>
  );
}


export default BarChartKPI;
