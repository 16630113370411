import { Checkbox, Grid } from "@mui/material";
import { useEffect, useState } from "react";
import { apiPost } from "../../data/apidata";
import { v4 as uuid } from 'uuid';
import axios from "axios";
import { LoadingBanner } from "../charts/Notifications";
import { timeout } from "d3";
import { Switch } from '@mui/material';
import './SenseConfig.css'

import { styled } from '@mui/material/styles';
import { enqueueSnackbar } from "notistack";
import { getOnceValue, getOnValue } from "../../data/firebase";

export const GlobalConfig = ({ currStep, config, setConfig, page_error, setPage_error, setCurrStep, onClose }) => {
    const [fall_detection, setFall_detection] = useState(false)
    const [fall_prevention, setFall_prevention] = useState(false)
    const [globalConfig, setGlobalConfig] = useState({});
    const [chekGlobalconfig, setCheckGlobalconfig] = useState(false)
    const [hasPanelConfig, setHasPanelConfig] = useState(false)
    const [hasLoaded, setHasloaded] = useState(false)
    useEffect(() => {
        if (!hasLoaded) {
            if (config !== undefined)
                console.log(config)
            saveDevices();
            setTimeout(() => {
                checkPanelConfig();
                setHasloaded(true)
            }, 5000)

        }
    }, [config])

    function checkPanelConfig() {
        return new Promise(async (resolve, reject) => {

            apiPost('api/messages/Sense/GetGlobalSenseConfig', {
                site_id: config.site_id,
                dbPath: config.dbPath,
                dbRef: `${config.site_code}_site/config/Vayyar`
                // dbRef: 'sensetest_site'
            }, d => {
                resolve(d)
                console.log(d['message']['data'])
                if (d['message']['data'].V_FD_Armed !== undefined)
                    setFall_detection(d['message']['data'].V_FD_Armed)
                if (d['message']['data'].V_FP_Armed !== undefined)
                    setFall_prevention(d['message']['data'].V_FP_Armed)
                setCheckGlobalconfig(true)
                setHasPanelConfig(true)
            }, e => {
                console.log(e)
                setCheckGlobalconfig(true)
                setPage_error('Panel failed creating sense config');
            })

        })

    }
    function saveDevices() {
        const tmp = config.sensors.map(item => {
            return {
                "active": 0,
                "advancedBed": 0,
                "advancedChair": 0,
                "advancedFall": fall_detection ? 1 : 0,
                "advancedPrevention": fall_prevention ? 1 : 0,
                "bedEnd": "00:00",
                "bedStart": "00:00",
                "chairEnd": "00:00",
                "chairStart": "00:00",
                "fallRisk": 0,
                "fdEnd": "00:00",
                "fdStart": "00:00",
                "fpEnd": "00:00",
                "fpStart": "00:00",
                "pairedUnit": item.device.device_code,
                "vayyarId": `/devices/${item.sensor_id}/events`,
                "vayyarName": item.device.device_name,
                "vayyarZone": item.device.device_zone,
                "zone1": "Bed",
                "zone1A": 0,
                "zone2": "chair",
                "zone2A": 0,
                "zone3": "bathroom",
                "zone3A": 0,
                "zone4": "bedConfirm",
                "zone4A": 0,
                vendor: config.vendor
            }
        })
        console.log()
        const tmp_fb = config.sensors.map(item => {
            return {
                "active": false,
                "advancedBed": false,
                "advancedChair": false,
                "advancedFall": fall_detection,
                "advancedPrevention": fall_prevention,
                "bedEnd": "00:00",
                "bedStart": "00:00",
                "chairEnd": "00:00",
                "chairStart": "00:00",
                "fallRisk": false,
                "fdEnd": "00:00",
                "fdStart": "00:00",
                "fpEnd": "00:00",
                "fpStart": "00:00",
                "pairedUnit": item.device.device_code,
                "vayyarId": `/devices/${item.sensor_id}/events`,
                "vayyarName": item.device.device_name,
                "vayyarZone": item.device.device_zone,
                "zone1": "Bed",
                "zone1A": false,
                "zone2": "chair",
                "zone2A": false,
                "zone3": "bathroom",
                "zone3A": false,
                "zone4": "bedConfirm",
                "zone4A": false,
            }
        })
        const finalConfig = {
            site_id: config.site_id,
            dbPath: config.dbPath,
            site_code: config.site_code,
            sensors: tmp,
            sensors_fb: tmp_fb,
        }
        console.log('Final config', finalConfig)
        apiPost('api/messages/Sense/SetSiteSenseConfig',
            finalConfig,
            d => {
                console.log(d)
                // enqueueSnackbar("Sense config created", { variant: "success" });
                // onClose();
            },
            e => {
                console.log(e)
                // enqueueSnackbar("Sense config failed", { variant: "error" });
            }
        )

    }
    function saveData(e) {
        apiPost('api/messages/Sense/SetGlobalSenseConfig', {
            site_id: config.site_id,
            dbPath: config.dbPath,
            dbRef: `${config.site_code}_site/config/Vayyar/`,
            GlobalConfig: {
                "V_Armed": fall_detection || fall_prevention,
                "V_FD_Armed": fall_detection,
                "V_FP_Armed": fall_prevention,
                "V_Reporting_Mode": !fall_prevention,
            },
            GlobalConfigSQL: {
                "V_Armed": (fall_detection || fall_prevention) ? 1 : 0,
                "V_FD_Armed": fall_detection ? 1 : 0,
                "V_FP_Armed": fall_prevention ? 1 : 0,
                "V_Reporting_Mode": (!fall_prevention) ? 1 : 0,
            }
        }, d => {

            enqueueSnackbar("Sense config created", { variant: "success" });
            onClose()
        }, e => {
            console.log(e)
            setPage_error('Global config failed')
            enqueueSnackbar("Sense config failed", { variant: "error" });

        })
    }
    return (
        <div className='aqForm'>
            {!chekGlobalconfig ?
                <div className='aq_fields'>

                    <div style={{ height: '300px', width: '100%', display: 'flex', alignItems: 'center' }}>
                        <div style={{ width: '100%' }}>
                            <LoadingBanner />
                            <p style={{ textAlign: 'center', marginTop: '20px', fontSize: '15px' }}>Configuring devices, please wait</p>
                        </div>

                    </div>
                </div>
                :
                <>
                    <div className='aq_fields'>
                        <h4 style={{ marginTop: 0 }}>Step 3/3: Fall settings</h4>
                        <label>
                            <div style={{ fontSize: '14px', fontWeight: 'bold' }}>Turn on/off Global Fall Detection</div>

                            <IOSSwitch checked={fall_detection}
                                onChange={(e) => setFall_detection(e.target.checked)} />
                            {fall_detection ? <span style={{ marginLeft: '10px' }}>active</span> : <span style={{ marginLeft: '10px' }}>inactive</span>}
                        </label>
                        <br />
                        <br />
                        <label>
                            <div style={{ fontSize: '14px', fontWeight: 'bold' }}>Turn on/off Global Fall Prevention</div>
                            <IOSSwitch checked={fall_prevention}
                                onChange={(e) => setFall_prevention(e.target.checked)} />
                            {fall_prevention ? <span style={{ marginLeft: '10px' }}>active</span> : <span style={{ marginLeft: '10px' }}>inactive</span>}
                        </label>

                    </div>


                    <div className='validate_close_div' style={{ alignSelf: 'flex-end' }}>
                        <button className='validate_button btn_size' onClick={(e) => {
                            setCurrStep(2);
                            setPage_error('')
                        }}>Previous</button>
                        <button disabled={!hasPanelConfig} className='disabled_save' style={{ marginRight: '2px' }} onClick={saveData}>Save</button>

                        <button className='close_buton btn_size' onClick={onClose}>Close</button>
                    </div>
                </>
            }
        </div>
    )
}
export const IOSSwitch = styled((props) => (
    <Switch style={{ marginRight: '10px', marginBottom: '0px' }} focusVisibleClassName=".Mui-focusVisible" disableRipple {...props} />
))(({ theme }) => ({
    width: 36,
    height: 20,
    padding: 0,
    '& .MuiSwitch-switchBase': {
        padding: 0,
        margin: 2,
        transitionDuration: '300ms',
        '&.Mui-checked': {
            transform: 'translateX(16px)',
            color: '#fff',
            '& + .MuiSwitch-track': {
                backgroundColor: '#4cc1bd',
                opacity: 1,
                border: 0,
                ...theme.applyStyles('dark', {
                    backgroundColor: '#2ECA45',
                }),
            },
            '&.Mui-disabled + .MuiSwitch-track': {
                opacity: 0.5,
            },
        },
        '&.Mui-focusVisible .MuiSwitch-thumb': {
            color: '#33cf4d',
            border: '6px solid #fff',
        },
        '&.Mui-disabled .MuiSwitch-thumb': {
            color: theme.palette.grey[100],
            ...theme.applyStyles('dark', {
                color: theme.palette.grey[600],
            }),
        },
        '&.Mui-disabled + .MuiSwitch-track': {
            opacity: 0.7,
            ...theme.applyStyles('dark', {
                opacity: 0.3,
            }),
        },
    },
    '& .MuiSwitch-thumb': {
        boxSizing: 'border-box',
        width: 16,
        height: 16,
    },
    '& .MuiSwitch-track': {
        borderRadius: 26 / 2,
        backgroundColor: '#E9E9EA',
        opacity: 1,
        transition: theme.transitions.create(['background-color'], {
            duration: 500,
        }),
        ...theme.applyStyles('dark', {
            backgroundColor: '#39393D',
        }),
    },
}));
