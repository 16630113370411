import { useState, useEffect } from "react";
import { Accordion, AccordionHeader, List, ListItem, Card, Title } from "@tremor/react";
import { apiPost } from "../../../data/apidata";
import "./HeartbeatMonitor.css";
import AQToolTip from "../../../components/ToolTip/ToolTip";
import { displayDateFromUTC, displayDateTimeFromUTC } from "../../../functions/dataHandler";
import { useUserSettings } from "../../../components/UserSettingsProvider/UserSettingsProvider";
import { get, orderByValue } from "firebase/database";
import TableTemplateAPI from "../../Settings/UserManagement/TableTemplateAPI";
import { columns_template_heartbeat_monitoring_calls } from "../../../components/AQDataTable/columns_templates";
import AQDataTable from "../../../components/AQDataTable/AQDataTable";
import { getOnceValue } from "../../../data/firebase";
import { Unit } from "../../VirtualEnvironment/Unit";

export default function HeartbeatMonitor() {

    const [notifications, setNotifications] = useState([]);
    const { userSettings, updateSettings } = useUserSettings();
    const [firstLoad, setFirstLoad] = useState(true);
    const [lastUpdated, setLastUpdated] = useState("");
    const [unitCall, setUnitCall] = useState([]);
    const [unitConfig, setUnitConfig] = useState([]);
    const [masterConfig, setMasterConfig] = useState([]);
    const [mappedValues, setMappedValues] = useState([]);

    function getDBCode() {
        try {
            return userSettings.site.id;
        }
        catch (e) { return "" }
    }

    function getNotifications() {
        const siteID = getDBCode();
        if (firstLoad === true) setFirstLoad(false);

        if (siteID === "") return;

        const { code } = userSettings.site;

        getOnceValue(code + "heartBeat", (d) => {
            setUnitCall(d)
        },
            () => { })
        getOnceValue(code + "config", (d) => {
            setUnitConfig(d.filter(df => (df !== null)))
        },
            () => { })
    }

    useEffect(() => {
        const intervalId = setInterval(getNotifications, 60 * 1000);

        return () => clearInterval(intervalId);
    }, [firstLoad]);


    useEffect(() => { getNotifications(); }, [firstLoad === true]);

    useEffect(() => {
        const transformedData = Object.entries(unitCall).map(([key, value]) => {
            var newTime = null;
            var newRoom = null;
            var newZone = null;
            var newStatus = 'Inactive';

            if (value.time && typeof value.time === 'string') {
                const [date, timeWithMicroseconds] = value.time.split('T');
                const [time] = timeWithMicroseconds.split('.');
                const [year, month, day] = date.split('-');

                // Combine into desired format DD/MM/YYYY HH:MM:SS
                newTime = `${day}/${month}/${year} ${time}`;

                // Convert to Date object
                const dateTime = new Date(`${date}T${time}`);
                const now = new Date();

                // Calculate the time difference in hours
                const timeDifferenceInHours = (now - dateTime) / (1000 * 60 * 60);

                // Determine if the unit is ACTIVE or INACTIVE
                newStatus = timeDifferenceInHours < 24 ? 'Active' : 'Inactive';
            } else {
                newTime = 'Not Available';
            }
            if (value.room && typeof value.room === 'string') {
                newRoom = value.room;
            } else {
                newRoom = 'Not Available'
            }
            if (value.zone && typeof value.zone === 'string') {
                newZone = value.zone;
            } else {
                newZone = 'Not Available'
            }
            return { unitStatus: newStatus, unitId: key, unitName: newRoom, unitZone: newZone, lastTime: newTime, fullDate: value.time }
        });


        setMappedValues(transformedData);
    }, [unitCall])

    useEffect(() => {
        const filtered = mappedValues.filter(item => {
            return item.lastTime !== 'Not Available';
        });

        setMasterConfig(filtered);
    }, [mappedValues]);


    return (
        <Card
            className="height-full"
        >
            <h4>Heartbeat Monitoring</h4>
            <br />
            <div>{
                //lastUpdated.toString()
            }</div>

            <AQDataTable
                data={masterConfig}
                columns={columns_template_heartbeat_monitoring_calls}
                settingsType={"Heartbeat_Monitor"}
                onUpdateRefreshPath={""}
            />

        </Card>
    )
};
