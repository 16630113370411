import { Checkbox, Grid } from "@mui/material";
import { useEffect, useState } from "react";
import { v4 as uuid } from 'uuid';


export const CogvisConfig = ({ currStep, config, setConfig, page_error, setPage_error, setCurrStep, onClose }) => {
    const [sensors, setSensors] = useState(config.sensors)
    const [rooms, setRooms] = useState(config.devices)
    const [saveBtn, setSaveBtn] = useState('')
    const [hasChanged, setHasChanged] = useState(false);

    useEffect(() => {
        if (config.tryAgain)
            setSaveBtn('Try again')
        else
            setSaveBtn('next')
    }, [config.tryAgain])

    function addDevice() {
        setSensors((data) => ([
            ...data,
            {
                id: uuid(),
                sensor_id: '',
                sensor_room: '',
                isSelected: false,
                error: false,
                msg: ''
            }
        ]))
        setHasChanged(true)
    }

    function removeDevice(e) {
        // console.log('sensors', sensors)
        let tmp = sensors.filter(item => item.isSelected === false);

        console.log('remove', tmp)
        setSensors(tmp)
        setHasChanged(true)
    }
    function goBack() {
        setCurrStep(1)
    }
    const findNonUniqueNameAndId = () => {
        const idSet = new Set();

        const nonUniqueIds = [];
        let arr = sensors
        // console.log(arr)
        for (let item of arr) {
            const id = item.sensor_id;
            if (idSet.has(id)) {
                console.log(id)
                nonUniqueIds.push(item);
                item['error'] = true
            } else {
                idSet.add(id);
            }
        }
        console.log(idSet);

        setSensors(arr)
        setHasChanged(true)
        if (nonUniqueIds.length > 0)
            return false;
        else return true
    };
    function saveData(e) {
        if (sensors.length > 0) {
            if (!sensors.some(item => item.error === true))
                if (findNonUniqueNameAndId()) {
                    console.log('Sensors before save', sensors)
                    setConfig((data) => (
                        {
                            ...data,
                            sensors: sensors,
                        }
                    ))
                    setCurrStep(4)
                    setHasChanged(true)
                    setPage_error('')
                }
                else
                    setPage_error('Some sensors have the same ids')
            else
                setPage_error('Some sensors have errors')
        }
        else {
            setPage_error('Error on number of sensors')
        }
    }
    return (
        <div className='aqForm'>
            <div className='aq_fields'>
                <h4 style={{ marginTop: 0 }}>Step 2/3: Device config</h4>
                {
                    sensors.map((item) => {
                        // console.log(item)
                        return (
                            <SensorUnit hasChanged={hasChanged} setHasChanged={setHasChanged}
                                sensorList={sensors} sensor={item} setSensors={setSensors} rooms={rooms}
                                isSelected={item.isSelected} error={item.error} id={item.id} />

                        )
                    })
                }

                <button className='validate_button' onClick={addDevice}>Add Device</button>
            </div>
            <div className=' bottom_field' style={{ width: '100%', }} >

                <div>{sensors.filter(item => item['isSelected'] === true).length} items selected</div>

                <div style={{ display: 'flex', justifyContent: 'space-between', alignContent: 'center', width: '100%', }}>
                    {sensors.filter(item => item['isSelected'] === true).length > 0 ?
                        <div style={{ alignSelf: 'flex-start' }}>

                            <button className="delete_button btn_size" onClick={removeDevice}> Delete</button>
                        </div>
                        : <div></div>
                    }
                    <div className='validate_close_div' style={{ alignSelf: 'flex-end' }}>
                        <button className='validate_button btn_size' onClick={(e) => {
                            setCurrStep(1);
                            setPage_error('')
                        }}>Previous</button>
                        <button className='validate_button btn_size' style={{ width: '73px', marginRight: '2px' }} onClick={saveData}>{saveBtn}</button>

                        <button className='close_buton btn_size' onClick={onClose}>Close</button>
                    </div>
                </div>
            </div>

        </div>
    )
}
const SensorUnit = ({ hasChanged, setHasChanged, sensorList, sensor, setSensors, rooms, isSelected, error, id }) => {
    const [sensorID, setSensorID] = useState(sensor.sensor_id)
    const [paired_unit, setPaired_unit] = useState(sensor.paired_unit)
    const [sensorRoom, setRoom] = useState(sensor.sensor_room)
    const [checked, setChecked] = useState(isSelected);
    const [hasError, setHasError] = useState(error);
    const [divBg, setDivBg] = useState('div_default ');

    useEffect(() => {
        if (hasChanged) {
            setSensorID(sensor.sensor_id);
            setRoom(sensor.sensor_room);
            setPaired_unit(sensor.device);
            setHasError(sensor.error)

            setHasChanged(false)
        }

    }, [hasChanged])

    useEffect(() => {
        if (error !== hasError)
            setHasError(error)
    }, [error])

    useEffect(() => {
        setChecked(isSelected)
        let tmp = 'div_default '
        if (hasError) tmp += 'div_error '
        if (isSelected) tmp += 'div_notSelected '
        setDivBg(tmp)
    }, [isSelected, hasError])

    useEffect(() => {
        let val = true
        if (sensorID !== null && sensorID !== undefined && sensorID !== '')
            if (paired_unit !== null && paired_unit !== undefined)
                if (sensor.msg === '')
                    val = false

        const tmp = sensorList.map((item) => {
            if (item.id === id) {
                item['sensor_id'] = sensorID;
                item['sensor_room'] = sensorRoom;
                item['error'] = val;
                item['device'] = paired_unit;
            }
            return item;
        })
        setSensors(tmp);
        setHasError(val);

    }, [sensorID, paired_unit, sensor.msg])

    function handleSelection(e) {
        // setChecked(e.target.checked);
        const tmp = sensorList.map((item) => {
            if (item.id === id) {
                // console.log(e.target.name);
                item['isSelected'] = e.target.checked;
                // console.log(item)
            }
            return item;
        })
        setSensors(tmp);
    }


    return (
        <div className={divBg}>
            {
                sensor.msg !== null &&
                <div style={{ marginLeft: '40px', color: 'red' }}>
                    {sensor.msg}
                </div>
            }
            <div style={{ display: 'flex', alignItems: 'center' }}>
                <Checkbox checked={checked} onChange={handleSelection} />

                <input
                    className="config_input shadow appearance-none border rounded  py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                    type="text" style={{ marginLeft: '5px', width: '200px' }} name="sensorID"
                    placeholder='device id' value={sensorID} onChange={(e) => { setSensorID(e.target.value) }} />

                <input
                    className="tag_input"
                    type="text"
                    id="autocomplete-input"
                    list="suggestions"
                    style={{ marginLeft: '5px', width: '200px' }}
                    placeholder="Select paired unit..."
                    value={sensorRoom}
                    onChange={(e) => {
                        let selectedOption
                        if (rooms !== null)
                            selectedOption = rooms.find(item =>
                                `${item.device_zone} ${item.device_name}` === e.target.value);

                        if (selectedOption !== undefined)
                            setPaired_unit(selectedOption)
                        else
                            setPaired_unit(null)

                        // console.log('device', selectedOption)
                        setRoom(e.target.value);
                    }}
                />
                <datalist id="suggestions">
                    {rooms !== null && rooms.map(o =>
                        <option key={o.id} value={`${o.device_zone} ${o.device_name}`}>{o.device_zone} {o.device_name}</option>)}
                </datalist>
            </div>


        </div>
    )
}