import React, {useState, useEffect, useMemo, useReducer, useRef, useCallback, Fragment} from 'react'

//
import {
  createColumnHelper,
  Column,
  Table,
  ColumnDef,
  ExpandedState,
  PaginationState,
  useReactTable,
  getCoreRowModel,
  getPaginationRowModel,
  getFilteredRowModel,
  getExpandedRowModel,  
  flexRender,
  RowData,
} from '@tanstack/react-table'
import { secondsToHHMMSS, displayDateFromUTC } from '../functions/dataHandler'


function useSkipper() {
  const shouldSkipRef = useRef(true)
  const shouldSkip = shouldSkipRef.current

  // Wrap a function with this to skip a pagination reset temporarily
  const skip = useCallback(() => {
    shouldSkipRef.current = false
  }, [])

  useEffect(() => {
    shouldSkipRef.current = true
  })

  return [shouldSkip, skip]
}

const ConnectDataTable=({data, type})=> {
  
  const [tableData, setTableData] = useState([]);
  const [columnOrder, setColumnOrder] = useState(["expander", "call_type", "room", "zone", "resident", "start", "end", "carer", "initial_response_seconds", "duration_seconds", "care_clicks"])
  
  useEffect(() => {
    console.log("ConnectDataTable", data, type);
    if (data !== null && data !== undefined) {
        if (type === "journey") {
          setTableData(journerObjectToArray(data));
        }
        else if (type === "journeysummarycall") {
          const tmpData = data.map((item) => {
            return {
              id: item.jc_id,
              start: item.jc_start,
              end: item.jc_end,
              care_clicks: item.cll_care_clicks,
              carer: item.cll_carer,
              duration: item.cll_duration,
              panel: item.cll_panel_name,
              resident: item.cll_resident,
              room: item.jc_room,
              zone: item.jc_zone,
              call_type: item.jc_callType,
              duration_seconds: item.js_duration_secs,
              initial_response_seconds: item.js_initial_response_secs,
            };

          });
          
          setTableData(tmpData);
        }
        else {
          setTableData(data);
        }
    }
}, [data]);
  const [autoResetPageIndex, skipAutoResetPageIndex] = useSkipper()
  const [expanded, setExpanded] = useState({})
  
  const columns = useMemo(
    () => [
      /*{
        id: 'expander',
        header: () => null,
        cell: ({ row }) => {
          return (
          <div
                style={{
                  // Since rows are flattened by default,
                  // we can use the row.depth property
                  // and paddingLeft to visually indicate the depth
                  // of the row
                  paddingLeft: `${row.depth * 2}rem`,
                }}
              >
                <>
                {
                row.getCanExpand() ? (
                    <button
                      {...{
                        onClick: (r)=>{
                          console.log("Row", row);  
                          row.getToggleExpandedHandler();
                        },
                        style: { cursor: 'pointer' },
                      }}
                    >
                      {row.getIsExpanded() ? '👇' : '👉'}
                    </button>
                  ) : (
                    '🔵'
                  )}{' '}
                  {row.getValue()}  
                  }
                </>
              </div>
  );
/*
          return row.getCanExpand() ? (
            <button
              {...{
                onClick: row.getToggleExpandedHandler(),
                style: { cursor: 'pointer' },
              }}
            >
              {row.getIsExpanded() ? '👇' : '👉'}
            </button>
          ) : (
            '🔵'
          )

        },
        
      },
      */
      {
        id: 'call_type',
        header: () => <span>Call Type</span>,
        accessorKey: 'call_type',
        cell: (info) => info.getValue(),
      },
      {
        id: 'room',
        header: () => <span>Room</span>,
        accessorKey: 'room',
        cell: (info) => info.getValue(),
      },   
      {
        id: 'zone',
        header: () => <span>Zone</span>,
        accessorKey: 'zone',
        cell: (info) => info.getValue(),
      },
      {
        id: 'resident',
        header: () => <span>Resident</span>,
        accessorKey: 'resident',
        cell: (info) => info.getValue(),
      },
      {
        id: 'start',
        header: () => <span>Start</span>,
        accessorKey: 'start',
        cell: (info) => displayDateFromUTC(info.getValue()),
      
      },
      {
        id: 'end',
        header: () => <span>End</span>,
        accessorKey: 'end',
        cell: (info) => displayDateFromUTC(info.getValue()),
      },
      {
        id: 'carer',
        header: () => <span>Carer</span>,
        accessorKey: 'carer',
        cell: (info) => info.getValue(),
      },  
      {
        id: 'initial_response_seconds',
        header: () => <span>Initial Response</span>,
        accessorKey: 'initial_response_seconds',
        cell: (info) => <span>{secondsToHHMMSS(info.getValue())}</span>,
        enableColumnFilter: false,
      },
      {
        id: 'duration_seconds',
        header: () => <span>Duration</span>,
        accessorKey: 'duration_seconds',
        cell: (info) => <span>{secondsToHHMMSS(info.getValue())}</span>,
        enableColumnFilter: false,
      },
      {
        id: 'care_clicks',
        header: () => <span>Care</span>,
        accessorKey: 'care_clicks',
        cell: (info) => info.getValue(),
        enableColumnFilter: false,
      },
    ],
    []
  );
  console.log("breakdownData", tableData);
  const renderSubComponent = ({ row }) => {
    return (
      <pre style={{ fontSize: '10px' }}>
        <code>{JSON.stringify(row.original, null, 2)}</code>
      </pre>
    )
  }

  const table = useReactTable({
    data: tableData,
    columns,
    renderSubComponent,
    //getRowCanExpand ,
    state: {
      //  columnVisibility,
      expanded,
      columnOrder,
    },
    onExpandedChange: setExpanded,
    onColumnOrderChange: setColumnOrder,
    //defaultColumn,
    getCoreRowModel: getCoreRowModel(),
    getFilteredRowModel: getFilteredRowModel(),
    getPaginationRowModel: getPaginationRowModel(),
    getExpandedRowModel: getExpandedRowModel(),
    autoResetPageIndex,
    // Provide our updateData function to our table meta
    meta: {
      updateData: (rowIndex, columnId, value) => {
        // Skip page index reset until after next rerender
        skipAutoResetPageIndex()
        setTableData(old =>
          old.map((row, index) => {
            if (index === rowIndex) {
              return {
                ...old[rowIndex],
                [columnId]: value,
              }
            }
            return row
          })
        )
      },
    },
    debugTable: true,
  })

  return (
    <div className="p-2">
      <div className="h-2" />
      <table className='calls'>
        <thead>
          {table.getHeaderGroups().map(headerGroup => (
            <tr key={headerGroup.id}>
              {headerGroup.headers.map(header => {
                return (
                  <th key={header.id} colSpan={header.colSpan}>
                    {header.isPlaceholder ? null : (
                      <div>
                        {flexRender(
                          header.column.columnDef.header,
                          header.getContext()
                        )}
                        {
                        /*
                        header.column.getCanFilter() ? (
                          <div>
                            <Filter column={header.column} table={table} />
                          </div>
                        ) : null
                        */
                        }
                      </div>
                    )}
                  </th>
                )
              })}
            </tr>
          ))}
        </thead>
        <tbody>
          {table.getRowModel().rows.map(row => {
            return (
              <Fragment key={row.id}>
                <tr>
                  {/* first row is a normal row */}
                  {row.getVisibleCells().map(cell => {
                    return (
                      <td key={cell.id}>
                        {flexRender(
                          cell.column.columnDef.cell,
                          cell.getContext()
                        )}
                      </td>
                    )
                  })}
                </tr>
                {row.getIsExpanded() && (
                  <tr>
                    {/* 2nd row is a custom 1 cell row */}
                    <td colSpan={row.getVisibleCells().length}>
                      New row
                      {renderSubComponent({ row })}
                    </td>
                  </tr>
                )}
              </Fragment>
            )
            
            /*
            return (
              <tr key={row.id}>
                {row.getVisibleCells().map(cell => {
                  return (
                    <td key={cell.id}>
                      {flexRender(
                        cell.column.columnDef.cell,
                        cell.getContext()
                      )}
                    </td>
                  )
                })}
              </tr>
            )
            */
          })}
        </tbody>
      </table>
      {//<pre>{JSON.stringify(expanded, null, 2)}</pre>
}
{//<pre>{JSON.stringify(table.getState().pagination, null, 2)}</pre>
}
      {//<pre>{JSON.stringify(table.getState().columnOrder, null, 2)}</pre>
}
      <div className="h-2" />
      <div className="flex items-center gap-2 table_pagination">
        <button
          className="border rounded p-1"
          onClick={() => table.setPageIndex(0)}
          disabled={!table.getCanPreviousPage()}
        >
          {'<<'}
        </button>
        <button
          className="border rounded p-1"
          onClick={() => table.previousPage()}
          disabled={!table.getCanPreviousPage()}
        >
          {'<'}
        </button>
        <button
          className="border rounded p-1"
          onClick={() => table.nextPage()}
          disabled={!table.getCanNextPage()}
        >
          {'>'}
        </button>
        <button
          className="border rounded p-1"
          onClick={() => table.setPageIndex(table.getPageCount() - 1)}
          disabled={!table.getCanNextPage()}
        >
          {'>>'}
        </button>
        <span className="flex items-center gap-1">
          <div>Page</div>
          <strong>
            {table.getState().pagination.pageIndex + 1} of{' '}
            {table.getPageCount()}
          </strong>
        </span>
        <span className="flex items-center gap-1">
          | Go to page:
          <input
            type="number"
            defaultValue={table.getState().pagination.pageIndex + 1}
            onChange={e => {
              const page = e.target.value ? Number(e.target.value) - 1 : 0
              table.setPageIndex(page)
            }}
            className="border p-1 rounded w-16"
          />
        </span>

        <select
          value={table.getState().pagination.pageSize}
          onChange={e => {
            table.setPageSize(Number(e.target.value))
          }}
        >
          {[10, 20, 30, 40, 50].map(pageSize => (
            <option key={pageSize} value={pageSize}>
              Show {pageSize}
            </option>
          ))}
        </select>
      </div>
      
    </div>
  )

}
function Filter({
  column,
  table,
}) {
  const firstValue = table
    .getPreFilteredRowModel()
    .flatRows[0]?.getValue(column.id)

  const columnFilterValue = column.getFilterValue()

  return typeof firstValue === 'number' ? (
    <div className="flex space-x-2">
      <input
        type="number"
        value={(columnFilterValue)?.[0] ?? ''}
        onChange={e =>
          column.setFilterValue((old) => [
            e.target.value,
            old?.[1],
          ])
        }
        placeholder={`Min`}
        className="w-24 border shadow rounded"
      />
      <input
        type="number"
        value={(columnFilterValue)?.[1] ?? ''}
        onChange={e =>
          column.setFilterValue((old) => [
            old?.[0],
            e.target.value,
          ])
        }
        placeholder={`Max`}
        className="w-24 border shadow rounded"
      />
    </div>
  ) : (
    <input
      type="text"
      value={(columnFilterValue ?? '')}
      onChange={e => column.setFilterValue(e.target.value)}
      placeholder={`Search...`}
      className="w-36 border shadow rounded"
    />
  )
}


function journerObjectToArray(journeyData){
  const arr = [];
  Object.values(journeyData).forEach((item) => {
      arr.push({
          id: item[0].js_journey_id,
          start: item[0].js_start,
          end: item[0].js_end,
          care_clicks: item[0].cll_care_clicks,
          carer: item[0].cll_carer,
          duration: item[0].cll_duration,
          panel: item[0].cll_panel_name,
          resident: item[0].cll_resident,
          room: item[0].jc_room,
          zone: item[0].jc_zone,
          call_type: item[0].js_callType,
          duration_seconds: item[0].js_duration_secs,
          initial_response_seconds: item[0].js_initial_response_secs,
      });
  });
  return arr;
}

export default ConnectDataTable;