import { useState } from 'react';
import { Card, TextInput } from '@tremor/react';

export const RoomUnitCard = ({ unitKey, data, onClose, onSave, onNew }) => {

  const [formData, setFormData] = useState((data));

  function handleEvent(ev) {
    const { name, value } = ev.target;
    setFormData((prevFormValues) => ({
      ...prevFormValues, [name]: value
    }));
  }

  function update() {
    if (unitKey === "NEWDEVICE") {
      onNew(unitKey, formData);
      return;
    }
    onSave(unitKey, formData);
  }

  return (
    <Card>
      <TextInput name="unitId" placeholder="ID" value={formData.unitId} onChange={handleEvent} />
      <TextInput name="unitName" placeholder="Name" value={formData.unitName} onChange={handleEvent} />
      <TextInput name="unitZone" placeholder="Zone" value={formData.unitZone} onChange={handleEvent} />
      <TextInput name="linkID" placeholder="Linked Panel Code" value={formData.linkID} onChange={handleEvent} />
      <TextInput name="iiwari_room_id" placeholder="Move - Room Config" value={formData.iiwari_room_id} onChange={handleEvent} />
      <button onClick={() => update()}>Update</button>
    </Card>
  );
};
