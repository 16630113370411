import { useState, useEffect } from "react";
import { Accordion, AccordionHeader, List, ListItem, Card, Title } from "@tremor/react";
import { apiPost } from "../../../data/apidata";
import "./BatteryMonitor.css";
import AQToolTip from "../../../components/ToolTip/ToolTip";
import { displayDateFromUTC, displayDateTimeFromUTC } from "../../../functions/dataHandler";
import { useUserSettings } from "../../../components/UserSettingsProvider/UserSettingsProvider";
import { get } from "firebase/database";
import TableTemplateAPI from "../../Settings/UserManagement/TableTemplateAPI";
import { columns_template_battery_monitoring_calls } from "../../../components/AQDataTable/columns_templates";
import AQDataTable from "../../../components/AQDataTable/AQDataTable";

export default function Batterymonitor(){
  
  const [notifications, setNotifications] = useState([]);
  const { userSettings, updateSettings } = useUserSettings();
  const [firstLoad, setFirstLoad] = useState(true);
  const [lastUpdated, setLastUpdated] = useState("");
  let timer = null;

  function getDBCode(){
    try { 
      return userSettings.site.id; }
    catch(e){ return "" }
  }

  function getNotifications(){
    const siteID = getDBCode();
    if (firstLoad===true) setFirstLoad(false);

    if (siteID==="") return;
    apiPost("/api/messages/notifications/list", {"siteID": siteID},(d)=>{
      setNotifications(d.message.lowbattery);
      setLastUpdated(new Date());
    },
    (e)=>{console.log(e)})
    
  }
  //adc disabled notifications

  useEffect(() => {
    const intervalId = setInterval(getNotifications, 60*1000);

    return () => clearInterval(intervalId);
  }, [firstLoad]);


useEffect(() => { getNotifications(); }, [firstLoad === true]);

  return(
  <Card 
    className="height-full"
  > 
    <h4>Battery Levels</h4>
    <br />
    <div>{
    //lastUpdated.toString()
    }</div>

    <AQDataTable 
      data={notifications}
      columns={columns_template_battery_monitoring_calls}
      settingsType={"Battery_Monitor"}
      onUpdateRefreshPath={""}
      dataRefresh={getNotifications}
    />       


  </Card>
)};


const GetIcon=({iconType})=>{
  if (iconType==="Unit battery low.") return <BatteryIconLow />; 
  if (iconType==="High Battery") return <BatteryIconHigh />;
  if (iconType==="Charging") return <ChargingIcon />;
  return <></>;
}

const ChargingIcon=()=><svg xmlns="http://www.w3.org/2000/svg" fill="yellow" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="w-10 h-10">
  <path stroke-linecap="round" stroke-linejoin="round" d="m3.75 13.5 10.5-11.25L12 10.5h8.25L9.75 21.75 12 13.5H3.75Z" />
</svg>

const BatteryIconLow = ()=><svg xmlns="http://www.w3.org/2000/svg" fill="red" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="w-12 h-12">
<path stroke-linecap="round" stroke-linejoin="round" d="M21 10.5h.375c.621 0 1.125.504 1.125 1.125v2.25c0 .621-.504 1.125-1.125 1.125H21M4.5 10.5h6.75V15H4.5v-4.5ZM3.75 18h15A2.25 2.25 0 0 0 21 15.75v-6a2.25 2.25 0 0 0-2.25-2.25h-15A2.25 2.25 0 0 0 1.5 9.75v6A2.25 2.25 0 0 0 3.75 18Z" />
</svg>


const BatteryIconHigh = ()=><svg xmlns="http://www.w3.org/2000/svg" fill="green" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="w-12 h-12">
<path stroke-linecap="round" stroke-linejoin="round" d="M21 10.5h.375c.621 0 1.125.504 1.125 1.125v2.25c0 .621-.504 1.125-1.125 1.125H21M3.75 18h15A2.25 2.25 0 0 0 21 15.75v-6a2.25 2.25 0 0 0-2.25-2.25h-15A2.25 2.25 0 0 0 1.5 9.75v6A2.25 2.25 0 0 0 3.75 18Z" />
</svg>


const PhoneIcon = ()=><svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="w-6 h-6">
<path stroke-linecap="round" stroke-linejoin="round" d="M10.5 1.5H8.25A2.25 2.25 0 0 0 6 3.75v16.5a2.25 2.25 0 0 0 2.25 2.25h7.5A2.25 2.25 0 0 0 18 20.25V3.75a2.25 2.25 0 0 0-2.25-2.25H13.5m-3 0V3h3V1.5m-3 0h3m-3 18.75h3" />
</svg>


const CheckLocation=({location, calls})=>{
  const filteredCalls = calls.filter(c=>(c.location===location));
  
  if (filteredCalls.length===0) return <></>;
  console.log({filteredCalls})
  
  return(
    <>
      <CallCard data={filteredCalls} location={location} />
    </>
  );
}

const CallCard=({data, location})=>{

  return (
    <div>

      <div className={"bg_sense_" + location + " colour-white flex-row"}>      
        <CallHeader data={location} />
        <div>
          <List>
            {data.map((d) => (
              <ListItem className="colour-white" key={d.room}>
                <span>{d.room}</span>
              </ListItem>
            ))
            }
          </List>
        </div>
      </div>
    </div>
  );

}

function getLocationDisplay(location){
  if (location==="bed") return "In bed";
  if (location==="fall") return "Fall";
  if (location==="manOnGround") return "On the ground";
  if (location==="bathroom") return "In bathroom";
  if (location==="chair") return "In Chair";
  if (location==="room") return "In Room";

  return location;
}

const CallHeader=({data})=>{
  return (
    <div className="sense_call_header_container">
        <div className="align-left left-padding-10">{getLocationDisplay(data)}</div>
    </div>
  );
}
