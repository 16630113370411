import React, { useEffect, useState, useRef } from 'react';
import './NourishMonitor';
import { Grid } from '@mui/material';
import { RoomUnitCard } from '../RoomUnitConfig/RoomUnitCard';
import { utcDateToYYYYMMDD } from "../../components/AQDataTable/columns_templates.js";

import { apiPost } from '../../data/apidata';
// import DELETE_ICON from '../../images/settings/delete.png';

export const NourishResidentDetails = ({ userData, siteID, dataRefresh, options, onClose }) => {
    //console.log(userData);
    console.log("user data", userData)
    console.log("options", options)
    userData.dob = utcDateToYYYYMMDD(userData.dob)
    const [assignedRooms, setAssignedRooms] = useState([]);
    const [assignedZone, setAssignedZone] = useState([]);

    // useEffect(() => {
    //     let rooms = []
    //     options.map(opt => {

    //         if (options.RoomUnitslength > 0)
    //             if (userData.RoomUnits.some(room => room === opt.unitCode))
    //                 rooms.push(opt)
    //     })
    //     setAssignedRooms(rooms)
    //     if (rooms.length > 0)
    //         setAssignedZone(rooms[0].zone)
    //     console.log("rooms are", rooms)
    // }, [userData])
    useEffect(() => {
        getRooms();
    }, userData);
    function getRooms() {
        apiPost("api/messages/residents/resident/getResidentRoomUnits",
            {
                siteID: siteID,
                dob: userData.dob,
                nhsNumber: userData.nhsNumber,
            },
            (d) => {
                let rr = d.message.data[0][0].map(unitRecord => {
                    console.log("dadad", unitRecord.unitCode)
                    return ({
                        "unitCode": unitRecord.unitCode,
                        "value": options.filter(optionRecord => (optionRecord.unitCode === unitRecord.unitCode))[0].value,
                        "zone": options.filter(optionRecord => (optionRecord.unitCode === unitRecord.unitCode))[0].zone,
                    })
                })
                console.log("Assigned rooms", rr)
                if (rr.length > 0)
                    setAssignedZone(rr[0].zone)

                setAssignedRooms(rr)
            },
            (e) => {
                console.log("Room units error", e)
            })

    }

    function addComma(i) {
        if (i !== assignedRooms.length - 1)
            return ","
    }

    return (
        <div className='new_user_page_container'>
            <h1>Resident details</h1>
            <div className='aqForm' >
                <p className='resident_p'>This resident is integrated with Nourish. <a className='nourish_link' href={userData.clientSystemUrl}>View details in Nourish</a></p>
                <br />
                <br />

                <div className="aq_fields">
                    <Grid container spacing={1}>

                        <Grid item xs={6} sm={6} md={6} lg={6}>
                            <div>
                                <div>Name</div>
                                <div><b>{userData.title} {userData.firstName} {userData.surname}</b></div>
                            </div>
                        </Grid>
                        <Grid item xs={6} sm={6} md={6} lg={6}>
                            <div>
                                <div>Date of birth</div>
                                <div><b>{userData.dob}</b></div>
                            </div>
                        </Grid>
                        <Grid item xs={12} sm={12} md={12} lg={12} ><br /></Grid>
                        <Grid item xs={6} sm={6} md={6} lg={6}>
                            <div>
                                <div>NHS number</div>
                                <div><b>{userData.nhsNumber}</b></div>
                            </div>
                        </Grid>

                        <Grid item xs={6} sm={6} md={6} lg={6}>
                            <div>
                                <div>Gender</div>
                                <div><b>{userData.gender}</b></div>
                            </div>
                        </Grid>

                        <Grid item xs={12} sm={12} md={12} lg={12} ><br /></Grid>
                        <Grid item xs={12} sm={12} md={12} lg={12}>
                            <div>
                                <div>
                                    <div>Assigned Rooms:</div>
                                    <div>
                                        <b>
                                            {
                                                assignedRooms.map((opt, i) =>

                                                    <span>{opt.value}{addComma(i)} </span>
                                                )}
                                        </b>
                                    </div>
                                </div>
                            </div>
                        </Grid>


                        <Grid item xs={12} sm={12} md={12} lg={12} ><br /></Grid>

                        <Grid item xs={8} sm={8} md={8} lg={8}>
                            <div>
                                <div>Zone:</div>
                                <div><b>{assignedZone}</b></div>
                            </div>
                        </Grid>


                    </Grid>


                    <br />

                    <div className='submit_fields'>
                        <div className='validate_close_div'>
                            <button className='close_buton' onClick={onClose}>Close</button>
                        </div>
                    </div>
                </div>
            </div>
        </div >
    );
};

const ShowRooms = () => {

}
