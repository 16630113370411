import React from "react";
import { NavLink } from "react-router-dom";
import IMG_LOGO from '../../../images/logo_white_background.jpg';

export const MobileNavBarBrand = ({ handleClick }) => {
  return (
    <div onClick={handleClick} className="mobile-nav-bar__brand">
      <NavLink to="/">
        <img
          className="mobile-nav-bar__logo"
          src={IMG_LOGO}
          alt="Arquella Connect"
          //width="82"
          //height="24"
        />
      </NavLink>
    </div>
  );
};
