import { Card, Title } from "@tremor/react";
import { useState, useEffect } from "react";
import { Accordion, AccordionHeader, AccordionBody, AccordionList } from "@tremor/react";
import { GetCallTypeImage } from "../GetImage";
import { getOnValue } from "../../data/firebase";
import { lCase } from "../../functions/string";
import CallTimer from "../CallTimer";
import CareClicksField from "../CareClicksField/CareClicksField";

export default function LiveDashboardLiveCalls({sitesList}){

  const [calls, setCalls] = useState([]);
  const [masterCalls, setMasterCalls] = useState([]);
  
  useEffect(()=>{
    if (masterCalls===null) {
      setCalls([]);
      return;
    }
    const tmpsCalls = [];
    Object.keys(masterCalls).map((k)=>{
      tmpsCalls.push(masterCalls[k]);
    });
    setCalls(tmpsCalls);
  }, [masterCalls])

  function onError(error){
    console.log("error", error);
  }

  function setupSiteListeners(){
    if ((!sitesList)||(sitesList.length===0)) return;
    sitesList.forEach(site => {
      if (site==="") return;
      getOnValue(site + "PhoneLiveCalls", setMasterCalls, onError);
    });
  }

  useEffect(()=>setupSiteListeners(), [sitesList]);
 
  function getUniqueKey(key){
    let rtn = `${new Date().toISOString()}${key}`;
    return rtn;
  }
  
  return(

  <Card 
  className="height-full"
  >
    <Title className="dashboard_section_title upper_case">Live Calls</Title>
    <br />
    <div className="dashboard_frame">
      <div>
        {calls.filter((cc)=>(cc.callType==="Emergency")).map((c,i)=><CallCard data={c} key={(c.aqRef) ? c.aqRef : `Emergency${i}`} />)}
        {calls.filter((cc)=>(cc.callType==="Accessory")).map((c,i)=><CallCard data={c} key={(c.aqRef) ? c.aqRef : `Accessory${i}`} />)}
        {calls.filter((cc)=>(cc.callType==="Sense")).map((c,i)=><CallCard data={c} key={(c.aqRef) ? c.aqRef : `Sense${i}`} />)}
        {calls.filter((cc)=>(cc.callType==="Assistance")).map((c,i)=><CallCard data={c} key={(c.aqRef) ? c.aqRef : `Assistance${i}`} />)}
        {calls.filter((cc)=>(cc.callType==="Call")).map((c,i)=><CallCard data={c} key={(c.aqRef) ? c.aqRef : `Call${i}`} />)}
        {calls.filter((cc)=>(cc.callType==="Attendance")).map((c,i)=><CallCard data={c} key={(c.aqRef) ? c.aqRef : `Attendance${i}`} />)}
        {calls.filter((cc)=>(cc.callType==="CareCall")).map((c,i)=><CallCard data={c} key={(c.aqRef) ? c.aqRef : `CareCall${i}`} />)}
      </div>
    </div>
  </Card>
)};



const CallCard=({data})=>{
  
  return (
    <div className={"live_call_container bg_" + lCase(data.callType)}>
        <div className="live_call_header_container">
        <GetCallTypeImage imgString={lCase(data.callType)} />
        <div>
          <div>{data.room}</div>
          <div><small>{data.zone}</small></div>
        </div>
        <div><CallTimer startTime={data.start} /></div>
    </div>
    </div>
  );

}
