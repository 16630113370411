import React, { useEffect, useRef, useState } from "react";
import Tag from "./Tag";
import "./Taggins.css";
import { groupBy } from "../../functions/objects_arrays";

const Tagging = ({ options, selections, onRemove, onAdd }) => {
  
  // Create an array of refs
  const inputRefGroup = useRef(null);
  const inputRef = useRef(null);
  const [data, setData] = useState(null);
  const [groupByData, setGroupByData] = useState([]);
  const [finalData, setFinalData] = useState([]);
  
  const clearInput = () => {
    inputRef.current.value = '';
  };
  
  function createFinalData(selectedValue){
    clearInput();
    try { setFinalData((data[selectedValue.zone]!==undefined)?data[selectedValue.zone]:[]); }
    catch(e) { setFinalData([]); }
  }

  useEffect(()=>{ 
    if (data===null) return;
    setGroupByData(Object.keys(data)); 
  }, [data])

  useEffect(()=>{
    if (options===undefined) return;
    if (options.length===0) return;
    setData(groupBy(options, "zone"))
  }, [options])
  
  console.log("dddataat", data);
  console.log("sssseet", finalData);

  return (
    <div>
      <div className="flex-column">
      <div className="flex-row">
      <input
          className="tagging"
          type="text"
          id="group-input"
          list="groupsuggestions"
          key={inputRefGroup}
          placeholder="Select a zone..."
//          onClick={(e)=>{e.target.value = '';}}
          onKeyDown={(e) => {
              const selectedOption = options.find(opt => opt.zone === e.target.value);
              if (selectedOption) {
                createFinalData(selectedOption);
              }
          }}
        />

        <input
          className="tagging"
          type="text"
          id="autocomplete-input"
          list="suggestions"
          ref={inputRef}
          placeholder="Select a room..."
          onChange={(e)=>{
            const selectedOption = options.find(opt => opt.value === e.target.value);
            if (selectedOption) {
              if (onAdd) onAdd(selectedOption);
              e.target.value = ''; // Clear input after adding
            }
          }}
          onKeyDown={(e) => {
            if (e.key === 'Enter') {
              const selectedOption = options.find(opt => opt.value === e.target.value);
              if (selectedOption) {
                if (onAdd) onAdd(selectedOption);
              }
              e.target.value = ''; // Clear input after adding
            }
          }}
        />
      </div>
        <div className="tagging_class_container">
          {selections.map((d, i) => (
            <Tag
              key={d.unitCode}
              ref={React.createRef()}
              onRemoveTag={onRemove}
              option={d}
            />
          ))}
        </div>
        <datalist id="groupsuggestions">
          {groupByData.map(o => <option key={o} value={o}>{o}</option>)}
        </datalist>
        <datalist id="suggestions">
          {finalData.map(o => <option key={o.unitCode} disabled={(selections.filter(sf=>sf.unitCode===o.unitCode).length>0)} value={o.value}>{o.value}</option>)}
        </datalist>
      </div>
    </div>
  );


};

export default Tagging;