
import LiveDashboardToday from "../components/charts/LiveDashboardToday";
import LiveDashboardLiveCalls from "../components/charts/LiveDashboardLiveCalls";
import SenseLive from "../components/charts/SenseLive";
import Notifications from "../components/charts/Notifications";

export default function Live({siteObj}) {
  
  if (siteObj===null) return <div>No site details.</div>

  return (
      <div className="dashboard_container_grid">
        <div className="dashboard_container_grid_item1 dashboard_container_grid_item height-full">
          <LiveDashboardLiveCalls sitesList={[siteObj.siteCode]} />
        </div>
        <div className="dashboard_container_grid_item2 dashboard_container_grid_item height-full">
          <LiveDashboardToday siteCode={siteObj.siteCode} />
        </div>
        <div className="dashboard_container_grid_item4 dashboard_container_grid_item height-full">
          <SenseLive sitesList={[siteObj.senseCode]} />
        </div>       
        <div className="dashboard_container_grid_item3 dashboard_container_grid_item height-full">
          <Notifications sitesList={[siteObj.senseCode]} siteID={siteObj.siteID} />
        </div>     

      </div>

  );
  
}

