import { useEffect } from "react";
import { AQModal } from "./Modal/Modal";
import { useState } from "react";
import { apiPost } from "../data/apidata";
import { StatusOnlineIcon } from "@heroicons/react/outline";
import {
  Card,
  Table,
  TableHead,
  TableRow,
  TableHeaderCell,
  TableBody,
  TableCell,
  Text,
  Title,
  Badge,
} from "@tremor/react";
import { displayDateFromUTC } from "../functions/dataHandler";
import { groupBy } from "../functions/objects_arrays";
import ConnectDataTable from "./ConnectDataTable";
import SpinnerLoader from "./SpinnerLoader";

export const DataBreakDown = ({preData, filters, onClose }) => {
  console.log("DataBreakDown", filters, preData);
  const [data, setData] = useState();
  const postUrl = "/dashboard/data/getlist";
  const [dataType, setDataType] = useState("journey");

  function getData(){
    apiPost(postUrl, filters, (d) => {
      if (filters.callType !== "All Calls") {
        d = d.filter((item) => (item.jc_callType === filters.callType));
      }
      d = groupBy(d, "js_journey_id");
      setData(d);  
    }, (e) => { console.log(e); });
  }
  
  useEffect(() => {
    console.log("DataBreakDown", filters, preData);
    if (preData!==undefined) {
      setData(preData);
      return;
    } 
    else {
      if (filters!==undefined) getData();
    }
  }, [filters, preData]);


return (
  <AQModal show={true} onClose={onClose} >
    {
    ((data===null)||(data===undefined))
    ? <div className="m-4"><div className="m-4"><SpinnerLoader isLoading={true} /></div></div> 
    : <ConnectDataTable type={(Array.isArray(data) ? "journeysummarycall" : "journey")} data={data} />
    }
  </AQModal>
);

};
