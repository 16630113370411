import React, { useState } from 'react';

const NavUserMenu = () => {
  const [isMenuOpen, setMenuOpen] = useState(false);

  const toggleMenu = () => {
    setMenuOpen(!isMenuOpen);
  };

  const handleOptionClick = (option) => {
    // You can perform any action based on the selected option here
    console.log(`Selected option: ${option}`);

    // Close the menu after selecting an option
    setMenuOpen(false);
  };
  return(<></>);
  return (
    <div className="user-menu">
      <button onClick={toggleMenu}>
        User Menu
      </button>

      {isMenuOpen && (
        <div className="dropdown">
          <ul>
            <li onClick={() => handleOptionClick('Profile')}>
              Profile
            </li>
            <li onClick={() => handleOptionClick('Settings')}>
              Settings
            </li>
            <li onClick={() => handleOptionClick('Logout')}>
              Logout
            </li>
          </ul>
        </div>
      )}
    </div>
  );
};

export default NavUserMenu;
