import './UserManagement.css';
import { columns_template_user_management } from "../../../components/AQDataTable/columns_templates";
import TableTemplateAPI from "./TableTemplateAPI";
import { useUserSettings } from "../../../components/UserSettingsProvider/UserSettingsProvider";

const UserManagement=()=>{
    
    const { userSettings, updateSettings } = useUserSettings();
    
    const postData = {
        "siteID":`${userSettings.site.id}`,
        "userName":"1", 
        "userToken":"1"
    };

    return (
        <TableTemplateAPI
        siteID={userSettings.site.id}
        title={"Teams"}
        apiPath={"/api/messages/site/users/list"}
        apiParams={postData}
        tableTemplate={columns_template_user_management}
        tableSettingsType={"team_management"}
        onUpdateRefreshPath={""}
    />
    );
}



export default UserManagement;