import { Text } from "@tremor/react";
import { FormInput } from "../../Input";
import "./FormTextInput.css";

export const FormTextInput = ({ label, id, name, value, onChange, placeholder, errorText }) => {

  return (
    <div className="formcontrol_formtextinput_container">
      {label!==""&&<Text>{label}:</Text>}
      <FormInput id={id} name={name} value={value} onChange={onChange} placeholder={placeholder} />
      {((errorText!=undefined) && (errorText!="")) && <Text className="error">{errorText}</Text>}
    </div>
  );
};
