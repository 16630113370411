import { useState } from "react";
import { useEffect } from "react";
import { getOnceValue, setValue } from "../../../data/firebase";
import './monitor.css';
import { PageLayout } from "../../../components/page-layout";
import { apiPost } from "../../../data/apidata";

const RemoteCheck=({site})=>{

    const [data, setData] =  useState(null);

    function getDataOnce(){
        apiPost("/api/messages/settings/ping/list", {"siteCode": `${site}`},
        (d)=>{
            const v = d.message.data;
            setData(v);
        },
        (e)=>{console.log("eeeeee",e); })
        //getOnceValue(`${site}_site/panel_status`, setData, (err)=>{console.log(err)});
    }
    
    function sendPing(){
        setValue(`${site}_site/config/remote/statusPing`, 2, ()=>{
            setTimeout(getDataOnce(), 5000);
        })
    }

    useEffect(()=>getDataOnce(), [site]);

    //if (data===null) return (<div>Loading...</div>);
    return(
        <PageLayout>
            <div>
                <div>
                    <button 
                        onClick={()=>sendPing()}
                    >Status Report</button>
                </div>
            
                <div>
                    {data!=null&&<ShowReports data={data} />}
                </div>

            </div>
        </PageLayout>
    );
}

const ShowReports=({data})=>{
    console.log("dddddd", data);
    
    const [selection, setSelection] = useState("");

    return(
        <div>
            {
            Object.keys(data).map(d=>{
                return(
                    <div>

                        <div 
                            onClick={()=>setSelection(d)}
                            key={`showrepdat${d}`}>{d}</div>

                        <div>
                            <ShowSelection data={data[d]} />
                        </div>
                    </div>
                    
                )
            })
            }
        </div>
    );
}


const ShowSelection=({data})=>{
    
    return(
        <table>
            <tr>
                <th>Date</th>
                <th>Panel</th>
                <th>Version</th>
                <th>Battery</th>
            </tr>
            {
            Object.keys(data).map(d=>{
                return(
                    <tr>
                        <td>{data[d]["time-reported"]}</td>
                        <td>{data[d]["panel_reference"]}</td>
                        {/*<td>{data[d]["panel_login"]}</td>*/}
                        <td>{data[d]["version_number"]}</td>
                        <td><ShowBatteryStatus battery_percent={data[d]["battery_percent"]} battery_status={data[d]["battery_status"]} /></td>
                    </tr> 
                )
            })
            }
        </table>
    );

}

const ShowBatteryStatus=({battery_percent, battery_status})=>{
    return (
        <div></div>
    )
}

export default RemoteCheck;