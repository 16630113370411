import { columns_template_user_management } from "../../components/AQDataTable/columns_templates";
import { PageLayout } from "../../components/page-layout";
import TableTemplateAPI from "./UserManagement/TableTemplateAPI";

const ConnectUsers=()=>{

    return(

            <TableTemplateAPI
                title={"User Management"}
                apiPath={"/api/messages/settings/usersitemanagement/list"}
                apiParams={{"userID":"1"}}
                tableTemplate={columns_template_user_management}
                tableSettingsType={"user_management"}
            />

    );
}

export default ConnectUsers;