import React, { useState, useEffect } from 'react';
import { getDifferenceInSeconds } from '../functions/dataHandler';

const CallTimer = ({startTime, spanClass}) => {
  const [setUtcTime, setSetUtcTime] = useState(new Date(startTime)); // Replace this with your desired set UTC time
  const [timeDifference, setTimeDifference] = useState(0);
  
  useEffect(() => {
    const interval = setInterval(() => {
      const currentUtcTime = new Date();
      const differenceInSeconds = getDifferenceInSeconds(currentUtcTime, setUtcTime);
      setTimeDifference(differenceInSeconds);
    }, 1000); // Update the timer every second (1000ms)

    return () => clearInterval(interval); // Cleanup the interval when the component is unmounted

  }, [setUtcTime]);

  
  const formatTime = (seconds) => {
    if (seconds <= 0) { return ''; }

    const hours = Math.floor(seconds / 3600);
    const minutes = Math.floor((seconds % 3600) / 60);
    const remainingSeconds = Math.floor(seconds % 60);
    return `${hours.toString().padStart(2, '0')}:${minutes.toString().padStart(2, '0')}:${remainingSeconds.toString().padStart(2, '0')}`;
  };

  return (
    <span className={spanClass}>{formatTime(timeDifference)}</span>
  );
};

export default CallTimer;