import { useEffect, useState } from "react";
import { apiPost } from "../../data/apidata";
import AQDataTable from "../AQDataTable/AQDataTable";
import { useSnackbar } from "notistack";
import { useUserSettings } from "../UserSettingsProvider/UserSettingsProvider";
import { columns_template_call_configuration } from "../AQDataTable/columns_templates";

const CallConfiguration = () => {

    const { enqueueSnackbar } = useSnackbar();

    // const [masterData, setMasterData] = useState(null);
    const [data, setData] = useState(null);
    const [site_id, setSite_id] = useState(null);

    const { userSettings, updateSettings } = useUserSettings();

    useEffect(() => {
        console.log(userSettings.site.id)
        setSite_id(userSettings.site.id)
        // getData(userSettings.site.id)
    }, [userSettings]);
    // useEffect(() => setData(masterData), [masterData]);
    useEffect(() => {
        getData()
    }, [site_id])
    function getData() {
        if (site_id !== null) {
            console.log('site_id', `${site_id}`)
            apiPost(
                'api/messages/CallConfig/list',
                { 'site_id': `${site_id}` },
                d => {
                    console.log(d['message']['data'][0][0])

                    const tmp = d['message']['data'][0][0]
                    let panels = [];
                    let devices = [];

                    if (tmp.panels !== null) {
                        panels = tmp.panels.map(item => {
                            return ({
                                panel_id: item.panel_id,
                                device_type: 'Panel',
                                unit_id: item.panel_ref,
                                unit_name: item.panel_name,
                                unit_zone: 'N/A'

                            })
                        })
                    }

                    if (tmp.devices !== null) {
                        devices = tmp.devices.map(item => {
                            return ({
                                device_id: item.device_id,
                                device_type: item.device_type,
                                unit_id: item.device_code,
                                unit_name: item.device_name,
                                unit_zone: item.device_zone,

                            })
                        })
                    }
                    setData([...panels, ...devices])
                }
            )
        }
    }

    return (
        <>
            <div className="flex-column form_container">
                <h4>Call Configuration</h4>

                {/* <div>{children}</div> */}
                <div className="usermanagement_user_roles_menu_container">

                    <div className="usermanagement_user_roles_menu">
                    </div>
                </div>

                <div className="usermanagement_list_container">
                    {
                        (data) === null ?
                            <div>Retrieving data...</div>
                            :
                            (data.length === 0) ?
                                <div>No records found for this time period.</div>
                                :

                                <AQDataTable
                                    siteID={site_id}
                                    data={Object.values(data)}
                                    columns={columns_template_call_configuration}
                                    settingsType={'call_config'}
                                    onUpdateRefreshPath={getData}
                                    dataRefresh={getData}
                                />
                    }
                </div>
            </div>
        </>

    );
}


export default CallConfiguration;