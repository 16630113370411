import { useState, useEffect } from "react";
import './Groups.css'
import AQDataTable from "../../../components/AQDataTable/AQDataTable";
import { apiPost } from "../../../data/apidata";
import { useUserSettings } from "../../../components/UserSettingsProvider/UserSettingsProvider";
import { columns_template_groups } from "../../../components/AQDataTable/columns_templates";
import { Card } from "@tremor/react";

export default function Groups() {

    const { userSettings, updateSettings } = useUserSettings();
    const [firstLoad, setFirstLoad] = useState(true);
    const [DeviceGroup_List, setDeviceGroupList] = useState([])
    const [masterData, setmasterData] = useState([])

    const siteID = getDBCode();

    function getDBCode() {
        try {
            return userSettings.site.id;
        }
        catch (e) { return "" }
    }

    function getNotifications(){
        if (firstLoad===true) setFirstLoad(false);
        if (siteID==="") return;  

        apiPost(
            'api/messages/site/devicegroups/list',
            {"siteID": siteID},
            (d)=>{
                setDeviceGroupList(d.message)
            },
            (e)=>{
                console.log('dvG_list_err: ', e)
            }
        )
    }

    function getGroupDevices(siteID, groupID) {
        return new Promise((resolve, reject) => {
            apiPost(
                'api/messages/site/devicegroups/group/devices',
                {
                    "siteID": siteID,
                    "deviceGroupID": groupID
                },
                (d) => {
                    resolve(d.message);
                },
                (e) => {
                    console.log('dvG_list_err: ', e);
                    reject(e);
                }
            );
        });
    }

    useEffect(() => {
        const fetchData = async () => {
            try {
                const tmp = await Promise.all(DeviceGroup_List.map(async (item) => {
                    const groupDevices = await getGroupDevices(item.siteID, item.deviceGroupID);
                    const filteredDeviceNames = groupDevices.filter(device=>device.deviceName).map(device=>device.deviceName).join(', ');
                    return {
                        ...item,
                        groupDevices: groupDevices == 0 ? '' : filteredDeviceNames
                    };
                }));
                setmasterData(tmp);
            } catch (error) {
                console.error('Error fetching group devices:', error);
            }
        };
    
        fetchData();
    }, [DeviceGroup_List]);

    useEffect(() => {
        const intervalId = setInterval(getNotifications, 60*1000);
    
        return () => clearInterval(intervalId);
      }, [firstLoad]);


    useEffect(() => { getNotifications(); }, [firstLoad === true]);

    return (
        <Card className="height-full">
            <h4>Groups</h4>
            <br />
            <AQDataTable
                data = {masterData}
                columns= {columns_template_groups}
                settingsType={'settings_groups'}
                onUpdateRefreshPath={''}
                siteID={siteID}
                dataRefresh={getNotifications}>
            </AQDataTable>
        </Card>
    );
};