import { useEffect, useState } from "react";
import { getOnceValue } from "../../../data/firebase";
import "./Devices.css";
import AQDataTable from "../../../components/AQDataTable/AQDataTable";


const TableTemplateFirebase = ({title, db, dbRef, tableTemplate, tableSettingsType})=> {
    
    const [masterData, setMasterData] = useState(null);
    const [data, setData] = useState(null);
    const [error, setError] = useState("");
    const [searchVal, setSearchVal] = useState("");

    function getData(){
        if ((dbRef===undefined)||(dbRef===null)||(dbRef==="")) return;
        getOnceValue(dbRef, (d)=>setMasterData(d), (e)=>setError(e));
    }
    

    useEffect(()=>{
        if (searchVal==="") setData(masterData);
        if (masterData===null) return;
        if (searchVal==="") setData(masterData);
        const tmp = masterData.filter(obj => (obj.unitId.includes(searchVal))||(obj.unitName.includes(searchVal))||(obj.unitZone.includes(searchVal))||((obj.groupid)?(obj.groupid.includes(searchVal)):""));
        setData(tmp);
    },[searchVal]);

    function convertToArray(arr){
        return (Object.values(arr));
        if (Array.isArray(arr)) return arr;
        if (typeof arr === 'object' && arr !== null) return Object.values(arr); 
        return [];
    }

    useEffect(()=>getData(),[(data==null)]);
    useEffect(()=>setData(masterData), [masterData]);
    
    if ((tableSettingsType===undefined)||(tableSettingsType===null)) return (<div>No table settings type found.</div>);
    
    return (
        <>
            <div className="flex-column form_container">
                <h4>{title}</h4>
                <div className="usermanagement_user_roles_menu_container">
            
                    <div className="usermanagement_user_roles_menu">
                    </div>
         </div>

                <div className="usermanagement_list_container">
                    {
                    (data)===null?
                    <div>Retrieving data...</div>
                    :
                    <AQDataTable 
                        data={convertToArray(data)} 
                        columns={tableTemplate} 
                        settingsType={tableSettingsType}
                    />
                    }
                </div>
            </div>
        </>
    );
}


export default TableTemplateFirebase;