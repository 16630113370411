import { useState } from "react"
import { EditCallConfig } from "../CallConfig/EditCallConfig";
import { SenseConfig } from "../Sense/SenseConfig";
import { columns_template_reporting_calls } from "../AQDataTable/columns_templates";
import { EditSenseConfig } from "../Sense/EditSenseConfig";


export const SiteConfigSelector = ({ option, setModal, siteID, siteList, onClose }) => {
    const [page, setPage] = useState(0)
    function setSelection(e) {
        console.log(e.target.value)
        if (e.target.value === 'Call')
            setPage(1)
        else if (e.target.value === 'Sense')
            setPage(2)
        else if (e.target.value === 'Move')
            setPage(3)
    }
    function saveData(e) {
        console.log(option)
        if (page === 1)
            if (option === 'edit')
                setModal(<EditCallConfig siteID={siteID} siteList={siteList} onClose={onClose} />);
            else return null;
        else if (page === 2)
            if (option === 'create')
                setModal(<SenseConfig siteID={siteID} onClose={onClose} />);

            else if (option === 'edit')
                setModal(<EditSenseConfig siteID={siteID} onClose={onClose} />);

    }
    return (
        <div className='new_user_page_container'>
            {option === 'create' ?
                <h1 style={{ marginBottom: '0px' }}>Setup Services</h1>
                :
                <h1 style={{ marginBottom: '0px' }}>Edit Services</h1>
            }
            <div className='aqForm'>
                <div className='aq_fields'>
                    <h4 style={{ marginTop: 0 }}>Service configuration</h4>
                    <select onChange={setSelection}
                        className="config_input shadow  border rounded  py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline">

                        <option selected hidden>Select service</option>
                        {option === 'edit' &&
                            <option>Call</option>
                        }
                        <option >Sense</option>
                        <option >Move</option>
                    </select>
                </div>

                <div className='validate_close_div' style={{ alignSelf: 'flex-end' }}>
                    <button className='validate_button btn_size' style={{ marginRight: '2px' }} onClick={saveData}>Next</button>

                    <button className='close_buton btn_size' onClick={onClose}>Close</button>
                </div>
            </div>
        </div>
    )
}