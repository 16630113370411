import { useEffect, useState } from "react";
import { FormInput } from "../Input";
import "./SitePage.css";
import { apiPost } from "../../data/apidata";
import { displayDateFromUTC } from "../../functions/dataHandler";
import IosShareIcon from '@mui/icons-material/IosShare';
import { Grid, IconButton } from "@mui/material";
import { utcDateToYYYYMMDD } from "../AQDataTable/columns_templates";

const SiteDetails = ({ id, onClose }) => {
    const [details, setDetails] = useState(null);
    console.log('site iiiiii', id)
    useEffect(() => {
        apiPost(
            "api/messages/site/management/GetSiteConfig",
            {
                "site_id": `${id}`,
            },
            (d) => {
                console.log('SiteDetails ', d['message']['data'][0][0]);
                setDetails(d['message']['data'][0][0])

            },
            (e) => {
                console.log("Api error for site", e);
                //setError("Error");
            })
    }, [id]);

    function DownloadToCSV() {
        let content =
            `Site ID,${details.site_id} 
Site ref,${details.site_code}
Site name,${details.site_name}
Site Address,${details.site_address},${details.site_postcode}
Site manager,${details.mn_name},${details.mn_email},${details.mn_phone}
Site maintenance,${details.mt_name},${details.mt_email},${details.mt_phone}
Site director,${details.dr_name.trim() === '' ? 'N/A' : details.dr_name},${details.dr_email.trim() === '' ? 'N/A' : details.dr_email},${details.dr_phone.trim() === '' ? 'N/A' : details.dr_phone}
Arquella Services,${addComma(details.services)}
Third party integrations,${addComma(details.integrations)},
Creation date, ${utcDateToYYYYMMDD(details.creation_date)}
 `

        const blob = new Blob([content], { type: "text/csv" });
        const url = window.URL.createObjectURL(blob);
        const link = document.createElement("a");
        link.href = url;
        link.download = `${details.site_name} details.csv`;
        link.click();
    }

    function addComma(td) {
        let res = 'N/A'
        if (td === null || td === undefined) return res
        if (td !== null && td.length > 0)
            res = ''
        td.map((item, i) => {
            res += item.name
            if (i !== td.length - 1)
                res += ','

        })

        return res
    }
    return (
        <div className="user_detail_container aqForm" style={{ height: "460px" }}>


            <Grid container style={{ marginBottom: '0px' }}>
                <Grid item xs={10} sm={10} md={10} lg={10}>
                    <h1>Site Details</h1>
                </Grid>
                <Grid style={{ display: 'flex', alignItems: 'center' }} item xs={2} sm={2} md={2} lg={2}>
                    <button style={{ alignSelf: 'center' }} onClick={DownloadToCSV}>
                        <IosShareIcon style={{ width: '30px', height: '30px' }} />
                    </button>
                </Grid>
            </Grid>
            <br />

            <div className="aq_fields">
                {details != null &&
                    <Grid container spacing={1}>
                        {/* Line */}
                        <Grid item xs={6} sm={6} md={6} lg={6}>
                            <div>
                                <div><b>Site ID</b></div>
                                <div>{details.site_id}</div>
                            </div>
                        </Grid>
                        <Grid item xs={6} sm={6} md={6} lg={6}>
                            <div>
                                <div><b>Site Ref</b></div>
                                <div>{details.site_code}</div>
                            </div>
                        </Grid>
                        {/* Line */}
                        <Grid item xs={6} sm={6} md={6} lg={6}>
                            <div>
                                <div><b>Site name</b></div>
                                <div>{details.site_name}</div>
                            </div>
                        </Grid>
                        {/* Line */}
                        <Grid item xs={12} sm={12} md={12} lg={12}>
                            <div>
                                <div><b>Address</b></div>
                                <div>{details.site_address}, {details.site_postcode}</div>
                            </div>
                        </Grid>

                        {/* Line */}
                        <Grid item xs={6} sm={6} md={6} lg={6}>
                            <div>
                                <div><b>Site manager</b></div>
                                <div>{details.mn_name}</div>
                                <div>{details.mn_email}</div>
                                <div>{details.mn_phone}</div>
                            </div>
                        </Grid>
                        <Grid item xs={6} sm={6} md={6} lg={6}>
                            <div>
                                <div><b>Site maintenance</b></div>
                                <div>{details.mt_name}</div>
                                <div>{details.mt_email}</div>
                                <div>{details.mt_phone}</div>
                            </div>
                        </Grid>
                        <Grid item xs={6} sm={6} md={6} lg={6}>
                            <div>
                                <div><b>Site director</b></div>
                                <div>{details.dr_name.trim() === '' ? 'N/A' : details.dr_name}</div>
                                <div>{details.dr_email.trim() === '' ? 'N/A' : details.dr_email}</div>
                                <div>{details.dr_phone.trim() === '' ? 'N/A' : details.dr_phone}</div>
                            </div>
                        </Grid>
                        {/* Line */}
                        <Grid item xs={12} sm={12} md={12} lg={12}>
                            <div>
                                <div><b>Arquella services</b></div>
                                <div>
                                    {addComma(details.services)}
                                </div>
                            </div>
                        </Grid>
                        <Grid item xs={12} sm={12} md={12} lg={12}>
                            <div>
                                <div><b>Third party integrations</b></div>
                                <div>
                                    {addComma(details.integrations)}
                                </div>
                            </div>
                        </Grid>
                        <Grid item xs={12} sm={12} md={12} lg={12}>
                            <div>
                                <div><b>Creation date</b></div>
                                <div>
                                    {utcDateToYYYYMMDD(details.creation_date)}
                                </div>
                            </div>
                        </Grid>
                    </Grid>
                }
                <div className='submit_fields'>
                    <div className='validate_close_div'>
                        <button className='close_buton' onClick={onClose}>Close</button>
                    </div>
                </div>
            </div>

        </div>
    );
}

export { SiteDetails };