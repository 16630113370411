import React, { useEffect } from "react";
import { NavBarBrand } from "./nav-bar-brand";
import { NavBarButtons } from "./nav-bar-buttons";
import { NavBarTabs } from "./nav-bar-tabs";
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

export const NavBar = ({user}) => {

  useEffect(() => {
    // Check if the user has seen the banner message before
    const hasSeenBanner = localStorage.getItem(`${user}hasSeenBannerdeviceList`);

    if (!hasSeenBanner) {
      // Display the banner message
      // Dismiss any existing toasts to ensure only one toast message at a time
      toast.dismiss();

      toast.info("We have moved device list into the call section under Configurations.", {
        position: "top-center",
        autoClose: false, // Time in milliseconds the toast will remain visible
        hideProgressBar: true,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        onClose: () => {
          console.log("Toast closed");
          localStorage.setItem(`${user}hasSeenBannerdeviceList`, 'true');
        },
        // Hide the icon
        icon: false,
        style: { backgroundColor: 'rgb(76, 193, 189)', color: "white" }
      });
      
      // Set a flag in localStorage so this message won't appear again
    }
  }, [user]); // Empty dependency array ensures this only runs once


  return (
    <div className="nav-bar__container">
      <ToastContainer/>
      <nav className="nav-bar">
        <NavBarBrand />
        <NavBarTabs />
        <NavBarButtons />
      </nav>
    </div>
  );
};
