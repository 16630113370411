import { useEffect, useState } from "react";
import { FormInput } from "../Input";
import "./UserDetails.css";
import { apiPost } from "../../data/apidata";
import { displayDateFromUTC } from "../../functions/dataHandler";

import {Grid} from "@mui/material";

const UserDetails=({id, details, onClose})=>{
    const [usersitelist, setUsersiteList] = useState([]);
    
    console.log("USER DETAILS", details);
     useEffect(()=>{
            apiPost(
                "api/messages/site/users/usersiteslist",
                {
                    "email":details.email
                },
                (d)=>{ 
                    console.log('UserSite list ',d['message']['data'][0][0]);
                    setUsersiteList( d['message']['data'][0][0])
                    
                },
                (e)=>{
                    console.log("Api error for usersitelist", e);
                    //setError("Error");
                })
        },[]);
        
        function addComma(i){
            if(i!==usersitelist.length-1)
                return ","
        }
    return(
        <div className="user_detail_container aqForm" style={{height:"460px"}}>
            
            <h1>User Details</h1>
            <p></p>
            <br />


{/*
id
siteID
siteName
*/}

            <div className="aq_fields">
                 <Grid container spacing={1}>
                    <Grid item  xs={4} sm={4} md={4} lg={4}>
                    <div>
                    <div><b>Title</b></div>
                    <div>{details.title}</div>
                </div>
                    </Grid>
                    <Grid item  xs={8} sm={8} md={8} lg={8}>
                        <div>
                    <div><b>Name</b></div>
                    <div>{details.firstName} {details.surname}</div>
                </div>
                    </Grid>
                    <Grid item xs={4} sm={4} md={4} lg={4}>
                         <div>
                    <div><b>Role</b></div>
                    <div>{details.role}</div>
                </div>
                    </Grid>

                     <Grid item xs={8} sm={8} md={8} lg={8}>
                         <div>
                    <div><b>Email</b></div>
                    <div>{details.email}</div>
                </div>
                    </Grid>
                    
                <br/>
                    <Grid item xs={12} sm={12} md={12} lg={12}>
                         <div>
                    <div>
                    <div><b>Assigned Sites</b></div>
                    <div> 
                    {
                        usersitelist.map((site,i)=>
                          
                            <span>{site.siteName}{addComma(i)} </span>
                    )}
                    </div>
                </div>
                </div>
                    </Grid>

                    
                    </Grid>
                
               
                <br/>
                <div>
                    <div><b>Date created</b></div>
                    <div> 
                    {displayDateFromUTC(details.created_date)}
                    </div>
                </div>
                <div className='submit_fields'>
                    <div className='validate_close_div'>
                        <button className='close_buton' onClick={onClose}>Close</button>
                    </div>
                </div>
            </div>
        </div>
    );
}

const SiteForm=({siteVal, userID})=>{
    const [error, setError] = useState("");
    const [site, setSite] = useState((siteVal===null)?"":siteVal);
    
    

    const removeSite = (siteID, userID) => {
        console.log("ussssssrrID", userID);
        apiPost(
            "api/messages/user/site/remove",
            {
                "userID" : userID,
                "siteID" : siteID
            },
            (d)=>{ 
                setSite("");
                console.log(d); 
            },
            (e)=>{ setError(e) },
        )

    };

    console.log("sssssite", site);
    
    return(
        <div>
            {
            (site==="")
            ?
            <AddSite userID={userID} onChange={setSite} onError={setError} />
            :
            <div className="flex-row">
                <div>{site}</div>
                <button onClick={()=>{
                    removeSite(siteVal, userID);
                }} className="button_1">&nbsp;x&nbsp;</button>
            </div>
            }
        </div>
        
    );
    
}

const AddSite=({userID, onChange, onError})=>{

    const [sitesList, setSitesList] = useState([]);
    const [selectedSite, setSelectedSite] = useState("");

    useEffect(()=>{

        apiPost(
            "api/messages/user/site/listAll",
            {
                "userID" : userID,
                "siteID" : "pass1"
            },
            (d)=>{ 
                const data = d.message.data;
                console.log(data);
                setSitesList(data);
            },
            (e)=>{ onError(e) },
        )

    }, [sitesList.length===0])

    const addSite = () => {
        console.log("addingSite");
        apiPost(
            "api/messages/user/site/add",
            {
                "userID" : `${userID}`,
                "siteID" : `${selectedSite}`
            },
            (d)=>{ 
                try {
                    console.log(sitesList);
                    console.log(selectedSite);
                    console.log(sitesList.filter(s=>(s.id===parseInt(selectedSite))));
                    const val = sitesList.filter(s=>(s.id===parseInt(selectedSite)))[0].name;
                    onChange(val);
                    console.log(selectedSite); 
                }
                catch(e){
                    console.log(e);
                }
            },
            (e)=>{ 
                console.log("error", e);
                //onError(e) 
            },
        )

    };
    
    function setSelected(e){
        setSelectedSite(e.target.value);
    }

    return(
            <div>
                <select 
                    name={"siteID"} 
                    className='aqselect'
                    onChange={setSelected}
                >
                    <option selected={(selectedSite==="")} value=""></option>
                    {
                    sitesList.map(s=><option selected={(selectedSite===s.id)} value={s.id}>{s.name}</option>)
                    }
                    
                </select>
                <button
                    disabled={selectedSite===""}
                    onClick={()=>addSite()}
                >Add</button>
            </div>
        );
    
}

export default UserDetails;