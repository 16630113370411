import { useEffect, useState } from "react";
import { apiPost } from "../../../../data/apidata";
import CalendarHeatmap from 'react-calendar-heatmap';
import 'react-calendar-heatmap/dist/styles.css';

export const GetSite=({siteID})=>{

    
    return(
        <div>
            Site {siteID}
            <SiteHeatMap siteID={siteID} />
        </div>
    );
}


const SiteHeatMap=({siteID})=>{

    const [data, setData] = useState([]);
    const [dateValues, setDateValues] = useState([]);
    const [display, setDisplay] = useState("");

    useEffect(()=>{
        const tmp = [];
        data.forEach(d=>{
            tmp.push({ date: `${d.year_val}-${d.month_val}-${d.day_val}`, count: d.total })
        });
        setDateValues(tmp);
    }, [data]);

    useEffect(()=>{
        
        if (siteID===undefined)return;
        if (siteID===null)return;

        apiPost("/api/messages/user/site/dailytotals", {"siteID": siteID}
            ,(d)=>{ try{setData(d.message.data);} catch(e){console.log(e)} }
            ,(e)=>{ console.log(e); }
        );

    },[siteID])

    const today = new Date();

    return(
        <div style={{width:"80%"}}>
            <h3>{display}</h3>
            <CalendarHeatmap
                startDate={new Date('2023-06-01')}
                endDate={new Date(`${today.getFullYear()}-${(today.getMonth()+1)}-${today.getDate()}`)}
                values={dateValues}
                showMonthLabels={true}
                showWeekdayLabels={true}
                horizontal={true}
                onMouseOver={(event, value)=>{
                    try {setDisplay(`${value.date} : ${value.count} calls`)}
                    catch(e){setDisplay(`No Data`)}
                }}
                onClick={(ev, val)=>{
                    console.log(ev);
                    console.log(val);
                }} 
            />
        </div>
    );
}