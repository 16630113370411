import React, { useEffect, useState } from 'react';
import { FormInput } from '../Input';
import './SitePage.css';
import { apiPost } from '../../data/apidata';
import { useSnackbar } from 'notistack';
import { SystemUpdateAltSharp as Download, Backup, Add } from '@mui/icons-material';
import { Checkbox } from '@mui/material';
import { v4 as uuidv4 } from 'uuid';
import PropTypes from 'prop-types';
import { SiteRoomConfig } from './SiteRoomConfig';
import * as XLSX from 'xlsx';
import SiteConfigTemplate from './SiteConfigTemplate.xlsx';
const NewSitePage = ({ siteList, onClose }) => {

    const { enqueueSnackbar } = useSnackbar();
    const [currStep, setCurrStep] = useState(1);
    const [zones, setZones] = useState([]);
    const [devices, setDevices] = useState([]);
    const [services, setServices] = useState([])
    const [integrations, setIntegrations] = useState([])
    const [panel_num, setPanel_num] = useState(0)
    const [finalConfig, setFinalConfig] = useState();
    const [formData, setFormData] = useState(
        {
            site_name: '',
            site_id: '',
            site_code: '',
            site_address: '',
            site_postcode: '',

            mn_name: '',
            mn_email: '',
            mn_phone: '',

            mt_name: '',
            mt_email: '',
            mt_phone: '',

            dr_name: '',
            dr_email: '',
            dr_phone: '',
        }
    )
    const [formValidation, setValidation] = useState(
        {
            site_name: false,
            site_address: false,
            site_postcode: false,

            mn_name: false,
            mn_email: false,
            mn_phone: false,

            mt_name: false,
            mt_email: false,
            mt_phone: false,

            dr_name: false,
            dr_email: false,
            dr_phone: false,
        }
    )
    // useEffect(() => {
    //     console.log(formData);

    // }, [formData])

    return (
        <>
            {currStep === 1 && (
                <Step1 formData={formData} setFormData={setFormData} formValidation={formValidation} setValidation={setValidation} setCurrStep={setCurrStep} siteList={siteList} onClose={onClose} />
            )}
            {currStep === 2 && (
                <Step2 formData={formData} setFormData={setFormData} formValidation={formValidation} setValidation={setValidation} setCurrStep={setCurrStep} siteList={siteList} onClose={onClose} />
            )}
            {currStep === 3 && (
                <Step3 setFinalConfig={setFinalConfig} formData={formData} setFormData={setFormData} formValidation={formValidation} setValidation={setValidation}
                    setCurrStep={setCurrStep} siteList={siteList} onClose={onClose}
                    zones={zones} setZones={setZones} devices={devices} setDevices={setDevices} services={services}
                    setServices={setServices} integrations={integrations} setIntegrations={setIntegrations}
                    panel_num={panel_num} setPanel_num={setPanel_num} />
            )}
            {currStep === 4 && (
                <SiteRoomConfig config={finalConfig} onClose={onClose} currStep={currStep} setCurrStep={setCurrStep} />
            )}
        </>
    );
};


export default NewSitePage;

const Step1 = ({ formData, setFormData, formValidation, setValidation, setCurrStep, siteList, onClose }) => {
    const [site_name_error, setSite_name_error] = useState('');
    const [page_error, setPage_error] = useState('');
    function handleChange(e) {
        setFormData((data) => ({
            ...data,
            [e.target.name]: e.target.value,
        })
        )
    }
    function checkSiteName(e) {
        handleChange(e)

        if (e.target.value !== '') {
            const code = e.target.value.toLowerCase().replaceAll(/ /g, '')
            setFormData((data) => ({
                ...data,
                site_code: code,
            }))

            if (!siteList.some(item => item.site_name === e.target.value)
                && !siteList.some(item => item.site_code === code)) {
                setSite_name_error('');

            }
            else {
                setSite_name_error('Site name or ref already exists');
            }
        }
        else {
            setSite_name_error('This field is required');
        }
    }

    function saveData(e) {
        if (site_name_error === '' && formValidation.site_address && formValidation.site_postcode) {
            if (formData.site_name !== '' && formData.site_address !== '' && formData.site_postcode !== '') {
                return setCurrStep(2);

            }
        }
        return setPage_error('Make sure all required fields are filled')

    }


    return (
        <div className='new_user_page_container'>
            <h1 style={{ marginBottom: '0px' }}>New Site</h1>
            <span className='formFieldError'>{page_error}</span>
            <br />
            <div className='aqForm'>
                <div className='aq_fields'>
                    <h4 style={{ marginTop: 0 }}>Step 1/4: Fill in site information</h4>

                    <label>
                        Site name <span className='red_star'>*</span>:
                        <FormInput
                            type="text"
                            name="site_name"
                            value={formData.site_name}
                            onChange={checkSiteName}
                        />
                        <span className='formFieldError'>{site_name_error}</span>
                    </label>
                    <br />
                    <label>
                        Site Ref <span className='red_star'>*</span>:
                        <input
                            className=" readonly_input shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                            type="text"
                            name="site_code"
                            readOnly={true}
                            value={formData.site_code}
                        />
                    </label>
                    <br />
                    <RequiredField Title='Address' name='site_address' value={formData.site_address} setFormData={setFormData} setValidation={setValidation} />
                    <RequiredField Title='Postcode' name='site_postcode' value={formData.site_postcode} setFormData={setFormData} setValidation={setValidation} />



                </div>
                <div className='submit_fields'>
                    <div className='validate_close_div'>
                        <button className='validate_button action_button' onClick={saveData}>Next</button>
                        <button className='close_buton action_button' onClick={onClose}>Close</button>
                    </div>
                </div>

            </div>
        </div>
    )
}


const Step2 = ({ formData, setFormData, formValidation, setValidation, setCurrStep, siteList, onClose }) => {
    const [page_error, setPage_error] = useState('');

    function saveData(e) {
        if (formValidation.mn_email && formValidation.mn_email && formValidation.mn_phone)
            if (formData.mn_email !== '' && formData.mn_email !== '' && formData.mn_phone !== '') {
                // apiPost(
                //     'api/messages/site/management/addNewSite',
                //     formData,
                //     (d) => {
                //         console.log('site id', d['message']['data'][0][0]);
                //         console.log('new id', d['message']['data'][0][0].site_id)
                //         setFormData((data) => ({
                //             ...data,
                //             site_id: d['message']['data'][0][0].site_id,
                //         }))
                //         setCurrStep(3);
                //     },
                //     (e) => {
                //         console.log(e);
                //     }
                // )
                return setCurrStep(3);
            }
        return setPage_error('Make sure all required fields are filled')

    }
    function goBack(e) {
        setCurrStep(1);
    }
    return (
        <div className='new_user_page_container'>
            <h1 style={{ marginBottom: '0px' }}>New Site</h1>
            <span className='formFieldError'>{page_error}</span>
            <br />
            <div className='aqForm'>
                <div className='aq_fields'>
                    <h4 style={{ marginTop: 0 }}>Step 2/4: Fill in contact information</h4>
                    {/* Manager info */}
                    <h5 style={{ marginTop: 0 }}>Manager contact information</h5>
                    <RequiredField Title='Name' name='mn_name' value={formData.mn_name} setFormData={setFormData} setValidation={setValidation} />
                    <RequiredField Title='Email' name='mn_email' value={formData.mn_email} setFormData={setFormData} setValidation={setValidation} />
                    <RequiredField Title='Phone Number' name='mn_phone' value={formData.mn_phone} setFormData={setFormData} setValidation={setValidation} />

                    {/* Maintenance info */}
                    <br />
                    <h5 style={{ marginTop: 0 }}>Maintenance contact information</h5>
                    <RequiredField Title='Name' name='mt_name' value={formData.mt_name} setFormData={setFormData} setValidation={setValidation} />
                    <RequiredField Title='Email' name='mt_email' value={formData.mt_email} setFormData={setFormData} setValidation={setValidation} />
                    <RequiredField Title='Phone Number' name='mt_phone' value={formData.mt_phone} setFormData={setFormData} setValidation={setValidation} />

                    {/* Director information */}
                    <br />
                    <h5 style={{ marginTop: 0 }}>Director contact information</h5>
                    <label>
                        Name:
                        <FormInput type="text" name="dr_name" value={formData.dr_name}
                            onChange={(e) => setFormData((data) => ({ ...data, dr_name: e.target.value, }))} />
                    </label>
                    <br />
                    <label>
                        Email:
                        <FormInput type="text" name="dr_email" value={formData.dr_email}
                            onChange={(e) => setFormData((data) => ({ ...data, dr_email: e.target.value, }))} />
                    </label>
                    <br />
                    <label>
                        Phone Number:
                        <FormInput type="text" name="dr_phone" value={formData.dr_phone}
                            onChange={(e) => setFormData((data) => ({ ...data, dr_phone: e.target.value, }))} />
                    </label>
                    <br />
                </div>
                <div className='submit_fields'>
                    <div className='validate_close_div'>
                        <button className='validate_button action_button' onClick={goBack}>Previous</button>

                        <button className='validate_button action_button' onClick={saveData}>Next</button>
                        <button className='close_buton action_button' onClick={onClose}>Close</button>
                    </div>
                </div>

            </div>
        </div>
    )
}

const Step3 = ({ setFinalConfig, formData, setFormData,
    formValidation, setValidation, setCurrStep, siteList, onClose,
    zones, setZones, devices, setDevices, services, setServices,
    integrations, setIntegrations, panel_num, setPanel_num
}) => {
    const [page_error, setPage_error] = useState('')
    const [zone_name, setZone_name] = useState('')
    const [deviceNum, setDeviceNum] = useState('')
    const [selectedDevice, setSelectedDevice] = useState({});
    const [deviceList, setDeviceList] = useState();
    const [autoGenerate, setAutoGenerate] = useState(true);
    const [numberError, setNumberError] = useState(false);
    const [excelDevices, setExcelDevices] = useState([]);
    const [serviceList, setServiceList] = useState(['Sense', 'Move', 'Call'])
    const [integrationList, setIntegrationList] = useState(['PCS', 'Nourish', 'Care vision'])
    const serviceSelector = React.useRef();
    const integrationSelector = React.useRef();
    const downloadLinkRef = React.useRef();

    function roomUnitGenerator(start, num, name, type, numStart) {
        console.log(start, num)
        const end = parseInt(num, 10) + start
        const tmp = [];
        for (var i = start; i < end; i++) {
            tmp.push(i);
        }
        let j = 0
        if (numStart != null)
            j = numStart;
        return tmp.map((item, i) => {
            j += 1;
            return ({
                unit_code: item.toString().padStart(3, '0'),
                device_name: `${name} ${j}`,
                device_type: type,
                isSelected: false,
                unit_zone: ''
            })
        });
    }
    function panelGenerator(num) {

        const tmp = Array.from({ length: num }, (_, i) => i);
        let j = 0;
        return tmp.map(item => {
            j += 1;
            let name = ''
            if (j === 1)
                name = 'Main Panel'
            else
                name = `Panel ${j}`
            return {
                device_name: name,
                device_type: 'Panel',
                unit_zone: '',
                isSelected: false,
            }
        })

    }


    useEffect(() => {
        apiPost('api/messages/site/management/GetDeviceTypeList',
            '',
            (d) => {
                console.log(d['message']['data'][0])
                if (d['message']['data'][0].length > 0) {
                    setDeviceList(d['message']['data'][0])
                    // setSelectedDevice(d['message']['data'][0][0])
                }
                console.log(panelGenerator(10))
            },
            (e) => {
                console.log(e)
            }
        )
    }, [])

    function addZone() {
        if (zone_name !== '') {
            let check = zones.some(item => item === zone_name);
            if (!check) {
                setZones((data) => [...data, zone_name]);
                setZone_name('')
            }
        }
    }

    function addDevice(e) {
        console.log('device to add', selectedDevice)
        if (selectedDevice.id !== null && selectedDevice.id !== undefined)
            if (selectedDevice !== '' && deviceNum !== '') {
                if (!numberError) {
                    console.log(devices.some(item => item.id === selectedDevice.id))
                    if (devices.some(item => item.id === selectedDevice.id)) {
                        const update = devices.map(item => {
                            if (item.id === selectedDevice.id) {
                                let num = Number(item.device_num) + Number(deviceNum);
                                item.device_num = num;
                                item.name = `${num} ${selectedDevice.name}`;
                                item.device_name = selectedDevice.name
                            }
                            return item;
                        })
                        console.log(devices)
                        setDevices(update)
                    }
                    else {
                        const tmp = {
                            ...selectedDevice,
                            device_num: deviceNum,
                            name: `${deviceNum} ${selectedDevice.name}`,
                            device_name: selectedDevice.name
                        }
                        console.log('device added', tmp)
                        setDevices((data) => ([
                            ...data,
                            tmp,
                        ])
                        )
                    }
                    setDeviceNum('')
                }
            }
    }

    function setRoomConfig() {
        let room_units = []
        const b_unit = devices.filter(item => item.device_name.toLowerCase() === 'bedroom unit')
        const e_unit = devices.filter(item => item.device_name.toLowerCase() === 'ensuite unit')
        const c_unit = devices.filter(item => item.device_name.toLowerCase() === 'communal unit')

        console.log(b_unit)
        if (b_unit.length > 0) {

            const rest = parseInt(b_unit[0].device_num) - 99;

            console.log('rest', rest)
            const tmp = (roomUnitGenerator(1, rest > 0 ? 99 : b_unit[0].device_num, 'Bedroom', 'Bedroom unit'))
            tmp.map(room => {
                room_units.push(room)
            })
            if (rest > 0) {
                const tmp2 = (roomUnitGenerator(700, rest, 'Bedroom', 'Bedroom unit', 99))
                tmp2.map(room => {

                    room_units.push(room)
                })
            }

        }

        if (e_unit.length > 0) {
            const tmp = (roomUnitGenerator(101, e_unit[0].device_num, 'Ensuite', 'Ensuite unit'))
            tmp.map(room => {
                room_units.push(room)
            })
        }
        if (c_unit.length > 0) {
            const tmp = (roomUnitGenerator(201, c_unit[0].device_num, 'Communal', 'Communal unit'))
            tmp.map(room => {
                room_units.push(room)
            })
        }
        return room_units
    }
    function setAccessoriesConfig() {
        // Motion point, sensor matt unit, ceilling pull cord, door contact
        let start = 401
        let acc_units = []
        const motion_unit = devices.filter(item => item.device_name.toLowerCase() === 'motion point')
        const matt_unit = devices.filter(item => item.device_name.toLowerCase() === 'sensor matt unit')
        const cord_unit = devices.filter(item => item.device_name.toLowerCase() === 'ceiling pull cord')
        const door_unit = devices.filter(item => item.device_name.toLowerCase() === 'door contact')
        if (motion_unit.length > 0) {
            // console.log('sssss', b_unit)
            const tmp = (roomUnitGenerator(start, motion_unit[0].device_num, 'Motion point', 'Motion point'))
            tmp.map(room => {
                acc_units.push(room)
            })
            start += parseInt(motion_unit[0].device_num)
        }
        if (matt_unit.length > 0) {
            // console.log('sssss', b_unit)
            const tmp = (roomUnitGenerator(start, matt_unit[0].device_num, 'Sensor matt unit', 'Sensor matt unit'))
            tmp.map(room => {
                acc_units.push(room)
            })
            start += parseInt(matt_unit[0].device_num)
        }
        if (cord_unit.length > 0) {
            // console.log('sssss', b_unit)
            const tmp = (roomUnitGenerator(start, cord_unit[0].device_num, 'Ceiling pull cord', 'Ceiling pull cord'))
            tmp.map(room => {
                acc_units.push(room)
            })
            start += parseInt(cord_unit[0].device_num)
        }

        if (door_unit.length > 0) {
            // console.log('sssss', b_unit)
            const tmp = (roomUnitGenerator(start, door_unit[0].device_num, 'Door contact', 'Door contact'))
            tmp.map(room => {
                acc_units.push(room)
            })
            start += parseInt(door_unit[0].device_num)
        }
        return (acc_units)
    }
    function setDoorbellConfig() {
        let start = 501
        let door_units = []
        const d_unit = devices.filter(item => item.device_name.toLowerCase() === 'doorbell')

        if (d_unit.length > 0) {
            const tmp = (roomUnitGenerator(start, d_unit[0].device_num, 'Doorbell', 'Doorbell'))
            tmp.map(room => {
                door_units.push(room)
            })
        }
        return (door_units)
    }
    function setPendantConfig() {
        let start = 601
        let pendant_units = []
        const d_unit = devices.filter(item => item.device_name.toLowerCase() === 'pendant')

        if (d_unit.length > 0) {
            const tmp = (roomUnitGenerator(start, d_unit[0].device_num, 'Pendant', 'Pendant'))
            tmp.map(room => {
                pendant_units.push(room)
            })
        }
        return (pendant_units)
    }
    function saveData(e) {


        if (formValidation.mn_email && formValidation.mn_email && formValidation.mn_phone)
            if (formData.mn_email !== '' && formData.mn_email !== '' && formData.mn_phone !== '') {
                let config;
                if (autoGenerate) {
                    config = {
                        'formData': formData,
                        // 'site_id': formData.site_id,
                        'site_code': formData.site_code,
                        'zones': zones,
                        'services': services,
                        'integrations': integrations,
                        'panels': panelGenerator(panel_num),
                        'devices': [...setRoomConfig(), ...setAccessoriesConfig(), ...setPendantConfig(), ...setDoorbellConfig()],
                        'device_types': deviceList,
                    };
                }
                else {
                    config = {
                        'formData': formData,
                        'site_code': formData.site_code,
                        'zones': zones,
                        'services': services,
                        'integrations': integrations,
                        'panels': panelGenerator(panel_num),
                        'devices': excelDevices,
                        'device_types': deviceList,
                    };
                }
                if (config.zones.length > 0 && config.panels.length > 0 && config.devices.length > 0) {
                    setFinalConfig(config);
                    return setCurrStep(4)
                }
                // console.log(config)

            }
        return setPage_error('Make sure all required fields are filled')

    }
    function goBack(e) {
        setCurrStep(2);
        // }
    }

    function handleAutogen(val) {
        // console.log(e.target)
        setAutoGenerate(val)
    }
    function handleDeviceChange(e) {
        // device tag config
        // {
        //     device_id:'',
        //     device_name:'',
        //     device_num:'',
        //     name:'',
        // }
        const tmp = deviceList.filter(item => item.name === e.target.value)
        if (tmp != null) {
            // console.log('device selected', tmp);

            setSelectedDevice(tmp[0]);
        }
        else
            setSelectedDevice({})
    }
    function handleNumberChange(e) {
        setDeviceNum(e.target.value)
        if (!isNaN(e.target.value))
            setNumberError(false)
        else
            setNumberError(true)
    }
    function handleArquellaServices() {
        console.log(serviceSelector.current)
        if (serviceSelector.current.value !== '')
            if (!services.some(item => item === serviceSelector.current.value)) {
                setServices((data) => [
                    ...data,
                    serviceSelector.current.value,
                ])

            }

    }
    function handleIntegrations() {
        console.log(integrationSelector.current)
        if (integrationSelector.current.value !== '')
            if (!integrations.some(item => item === integrationSelector.current.value)) {
                setIntegrations((data) => [
                    ...data,
                    integrationSelector.current.value,
                ])

            }

    }
    function handlePanelNumberChange(e) {
        if (parseInt(e.target.value) >= 0)
            setPanel_num(e.target.value)
        else
            setPanel_num('')
    }

    const retreiveFromSql = async (con) => {
        if (con) {
            try {
                // Fetch the Excel file as binary data
                const response = await fetch(SiteConfigTemplate);

                if (!response.ok) {
                    throw new Error('Failed to fetch the Excel file');
                }

                // Get the file content as a Blob
                const blob = await response.blob();

                // Create a link and trigger the download
                const a = document.createElement('a');
                a.href = window.URL.createObjectURL(blob);
                a.download = 'SiteConfig.xlsx'; // Desired file name

                document.body.appendChild(a);
                a.click();
                document.body.removeChild(a);
            } catch (error) {
                console.error('Error downloading the Excel file:', error);
            }
            // apiPost('api/messages/site/management/retrieveFile',
            //     {
            //         id: 0
            //     },
            //     (d => {
            //         console.log(d);
            //         try {
            //             const file = d['message']['data'][0].data.data; //file is Buffer type
            //             const filename = d['message']['data'][0].filename;
            //             const byteArray = Uint8Array.from(file, c => c.charCodeAt(0));
            //             console.log(file)

            // var a = window.document.createElement('a');

            // a.href = window.URL.createObjectURL(new Blob(file, { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' }));
            // a.download = 'file.xlsx';
            // document.body.appendChild(a)
            // a.click();
            // document.body.removeChild(a)
            //         }
            //         catch (e) { console.log(e) }
            //     })
            // )
        }
    }
    return (
        <div className='new_user_page_container'>
            <h1 style={{ marginBottom: '0px' }}>New Site</h1>
            <span className='formFieldError'>{page_error}</span>
            <br />
            <div className='aqForm'>
                <div className='aq_fields'>
                    <h4 style={{ marginTop: 0 }}>Step 3/4: Fill in setup information</h4>
                    <label>
                        <span style={{ marginRight: '10px' }}>Create Zones: </span>
                        <input
                            className="config_input shadow appearance-none border rounded  py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                            type="text" style={{ marginLeft: '20px' }} name="zone" placeholder='zone name' value={zone_name} onChange={(e) => setZone_name(e.target.value)} />

                        <button className='add_button' onClick={addZone}>add <Add /></button>
                    </label>
                    <Tags selection={zones} setSelection={setZones} />
                    <br />
                    <label>
                        <span style={{ marginRight: '10px' }}>Number of Panels:</span>
                        <input
                            className="config_input shadow appearance-none border rounded  py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                            type="number" name="zone" placeholder='0' value={panel_num} onChange={handlePanelNumberChange} />
                    </label>

                    <div style={{ display: 'inline-block', marginTop: '0px' }}>
                        <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                            <input style={{ marginRight: '2px', }} type="checkbox" id='0' onChange={(e) => handleAutogen(e.target.checked)} checked={autoGenerate} />
                            <span>Auto-generate room list</span>

                            <input style={{ marginLeft: '10px', marginRight: '2px' }} type="checkbox" id='1' onChange={(e) => handleAutogen(!e.target.checked)} checked={!autoGenerate} />
                            <span>Upload room list</span>


                        </div>
                    </div>
                    <br />
                    {!autoGenerate ?
                        <>
                            <div style={{ display: 'inline-block', marginTop: '10px', cursor: 'pointer' }}
                                onClick={(e) => { retreiveFromSql(true) }}>
                                <Download style={{ color: '#4CC1BD', marginRight: '2px', }} />
                                <span style={{ color: '#4CC1BD' }}>Download sample document</span>
                            </div>
                            <br />
                            <br />

                            <div>Upload config file:</div>
                            <UploadArea setExcelDevices={setExcelDevices} />

                        </>
                        :
                        <>
                            <div style={{ marginTop: '10px' }}>
                                <span style={{ marginRight: '10px' }}>Select device type:</span>
                                <select style={{ width: '150px' }} className="shadow  border rounded  py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                                    onChange={(e) => { handleDeviceChange(e) }} >
                                    <option value="" selected disabled hidden>Choose device</option>
                                    {/* Hide room unit */}
                                    {deviceList != null && deviceList.map(item => {
                                        return (
                                            <option hidden={item.name === 'Room Unit'} value={item.name}>{item.name}</option>
                                        )
                                    })}
                                </select>
                            </div>
                            <br />


                            <div >
                                <span style={{ marginRight: '22px' }}>Number of units:</span>
                                <input
                                    className="config_input shadow appearance-none border rounded  py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                                    type="text" name="unit_number" placeholder='number' value={deviceNum} onChange={handleNumberChange} />
                                <button className='add_button' onClick={addDevice}>add <Add /></button>
                            </div>
                            {numberError &&
                                <span>Number entered is not valid</span>
                            }
                            <div style={{ marginTop: '10px' }}>
                                <DeviceTags selection={devices} setSelection={setDevices} />
                            </div>
                        </>
                    }
                    <br />
                    <div>
                        Arquella services:
                        <select ref={serviceSelector} style={{ marginLeft: '35px' }} className="shadow  border rounded  py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                        >
                            <option value="" selected disabled hidden>Select service</option>
                            {serviceList != null && serviceList.map(item => {
                                return (
                                    <option value={item}>{item}</option>
                                )
                            })}
                        </select>
                        <button className='add_button' onClick={handleArquellaServices}>add <Add /></button>
                        <div style={{ marginTop: '5px' }}>
                            <Tags selection={services} setSelection={(setServices)} />
                        </div>
                    </div>

                    <div >
                        Third party integration:
                        <select ref={integrationSelector} style={{ marginLeft: '10px' }} className="shadow  border rounded  py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                        >
                            <option value="" selected disabled hidden>Select integration</option>
                            {integrationList != null && integrationList.map(item => {
                                return (
                                    <option value={item}>{item}</option>
                                )
                            })}
                        </select>
                        <button className='add_button' onClick={handleIntegrations}>add <Add /></button>
                        <div style={{ marginTop: '5px' }}>
                            <Tags selection={integrations} setSelection={(integrations)} />
                        </div>
                    </div>


                </div>
                <div className='submit_fields'>
                    <div className='validate_close_div'>
                        <button className='validate_button action_button' onClick={goBack}>Previous</button>
                        <button className='validate_button action_button' onClick={saveData}>Next</button>
                        <button className='close_buton action_button' onClick={onClose}>Close</button>
                    </div>
                </div>

            </div>
        </div >
    )
}
const RequiredField = ({ Title, name, value, setFormData, setValidation }) => {
    const [error, setError] = useState('')

    useEffect(() => {
        if (error === '')
            setValidation((data) => ({
                ...data,
                [name]: true,
            })
            )
        else
            setValidation((data) => ({
                ...data,
                [name]: false,
            })
            )
    }, [error])

    function handleChange(e) {
        setFormData((data) => ({
            ...data,
            [e.target.name]: e.target.value,
        })
        )
    }
    function checkField(e, setError) {
        handleChange(e)
        if (e.target.value !== '') {
            setError('');
        }
        else setError('This field is required')
    }
    function handleFocus(e) {
        if (e.target.value !== '')
            if (e.target.name.includes('email') && !validateEmail(e.target.value))
                setError('Email is not valid')
        if (e.target.name.includes('phone') && !validatePhoneNumber(e.target.value))
            setError('Phone number is not valid')
    }
    const validateEmail = (email) => {
        const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
        return emailRegex.test(email);
    };
    const validatePhoneNumber = (phoneNumber) => {
        const ph1 = /^\d{11}$/;
        const ph2 = /^\d{10}$/;
        return (ph1.test(phoneNumber) || ph2.test(phoneNumber));
    };
    return (
        <>
            <label>
                {Title} <span className='red_star'>*</span>:
                <input
                    className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                    type="text"
                    name={name}
                    value={value}
                    onChange={(e) => checkField(e, setError)}
                    onBlur={handleFocus}

                />
                <span className='formFieldError'>{error}</span>
            </label>
            <br />
        </>
    )
}
const Tags = ({ selection, setSelection }) => {
    function removeValue(e) {
        const tbl = selection.filter((item) => item !== e)
        setSelection(tbl)
    }
    function clearAll() {
        setSelection([])

    }
    return (
        <div style={{ display: "flex", flexDirection: 'column' }}>
            <div style={{ marginBottom: "0px" }}>
                {selection != null && selection.map((item) => (
                    <div className="tag-item">
                        <span className="text" >{item}</span>
                        <span onClick={() => removeValue(item)} className="close">&#10006;</span>
                    </div>
                ))
                }
                {selection.length > 1 &&
                    <button className='clear_button' onClick={clearAll}>Clear all</button>
                }

            </div>
        </div>
    )
}
const DeviceTags = ({ selection, setSelection }) => {
    function removeValue(e) {
        const tbl = selection.filter((item) => item !== e)
        setSelection(tbl)
    }
    function clearAll() {
        setSelection([])
    }
    return (
        <div style={{ display: "flex", flexDirection: 'column' }}>
            <div style={{ marginBottom: "0px" }}>
                {selection != null && selection.map((item) => (
                    <div className="tag-item">
                        <span className="text">{item.name}</span>
                        <span onClick={() => removeValue(item)} className="close">&#10006;</span>
                    </div>

                ))
                }
                {selection != null && selection.length > 1 &&
                    <button className='clear_button' onClick={clearAll}>Clear all</button>
                }
            </div>
        </div>
    )
}

const UploadArea = ({ setExcelDevices }) => {
    const [draggedOver, setDraggedover] = useState(false)
    // const [excelData, setExcelData] = useState([]);
    const [filename, setFilename] = useState('');
    // const [devices, setDevices] = useState([])

    const drop = React.useRef(null);
    useEffect(() => {
        drop.current.addEventListener('dragover', handleDragOver);
        drop.current.addEventListener('dragleave', handleDragLeave);
        drop.current.addEventListener('drop', handleDrop);

        return () => {
            if (drop.current != null) {
                drop.current.removeEventListener('dragover', handleDragOver);
                drop.current.removeEventListener('dragleave', handleDragLeave);
                drop.current.removeEventListener('drop', handleDrop);
            }
        };
    }, []);

    const handleDragOver = (e) => {
        e.preventDefault();
        e.stopPropagation();
        setDraggedover(true)
    };
    const handleDragLeave = (e) => {
        e.preventDefault();
        e.stopPropagation();
        setDraggedover(false)
    };



    function extractFile(file) {

        const reader = new FileReader();
        reader.onload = (e) => {
            const binaryStr = e.target.result;
            const workbook = XLSX.read(binaryStr, { type: 'binary' });
            const sheetName = workbook.SheetNames[0];
            const sheet = workbook.Sheets[sheetName];

            const rows = XLSX.utils.sheet_to_json(sheet);
            console.log('Xcel', rows)
            extractDevices(rows);
            // sendtoSQL(file)
        }
        reader.readAsArrayBuffer(file);
    }
    function extractDevices(rows) {
        let devices = []
        if (rows.length > 0) {
            let pendants = 601
            let doorbells = 501
            let accessories = 401
            let communals = 201
            let ensuites = 101
            let bedrooms = 1
            devices = rows.map(item => {
                let unitID;
                if (item['Device type'] != null && item['Device type'].toLowerCase().trim() === 'bedroom unit') {
                    unitID = bedrooms
                    bedrooms += 1
                    if (bedrooms === 100)
                        bedrooms = 700;

                }
                else if (item['Device type'] != null && item['Device type'].toLowerCase().trim() === 'ensuite unit') {
                    unitID = ensuites
                    ensuites += 1
                }
                else if (item['Device type'] != null && item['Device type'].toLowerCase().trim() === 'communal unit') {
                    unitID = communals
                    communals += 1
                }
                else if (item['Device type'] != null && item['Device type'].toLowerCase().trim() === 'doorbell') {
                    unitID = doorbells
                    doorbells += 1
                }
                else if (item['Device type'] != null && item['Device type'].toLowerCase().trim() === 'pendant') {
                    unitID = pendants
                    pendants += 1
                }
                else if (item['Device type'] != null &&
                    (item['Device type'].toLowerCase() === 'motion point' ||
                        item['Device type'].toLowerCase() === 'sensor matt unit' ||
                        item['Device type'].toLowerCase() === 'ceiling pull cord' ||
                        item['Device type'].toLowerCase() === 'door contact')) {
                    unitID = accessories
                    accessories += 1
                }
                else unitID = 0;
                return ({
                    unit_code: unitID.toString().padStart(3, '0'),
                    device_name: item['Bedroom name'],
                    device_type: item['Device type'],
                    unit_zone: item['Zone'],
                    isSelected: false,
                })

            })
        }
        setExcelDevices(devices)
    }
    function arrayBufferToBase64(buffer) {
        let binary = '';
        const bytes = new Uint8Array(buffer);
        const len = bytes.byteLength;
        for (let i = 0; i < len; i++) {
            binary += String.fromCharCode(bytes[i]);
        }
        return window.btoa(binary);
    }

    function sendtoSQL(fileData) {
        fileData.arrayBuffer()
            .then(buffer => {
                console.log(buffer);
                const base64File = arrayBufferToBase64(buffer);
                apiPost('api/messages/site/management/saveFile',
                    {
                        filename: 'ExcelUploadTemplate.xlsx',
                        file: base64File,
                    },
                    d => {
                        console.log(d);
                    },
                    e => {
                        console.log(e);
                    }
                );
            })
            .catch(err => {
                console.error('Error reading file:', err);
            });
    }


    const handleDrop = (e) => {
        e.preventDefault();
        e.stopPropagation();

        const files = e.dataTransfer.files;
        setDraggedover(false);

        console.log(files);

        if (files.length > 0) {
            const file = files[0];

            if (file) {
                setFilename(file.path)
                console.log(file.name);
                extractFile(file);
            }
        }
    };
    function handleFileChange(e) {
        const files = e.target.files;

        console.log(e)
        if (files.length > 0) {
            setFilename(files[0].path)
            console.log(files[0].name);
            extractFile(e.target.files[0]);
        }
    }
    return (
        <div className={draggedOver ? 'upload_area_on' : 'upload_area'} ref={drop} >
            <div onDragOver={handleDragOver} onDragLeave={handleDragLeave} style={{ display: 'flex', alignItems: 'center', }}>
                <Backup className='upload_icon' sx={{ fontSize: 100 }} />
            </div>
            <div style={{ textAlign: 'center' }}>
                <input type='file' className='file_btn' accept='.xlsx' value={filename} onChange={handleFileChange} />
                <span style={{ color: 'black' }}>or drag and drop file</span>
            </div>

        </div>
    )
}