import { Modal } from "@mui/material";
import { ModalProvider } from "../ModalContext/ModalContext";
import { useModal } from "../ModalContext/ModalContext";
import { OpenModal } from "../charts/DataModal";
import { useEffect, useState } from "react";

const AQModal=(props, height)=>{

    const { isModalOpen, openModal, closeModal } = useModal();
  
    useEffect(()=>{
      if (props.show===true){
        openModal();
      }
      else {
        closingTime();
      }
    },[props.show])
  
    function closingTime(){
      closeModal();
      if (props.onClose) props.onClose();
    }
  
    return(
      <OpenModal title={""} closeModal={closingTime} height={props.height}>
        {props.children}
      </OpenModal>
    );
  
}

export default AQModal;