import { Card, Title } from "@tremor/react";
import "./CallTileContainer.css";

export const CallTileContainer = ({ title, children }) => {

  return (
    <Card
      className="height-full y_scroll_auto"
    >
      <Title className="dashboard_section_title upper_case">{title}</Title>

      <div className="">
        {children}
      </div>

    </Card>
  );

};
