import { Checkbox, Grid } from "@mui/material";
import { useEffect, useState } from "react";
import { apiPost } from "../../data/apidata";
import { v4 as uuid } from 'uuid';
import axios from "axios";
import { LoadingBanner } from "../charts/Notifications";
import { timeout } from "d3";
import { Switch } from '@mui/material';

import { styled } from '@mui/material/styles';
import { enqueueSnackbar } from "notistack";
import { VayyarConfig, VayyarConfigLoading } from "./vayyar";
import { CogvisConfig } from "./cogvis";
import { GlobalConfig } from "./GlobalConfig";


export const EditSenseConfig = ({ siteID, onClose }) => {
    const [currStep, setCurrStep] = useState(1)
    const [page_error, setPage_error] = useState('')
    const [config, setConfig] = useState({})
    const [vendor, setVendor] = useState('')

    useEffect(() => {
        console.log(`siteid ${siteID}`)
        apiPost(
            'api/messages/Sense/GetSiteSenseConfig',
            { site_id: siteID },
            d => {
                let data = d['message']['data'][0][0]
                // console.log('senseConfig', data)


                if (data.sensors != null) {
                    if (data.sensors[0].vendor === 'Vayyar') {
                        setVendor('Vayyar')
                        data['vendor'] = 'Vayyar'
                    }
                    else {
                        setVendor('Cogvis')
                        data['vendor'] = 'Cogvis'
                    }
                }

                if (data.loginDetails != null) {
                    data['username'] = data.loginDetails[0].username;
                    data['password'] = data.loginDetails[0].password;
                }

                setConfig(data, vendor)
                console.log('got data', data)

            },
            e => {
                console.log(e)
            }
        )
    }, [siteID])

    return (
        <div className='new_user_page_container'><h1 style={{ marginBottom: '0px' }}>Sense configuration</h1>
            <span className='formFieldError'>{page_error}</span>
            <br /> {/* <renderStep currStep={currStep} setCurrStep={setCurrStep}
                config={config} setConfig={setConfig} onClose={onClose}
                page_error={page_error} setPage_error={setPage_error} vendor={vendor} /> */}
            {renderStep({
                currStep: currStep, config: config, setConfig: setConfig, page_error: page_error, setPage_error: setPage_error, setCurrStep: setCurrStep, onClose: onClose, vendor: vendor, setVendor: setVendor
            })}
        </div>
    );

}
const renderStep = (props) => {
    switch (props.currStep) {
        case 1:
            return <Step1 {...props} />;
        case 2:
            return props.vendor === 'Vayyar' ? <VayyarConfig {...props} /> : <CogvisConfig {...props} />;
        case 3:
            return props.vendor === 'Vayyar' && <VayyarConfigLoading {...props} />;
        case 4:
            return props.vendor && <GlobalConfig {...props} />;
        default:
            return null;
    }
};

const Step1 = ({ currStep, config, setConfig, page_error, setPage_error, setCurrStep, onClose, vendor, setVendor }) => {

    const [username, setUsername] = useState(config.username != null ? config.username : '')
    const [password, setPassword] = useState(config.password != null ? config.password : '')
    const [hasLoaded, setHasloaded] = useState(false)
    useEffect(() => {

        if (vendor === 'Cogvis') {
            let sensors = config.sensors.map(item => {
                return ({
                    ...item,
                    isSelected: false,
                    error: false,
                    msg: '',
                    sensor_id: item.vayyarId.split('/')[2],
                    sensor_room: `${item.vayyarZone} ${item.vayyarName}`,
                    paired_unit: config.devices.filter(room =>
                        item.vayyarName === room.device_name
                        && item.vayyarZone === room.device_zone
                    )[0],
                    // device: config.devices.filter(room =>
                    //     item.vayyarName === room.device_name
                    //     && item.vayyarZone === room.device_zone
                    // )
                })
            })
            console.log('sss', sensors)
            setConfig((data) => (
                {
                    ...data,
                    'sensors': sensors,
                    vendor: vendor,
                    tryAgain: false,
                }
            ))
            setCurrStep(2);
            setPage_error('')

            console.log(config)
        }
        else {
            setUsername(config.username)
            setPassword(config.password)
        }
    })
    // useEffect(() => {
    //     if (config != null)
    //         if (!hasLoaded) {
    //             console.log(config.username)
    //             if (config.username !== null && config.username !== undefined) {
    //                 setUsername(config.username)
    //                 setHasloaded(true)
    //             }
    //             if (config.password !== null && config.password !== undefined) {
    //                 setHasloaded(true)
    //                 setPassword(config.password)
    //             }


    //         }
    // }, [config, username, password])


    function saveData() {
        if (config.sensors.length > 0) {
            let sensors = config.sensors.map(item => {
                return ({
                    ...item,
                    isSelected: false,
                    error: false,
                    msg: '',
                    sensor_id: item.vayyarId.split('/')[2],
                    sensor_room: `${item.vayyarZone} ${item.vayyarName}`,
                    paired_unit: config.devices.filter(room =>
                        item.vayyarName === room.device_name
                        && item.vayyarZone === room.device_zone
                    )[0],
                })
            })
            console.log('got sensors', sensors, vendor)
            if (vendor === 'Vayyar') {

                axios.post('https://home.vayyarhomeapisdev.com/token/idtoken/',
                    {
                        email: username,
                        password: password
                    })
                    .then(d => {
                        console.log('UserData', d.data);
                        if (d.status === 200) {
                            apiPost('api/messages/Sense/updateVayyarLoginDetails',
                                {
                                    site_id: config.site_id,
                                    username: config.username,
                                    password: config.password
                                }, d => {
                                    console.log('Login details saved')
                                }, e => {
                                    console.log('Login details failed')
                                }
                            )

                            // sensors.push(
                            //         {
                            //             id: uuid(),
                            //             sensor_id: '',
                            //             sensor_room: '',
                            //             isSelected: false,
                            //             error: false,
                            //             msg: ''
                            //         })

                            setConfig((data) => (
                                {
                                    ...data,
                                    'sensors': sensors,
                                    idToken: d.data.idToken,
                                    username: username,
                                    password: password,
                                    vendor: vendor,
                                    tryAgain: false,
                                }
                            ))
                            setCurrStep(2);
                            setPage_error('')

                            console.log(d.data)
                            // console.log(config);
                        }
                    })
                    .catch(e => {
                        console.error('Error!', e);
                        setPage_error('Wrong username or passrword')
                    });
            }

        }
        else {
            setPage_error('There is no sensor configured for this site')
        }
    }

    return (
        <div className='aqForm'>
            <div className='aq_fields'>
                <h4 style={{ marginTop: 0 }}>Step 1/3: Vendor selection</h4>

                <br />
                <br />


                {vendor === 'Vayyar' &&
                    <div>
                        <label>
                            <span style={{ marginRight: '10px' }}>Username: </span>
                            <input
                                className="config_input shadow appearance-none border rounded  py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                                type="text" style={{ marginLeft: '45px' }} name="username" placeholder='username' value={username} onChange={(e) => setUsername(e.target.value)} />
                        </label>
                        <br />
                        <br />
                        <label>
                            <span style={{ marginRight: '10px' }}>Password: </span>
                            <input
                                className="config_input shadow appearance-none border rounded  py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                                type="password" style={{ marginLeft: '48px' }} name="zone" placeholder='password' value={password} onChange={(e) => setPassword(e.target.value)} />
                        </label>
                    </div>
                }

            </div>
            <div className='submit_fields'>
                <div className='validate_close_div'>
                    <button className='validate_button action_button' onClick={saveData}>Next</button>
                    <button className='close_buton action_button' onClick={onClose}>Close</button>
                </div>
            </div>

        </div>
    )
}