import React, { useEffect } from 'react';
import Live from './Live';
import LiveDashboardLiveCalls from '../components/charts/LiveDashboardLiveCalls';
import { PageLayout } from '../components/page-layout';
import { useUserSettings } from '../components/UserSettingsProvider/UserSettingsProvider';

const Move=({siteCode})=>{

    const { userSettings, updateSettings } = useUserSettings();
    const site_guid = userSettings.move.key;
    //'01861232-864d-460e-2931-bfc95ddd4753'; //iiwari
    
    return(
            <div className='grid-30-70-container'>
                <div width="100%">
                <iframe
                    id='iiwariframe' 
                    src={"https://app.iiwari.cloud/arquella?site=" + site_guid + "&theme=iframe"}
                    //sandbox="allow-top-navigation"
                    style={{
                        marginLeft: "2.5%",
                        marginTop: "2.5%",
                        alignSelf: "center",
                        height: "80vh",
                        //margin: "3vh",
                        //width: "95%",
                        width: "1200px",
        //                    minHeight: "",
                        marginRight:"10px", 
                        marginTop:"10px",
                        //border:"none",
                    }}
                    >
                </iframe>
                {/*<LocationEventRunner></LocationEventRunner>*/}
                <div id="newtest"></div>
            </div>

            </div>

    );

}

export default Move;