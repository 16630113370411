import { useEffect, useState } from "react"
import { IOSSwitch } from "./GlobalConfig"
import { Slider, styled } from "@mui/material"
import './SenseConfig.css'
import { apiPost } from "../../data/apidata"
import axios from 'axios';

export const EditSenseAllDevices = ({ siteID, onClose }) => {
    const [page_error, setPage_error] = useState('')
    const [fall_detection, setFall_detection] = useState(false)
    const [bedAlarm, setBedAlarm] = useState(false)
    const [chairAlarm, setChairAlarm] = useState(false)

    const [fallValues, setFallValue] = useState([0, 24]);
    const [bedValues, setBedValues] = useState([0, 24]);
    const [chairValues, setChairValues] = useState([0, 24]);


    useEffect(() => {
        // apiPost('api/messages/Sense/GetSiteSenseConfig',
        //     { site_id: siteID },
        //     d => {
        //         let data = d['message']['data'][0][0]

        //         if (data.GlobalConfig != null) {
        //             setFall_detection(data.GlobalConfig.V_FD_Armed)
        //         }

        //     }
        // )

        axios.post('https://cai-m2m-dev.auth.eu-central-1.amazoncognito.com/oauth2/token/',
            {
                client_id: '11i1vf4ii59qvigpd8af7ma51j',
                client_secret: '35doe1h7nqv9cf6votlqmu1h31q10d7fr1dkfaoc72oftu1sdbe'

            })
            .then(d => {
                console.log(d)
            })
            .catch(e => {
                console.log(e)
            })


    }, [siteID])

    function saveData() {

        apiPost('api/messages/Sense/SetSiteSenseConfig',
            {

            }
            ,
            d => {
                console.log(d)
            },
            e => {
                console.log(e)
            }
        )

    }


    return (
        <div className='new_user_page_container'><h1 style={{ marginBottom: '0px' }}>Edit all devices</h1>
            <span className='formFieldError'>{page_error}</span>
            <div className='aqForm'>
                <div className='aq_fields'>

                    <Label label={'Turn on/off Fall Detection'} />
                    <IOSSwitch checked={fall_detection} onChange={(e) => setFall_detection(e.target.checked)} />
                    <SenseSlider label={'Set fall detection alarm period for all devices'}
                        value={fallValues} setValue={setFallValue} enabled={fall_detection} />


                    <Label label={'Turn on/off Out of Bed Alarm for all devices'} />
                    <IOSSwitch checked={bedAlarm} onChange={(e) => setBedAlarm(e.target.checked)} />
                    <SenseSlider label={'Set Out of Bed period for all devices'}
                        value={bedValues} setValue={setBedValues} enabled={bedAlarm} />

                    <Label label={'Turn on/off Out of Chair Alarm for all devices'} />
                    <IOSSwitch checked={chairAlarm} onChange={(e) => setChairAlarm(e.target.checked)} />
                    <SenseSlider label={'Set Out of Chair alarm for all devices'}
                        value={chairValues} setValue={setChairValues} enabled={chairAlarm} />
                </div>

                <div className='submit_fields'>
                    <div className='validate_close_div'>
                        <button className='validate_button action_button' onClick={saveData}>Next</button>
                        <button className='close_buton action_button' onClick={onClose}>Close</button>
                    </div>
                </div>
            </div>

        </div>)
}

const SenseSlider = ({ label, value, setValue, enabled }) => {
    const [begin, setBegin] = useState('')
    const [end, setEnd] = useState('')

    useEffect(() => {
        const tmp = value[0]
        if (value[0] > value[1]) {
            setValue(value[1], tmp)
        }
        setBegin(getHour(value[0]))
        setEnd(getHour(value[1]))

    }, [value])
    function getHour(val) {
        // console.log(val)
        let hour = val;
        let mn = '00'

        if (String(val).includes('.5')) {
            const tmp = String(val).split('.5')
            // console.log(tmp)
            hour = tmp[0]

            mn = `30`
        }

        if (hour < 10)
            hour = `0${hour}`

        return `${hour} : ${mn}`

    }

    const marks = [
        {
            value: 0,
            label: `${0}H`
        },
        {
            value: 6,
            label: `${6}H`
        },
        {
            value: 12,
            label: `${12}H`
        },
        {
            value: 18,
            label: `${18}H`
        },
        {
            value: 24,
            label: `${24}H`
        }
    ]

    function valuetext(value) {
        return `${value}`;
    }
    const handleChange = (event, newValue) => {
        setValue(newValue);
    };
    return (
        <div>
            <br />
            <Label label={label} />
            <div style={{ marginRight: '20px' }}>
                <div style={{ textAlign: 'right', width: '100%', fontSize: '10px' }}>
                    <span style={{ border: 'solid 1px grey', padding: '2px', borderRadius: '3px' }}>{begin}</span> - <span style={{ border: 'solid 1px grey', padding: '2px', borderRadius: '3px' }}>{end}</span>
                </div>
                <ArquellaSlider
                    disabled={!enabled}
                    style={{ marginLeft: '10px', marginRight: '20px' }}
                    max={24}
                    step={0.5}
                    marks={marks}
                    value={value}
                    onChange={handleChange}
                    valueLabelDisplay="auto"
                    valueLabelFormat={getHour}
                />
            </div>
        </div>
    )
}

const ArquellaSlider = styled(Slider)(({ theme }) => ({
    color: '#03524f',
    height: 5,
    '& .MuiSlider-track': {
        border: 'none',
    },
    '& .MuiSlider-thumb': {
        height: 20,
        width: 20,
        backgroundColor: '#fff',
        border: '2px solid currentColor',
        '&:focus, &:hover, &.Mui-active, &.Mui-focusVisible': {
            boxShadow: 'inherit',
        },
        '&::before': {
            display: 'none',
        },
    },
    '& .MuiSlider-valueLabel': {
        lineHeight: 1.2,
        fontSize: 12,
        background: 'unset',
        padding: 0,
        width: 45,
        height: 25,
        // borderRadius: '50% 50% 50% 0',
        backgroundColor: '#1e716e',
        transformOrigin: 'bottom left',
        // transform: 'translate(50%, -100%) rotate(-45deg) scale(0)',
        // transform: 'translate(50%, -100%) rotate(-45deg) scale(0)',
        '&::before': { display: 'none' },
        '&.MuiSlider-valueLabelOpen': {
            // transform: 'translate(50%, -100%) rotate(-45deg) scale(1)',
        },
        '& > *': {
            // transform: 'rotate(45deg)',
        },
    },
}));
const Label = ({ label }) => {
    return (
        <div style={{ marginRight: '10px', fontWeight: 'bold' }}>
            {label}
        </div >
    )
}