import { useState } from "react";
import "./EditZoneProfile.css";
import AQDataTable from "../AQDataTable/AQDataTable";
import { columns_template_zone_profile_zones } from "../AQDataTable/columns_templates";

const EditZoneProfile=({data, onClose, onUpdated})=>{

    const [formDetails, setFormDetails] = useState(data);

    return(
        <div className='inner_layer' style={{height:'400px', width: '800px'}}>
            <h1 style={{marginBottom: '0px'}}>{formDetails.name}</h1>
            <div className='aqForm' style={{height: '350px'}}>
                <div className='aq_fields' style={{fontSize:'16px'}} >
                    <ShowZones profileID={data.id} zones={data.zones}/>
                    <div className='submit_fields'>
                        <div className='validate_close_div'>
                            <button className='close_buton' onClick={onClose} style={{width: '70px'}}>Close</button>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        // <div className="edit_zone_profile_container">
        //     <h1>{formDetails.name}</h1>
        //     <ShowZones profileID={data.id} zones={data.zones}/>
        // </div>
    );
}


const ShowZones=({profileID, zones})=>{
    
    return(
        <div>
            <AQDataTable
                data={zones}
                //siteID={"sensetest"}
                columns={columns_template_zone_profile_zones}
                settingsType={"zone_profile_zones"}
                onUpdateRefreshPath={"zone profiles"}
            />
        </div>
        
    );
}

export default EditZoneProfile;