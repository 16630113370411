import { useState, useEffect } from "react";
import { Card, Title } from "@tremor/react";
import { GetSenseImage } from "../GetImage";
import { getOnValue, getOnceValue } from "../../data/firebase";
import "./SenseLive.css";
import Grid from "@mui/material/Grid";

export default function SenseLive({sitesList}){

  const [calls, setCalls] = useState([]);
  const [masterCalls, setMasterCalls] = useState({});
  const [config, setConfig] = useState({});
  
  function updateValues(data){
    //console.log({data});
    setMasterCalls(data);
  }

  function handlError(error){
    console.log(error);
  }

  function processMasterCalls(){
    const tmpsCalls = [];
    try {
    Object.keys(masterCalls).forEach((k)=>{
      if (k === 'room') return; 
      Object.keys(masterCalls[k]).forEach(c=>{
        if (masterCalls[k][c]===false) return;
        tmpsCalls.push(
          {
            "location": k,
            "room": getVayyarRoomFromID(c)
          }
        );
      });
    });
    } catch(e) { console.log(e); }
    setCalls(tmpsCalls);
  }
  

  function getVayyarRoomFromID(id){
    const matches = config.filter((c)=>(c.vayyarUnit===id));
    if (matches.length===0)return id;
    return matches[0].roomName;
  }

  function trimVayyarRef(ref){
    ref = ref.replace("/devices/","");
    ref = ref.replace("/events","");
    return ref;
  }

  function getConfig(site){
    if (site==="") return;
  
    const configPath = site + "_unitconfig";

    getOnceValue(configPath, (d)=>{
      const tmpValue = [];
      d.map((val)=>{
        tmpValue.push(
          {
            "vayyarUnit": trimVayyarRef(val.vayyarId),
            "roomName": val.vayyarName,
            "zoneName": val.vayyarZone
          }
        );
      });
      setConfig(tmpValue);
      getSiteData(site);
    },
    (e)=>{ console.log(e); }
    )
  }

  function getSiteData(site){
    getOnValue(site + "_site/config/Vayyar/presence/Main Panel/", updateValues, handlError);
  }


  
  function resetSitesList(){
    if (sitesList.length===0) return;
    sitesList.map(site=>{
      if (site==="") return;
      getConfig(site);
    });
  }

  useEffect(()=>resetSitesList(),     [sitesList]);
  useEffect(()=>processMasterCalls(), [masterCalls]);


  return (
    <Card className="height-full">
      <Title className="dashboard_section_title upper_case">Sense</Title>
      <div className="dashboard_frame">
        <Grid container spacing={1} className="sense_live_container">
          {
          calls.length > 0 ? 
            calls.map((c, i) => (
              <Grid item  xs={12} sm={6} md={4} lg={3} key={i}>
                <CallCard data={c} />
          </Grid>
            )) 
            : null
          }
        </Grid> 
      </div>
    </Card>
  )

function CallCard({ data }){
  return (
    <div className={"bg_sense_" + data.location + " colour-white flex-row sense_div"}>
      <CallHeader data={data.location}/>
      <div style={{textAlign:'center'}} >
      <span className="sense_text" >{data.room}</span>
      <br></br>
      <hr></hr>
      <span className="sense_text">{getLocationDisplay(data.location)}</span>
      </div>
    </div>
  );
}



function getLocationDisplay(location){
  if (location==="bed") return "In bed";
  if (location==="fall") return "Fall";
  if (location==="manOnGround") return "On the ground";
  if (location==="bathroom") return "In bathroom";
  if (location==="chair") return "In Chair";
  if (location==="room") return "In Room";

  return location;
}

function CallHeader({data}){
  
  return (
    <div className="sense_call_header_container">
        <GetSenseImage imgString={data.toLowerCase()} />
        {/*<div className="align-left left-padding-10">{getLocationDisplay(data)}</div>*/}
    </div>
  );
}


function CallBody({data}){

  return (
    <div>
      Call Details
    </div>
  );}
}
