import React from "react";
import Live from "./Live";
import { useUserSettings } from "../components/UserSettingsProvider/UserSettingsProvider";

export const HomePage = () => {
  
  const { userSettings, updateSettings } = useUserSettings();

  return(
      <Live
        siteObj={
          {
            "siteID": userSettings.site.id,
            "siteCode": userSettings.site.code,
            "senseCode": userSettings.site.code,
          }
        }
      />
    );
}


