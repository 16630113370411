import { useEffect, useState } from "react";
import arqella_logo from "../../../images/arquellaAlogo.png";
import {
    Container,
    Grid,
    Paper,
    IconButton,
} from '@mui/material';
import "./ResetPassword.css";
import { enqueueSnackbar } from "notistack";
import { useNavigate } from "react-router-dom";
import CheckCircleOutlineIcon from '@mui/icons-material/CheckCircleOutline';
import { Visibility, VisibilityOff } from "@mui/icons-material";
import { apiPost } from "../../../data/apidata";
import { useAuth0 } from "@auth0/auth0-react";


const ResetPassword = () => {
    const [newPassword, setNewPassword] = useState('');
    const [newConfirmPassword, setNewConfirmPassword] = useState('');
    const [showError, setShowError] = useState("");
    const [isPasswordValid, setIsPasswordValid] = useState(false);
    const [userData, setUserData] = useState(null);
    const [onSuccess, setOnSuccess] = useState(false);
    const [pageTitle, setPageTitle] = useState('');
    const [btnTitle, setBtnTitle] = useState('');

    useEffect(() => {
        const queryParameters = new URLSearchParams(window.location.search)
        const link = queryParameters.get("link")
        const target = queryParameters.get("target")
        if (target === 'create') {
            setPageTitle('Create New Password')
            setBtnTitle('Create Password')
        }
        else {
            setPageTitle('Reset Password')
            setBtnTitle('Reset Password')
        }

        apiPost("api/messages/user/password_link/check",
            {
                "password_link": `${link}`,
            },
            (d) => {
                const data = (d['message']['data'][0][0]);
                let user = null
                if (data.length > 0) {
                    data.map(item => {
                        if (item.STATUS === 'Valid' && item.password_link === link) user = item
                    });
                }
                if (user !== null) {
                    console.log("email", user.user_email)
                    setUserData(user)
                }
            },
            (e) => {
                console.log(e);
            }
        )
    }, [])

    useEffect(() => {
        let valid = false;
        if (newPassword !== '' &&
            newConfirmPassword !== '') {
            console.log(newPassword)
            if (newPassword !== "new password" && newConfirmPassword !== "confirm password") {
                if (newPassword === newConfirmPassword) {
                    valid = validatePassword(newPassword)

                    if (!valid) setShowError("Please make sure password contains lower and upper case letters, digits and is at least 8 character long")
                }
                else {
                    setShowError("Passwords don't match")
                }
            }
            else setShowError("");
        } else setShowError("");
        setIsPasswordValid(valid);
        if (valid) setShowError("")

    }, [newPassword, newConfirmPassword])
    const validatePassword = (password) => {

        const isLongEnough = password.length >= 8;
        const hasLower = /[a-z]/.test(password);
        const hasUpper = /[A-Z]/.test(password);
        const hasDigit = /[0-9]/.test(password);
        return isLongEnough && hasLower && hasUpper && hasDigit;
    };
    function submitPassword() {
        if (isPasswordValid) {
            console.log(userData.user_id)
            apiPost(
                "api/messages/user/password/resetAuth0",
                {
                    "email": `${userData.user_email}`,
                    "password": `${newPassword}`,
                },
                (d) => {
                    enqueueSnackbar("Password has been reset.", { variant: "success" });
                    setOnSuccess(true)
                    apiPost("api/messages/user/password_link/remove",
                        {
                            "user_id": `${userData.user_id}`,
                        },
                        (d) => {

                        },
                        (e) => {
                            console.log(e);
                        }
                    )

                },
                (e) => { console.log(e) },
            )
        }
    }
    return (
        <Container component="main"
            maxWidth="xs"
            style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100vh' }}
        >
            <Paper
                elevation={3}
                sx={{
                    padding: 4,
                    paddingLeft: 8,
                    paddingRight: 8,
                    paddingBottom: 10,
                    // marginTop: 8,
                    margin: 'auto',
                    display: 'flex',
                    flexDirection: 'column',
                    alignItems: 'center',
                }}
            >
                {(onSuccess === false) ?
                    < >
                        <img src={arqella_logo} alt="logo" color="" />



                        {(userData != null) ?
                            <>
                                <h3 variant="body2" color="textSecondary" fontSize={16}>
                                    {pageTitle}
                                </h3>

                                <span>Enter your new password below</span>
                                <br />
                                <span style={{ color: 'red', textAlign: 'center' }}>{showError}</span>

                                <div component="form" noValidate sx={{ mt: 3 }}>
                                    <Grid container>
                                        <Grid item sm={12}>
                                            <PasswordField placeHolder={"new password"} setReturnValue={setNewPassword} />
                                        </Grid>
                                        <Grid item xs={12}>
                                            <PasswordField placeHolder={"confirm password"} setReturnValue={setNewConfirmPassword} />
                                        </Grid>
                                    </Grid>

                                    <input type="button"
                                        className="passwordInputSubmitButton"
                                        onClick={submitPassword}
                                        value={btnTitle}
                                    />


                                </div>
                            </>
                            :
                            <div style={{
                                fontFamily: '16px',
                                color: 'red',
                            }}>
                                <br />
                                <br />
                                <br />
                                This link is not valid.
                            </div>}
                    </>
                    :
                    <SuccessPage />
                }
            </Paper>
        </Container>
    )

}

const PasswordField = ({ placeHolder, setReturnValue }) => {
    const [showPassword, setShowPassword] = useState(true);
    const [onfocus, setOnfocus] = useState(false);
    const [buttonDisabled, setButtonDisabled] = useState(true);
    const [passValue, setPassValue] = useState(placeHolder);

    function handleShowPassword() {
        setShowPassword(!showPassword)
    }
    function textChangeHandler(e) {
        setPassValue(e.target.value)
        setReturnValue(e.target.value)
        console.log(passValue)
    }
    function handleFocus(val) {
        setOnfocus(val)
        console.log(onfocus)
        if (!val && passValue === '') {
            setPassValue(placeHolder)
            setShowPassword(true)
            setButtonDisabled(true)
        }
        if (val && passValue === placeHolder) {
            setPassValue('')
            setShowPassword(false)
            setButtonDisabled(false)
        }
    }
    return (
        <div className="passwordInputDiv" style={{
            // margin: "10PX",
            // padding: "5PX",
            display: "inline-block",
            borderRadius: "5px",
            borderWidth: "1px",
            borderColor: onfocus ? "#76b5b7" : "#b7c3c2"
        }}>
            <input className="passwordInputField"
                onFocus={() => {
                    handleFocus(true)
                }}
                onBlur={() => {
                    handleFocus(false)
                }}
                type={!showPassword ? "password" : "text"}
                onChange={textChangeHandler}
                value={passValue} />
            <IconButton tabIndex={'-1'} className="passwordInputShowButton" disabled={buttonDisabled} onClick={handleShowPassword}>
                {showPassword ? <Visibility /> : <VisibilityOff />}
            </IconButton>
        </div>
    )
}

const SuccessPage = () => {
    const navigate = useNavigate();
    const { isAuthenticated, loginWithRedirect, logout } = useAuth0()
    function gotToConnect() {
        // navigate("/loginredirect")
        if (isAuthenticated)
            logout()
        loginWithRedirect();
    }
    return (
        <>
            <br />
            <br />
            <CheckCircleOutlineIcon style={{
                width: '100px', height: '100px'
            }} alt="checked logo" />
            <br />
            <p style={{ fontWeight: "normal", fontSize: "16px", textAlign: 'center' }} >
                Password created successfully!
            </p>

            <div component="form" noValidate sx={{ mt: 3 }}>


                <input type="button"
                    style={{ padding: '8px 20px' }}
                    className="passwordInputSubmitButton"
                    onClick={gotToConnect}
                    value={"Go to Connect Dashboard"}
                />
            </div>
            <br />
            <br />
        </>
    )
}
export { ResetPassword };